import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/config.service';
import { Currency } from 'src/app/currency.interface';
import { InteractionService } from 'src/app/interaction.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { User } from 'src/app/user.interface';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  isLoaded: boolean = false;

  jurisdictions: any;
  seletedJurisdiction: any;
  selectedJurisdictionLevel: any;
  usersList: any = [];
  allowUsersList: any = [];
  allUsersTreeList = {} as User[];
  Currency: Currency = <Currency>{};

  createUserRequest = {} as User;
  // form fields
  createUserForm: FormGroup;

  jurisdictionType: number;

  jurisdictionCoupon: string = '';

  countries: any[];

  constructor(private translate: TranslateService, public appComponent: AppComponent,
    private configService: ConfigService, private formBuilder: FormBuilder, private router: Router,
    private interactionService: InteractionService, private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);

    this.createUserForm = this.formBuilder.group({
      jurisdictionFather: ['', Validators.required],
      userName: [this.createUserRequest.login, Validators.required],
      name: [''],
      email: ['', Validators.required],
      whatsapp: [''],
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      lastName: [''],
      email2: [''],
      email3: [''],
      commission: [''],
      gender: [''],
      birthdate: [''],
      address: [''],
      city: [''],
      country: [''],
      zipcode: [''],
      phone: [''],
      phone2: [''],
      cellphone: [''],
      notes: [''],
      access: [true]

    }, {
      //validator: ConfirmedValidator('password', 'confirmPassword')
    })
  }

  ngOnInit() {
    //this.getJurisdictionsType();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.getUsersTreeList();
  }

  getUsersTreeList() {
    this.configService.getUsersTreeList(this.appComponent.userAdmin.id)
      .subscribe(data => {
        this.allUsersTreeList = data;
        this.getJurisdictionsType();
      }, error => {
        console.log(error);
      });
  }

  getJurisdictionsType() {
    this.configService.getJurisdictionsType(this.appComponent.userAdmin.jurisdictionId)
      .subscribe(data => {
        this.jurisdictions = data.filter(x => x.id != 7); // not allow player level as father
        this.selectedJurisdictionLevel = this.appComponent.userAdmin.jurisdictionId;
        this.getUsersList();
      }, error => {
        console.log(error);
      });
  }

  getUsersList() {
    this.configService.GetUsersList(this.appComponent.userAdmin.id).subscribe(data => {
      data.forEach(element => {
        if (element.jurisdictionId == this.selectedJurisdictionLevel && element.id == this.appComponent.userAdmin.id) {
          this.allowUsersList.push(element)
          this.usersList.push(element)
        }
      });
      this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
      this.configService.getUsersTreeList(this.appComponent.userAdmin.id)
        .subscribe(res => {
          res.forEach(element => {
            this.usersList.push(element)
          });
          this.seletedJurisdiction = -1;
          //this.getCountries();
          this.cleanJurisdictions();
        }, error => {
          console.log(error);
        });
    })
  }

  // clean jurisdictions avoids to show levels which there are no users inside to be father
  cleanJurisdictions() {
    for (let i = this.jurisdictions.length - 1; i > 0; i--) {
      let search = this.allUsersTreeList.find(x => x.jurisdictionId == this.jurisdictions[i].id);
      if (typeof (search) == 'undefined') {
        this.jurisdictions.pop();
      }
    }
    this.getCountries();
  }

  getCountries() {
    this.configService.getCountries()
      .subscribe(data => {
        this.countries = data.sort((a, b) => a.name.localeCompare(b.name));
        document.getElementById("spinner-loading").classList.add("hidden");
        this.isLoaded = true;
      }, error => {
        console.log(error);
      })
  }

  jurisdictionLevelChange(event) {
    this.selectedJurisdictionLevel = event.target.value
    this.allowUsersList = []
    this.usersList.forEach(element => {
      if (element.jurisdictionId == this.selectedJurisdictionLevel) {
        if (this.appComponent.userAdmin.jurisdictionId == this.selectedJurisdictionLevel) {
          if (element.id == this.appComponent.userAdmin.id) {
            this.allowUsersList = [element]
          }
        } else {
          this.allowUsersList.push(element)
        }
      }
    });
    this.allowUsersList.sort((a, b) => a.login.localeCompare(b.login))
    this.seletedJurisdiction = -1
  }

  loadCountry() {
    //document.getElementById("spinner-loading").classList.add("hidden");
    //this.isLoaded = true;
  }

  createUser(formData) {
    // start required checks
    let requiredList = [];
    let repeat: boolean = false;
    let fatherId = +(<HTMLInputElement>document.getElementById('jurisdiction-father')).value;
    let fatherJurisdictionId: number = this.appComponent.userAdmin.jurisdictionId + 1;
    let maxCommission: number;
    this.allowUsersList.forEach(element => {
      if (element.id == fatherId)
        maxCommission = element.commission;
      fatherJurisdictionId = element.jurisdictionId;
    });
    if (formData.userName == "" || formData.userName == null || typeof formData.userName === 'undefined') {
      requiredList.push("- Username<br/>");
      repeat = true;
    }
    if (formData.userName != null && formData.userName.includes(" ")) {
      requiredList.push("- Username " + this.translate.instant("pg-new-user.fail-space") + "<br/>");
      repeat = true;
    }
    if (formData.password == "" || formData.password == null || typeof formData.password === 'undefined') {
      requiredList.push("- " + this.translate.instant("pg-jurisdiction.password") + "<br/>");
      repeat = true;
    }
    if (formData.confirmPassword == "" || formData.confirmPassword == null || typeof formData.confirmPassword === 'undefined') {
      requiredList.push("- " + this.translate.instant("pg-jurisdiction.confirm-password") + "<br/>");
      repeat = true;
    }
    if (formData.email == "" || formData.email == null || typeof formData.email === 'undefined') {
      requiredList.push("- E-mail<br/>");
      repeat = true;
    }
    if (formData.password.length < 8) {
      requiredList.push("- " + this.translate.instant("pg-new-user.fail-min-password") + "<br/>");
      repeat = true;
    }
    if (formData.password != formData.confirmPassword) {
      requiredList.push("- " + this.translate.instant("pg-new-user.fail-confirm-password") + "<br/>");
      repeat = true;
    }
    if (formData.commission > maxCommission) {
      requiredList.push("- " + this.translate.instant("pg-new-user.fail-commission") + "(" + maxCommission + "%)<br/>");
      repeat = true;
    }

    //let requiredMsg = this.translate.instant("pg-new-user.whatch-requirements") + "\n";
    let requiredMsg = "";
    requiredList.forEach(element => {
      requiredMsg += element + "\n";
    });
    if (repeat) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.whatch-requirements"), requiredMsg, false);
      return;
    }
    // end required checks

    // sending new user object
    let newUser: User = {} as User;
    newUser.parentId = +fatherId;
    newUser.jurisdictionId = fatherJurisdictionId + 1;
    newUser.login = formData.userName;
    newUser.password = formData.password;
    newUser.name = formData.name;
    newUser.lastName = formData.lastName;
    newUser.email = formData.email;
    newUser.email2 = formData.email2;
    newUser.email3 = formData.email3;
    newUser.commission = +formData.commission;
    newUser.gender = formData.gender;
    newUser.birthDate = new Date(formData.birthdate);
    newUser.country = formData.country;
    newUser.city = formData.city;
    newUser.address = formData.address;
    newUser.zipCode = formData.zipcode;
    newUser.phone = formData.phone;
    newUser.phone2 = formData.phone2;
    newUser.cellPhone = formData.cellphone;
    newUser.whatsapp = formData.whatsapp;
    newUser.notes = formData.notes;
    newUser.deleted = formData.access == true ? false : true;
    //console.log(newUser);

    //save new user
    this.configService.createUser(newUser, "nothing")
      .subscribe(data => {
        if (this.jurisdictionCoupon != '') {
          let availableValue: number = this.Currency.quote * +(<HTMLInputElement>document.getElementById('available-value')).value;
          this.configService.saveJurisdictionCoupon(data.id, this.jurisdictionCoupon, availableValue)
            .subscribe(data => {
              //...
            }, error => {
              //...
            })
        }
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.success"), this.translate.instant("pg-new-user.created"), false);
        this.router.navigate(['/jurisdiction']);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-new-user.fail"), this.translate.instant("pg-new-user." + error.error), true);
        console.log(error);
      })

  }

  generateCoupon() {
    this.configService.generateJurisdictionCoupon()
      .subscribe(data => {
        this.jurisdictionCoupon = data.code;
      }, error => {
        this.modalsService.openNotificationModal("pg-new-user.fail", "Não foi possível gerar novo código de cupom.", true);
      })
  }

}


