import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from '../interaction.service';
import { Currency } from '../currency.interface';
import { ModalDirective } from 'angular-bootstrap-md';
import { WithdrawRequest } from '../Interfaces/Withdraws/WithdrawRequest';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  @ViewChild('documentFail', { static: true }) documentFail: ModalDirective;

  userBank: string = 'pix';
  userAgency: string = 'pix';
  userAccount: string;
  userAccountType: number;
  cellphone: any;
  bankList = [
    { ind: 1, selected: false, label: 'poupança' },
    { ind: 2, selected: false, label: 'corrente' }
  ];
  pixList = [
    { ind: 3, selected: false, label: 'e-mail' },
    { ind: 4, selected: false, label: 'telefone' },
    { ind: 5, selected: false, label: 'cpf' },
    { ind: 6, selected: false, label: 'chave' }
  ];
  requestedCredit: number;
  withdrawId: number;
  agreedCount: number;

  paymentMethod: string = "pix";
  userCredit: number;

  email: string;

  user: any;

  msgDocumentFail: string;

  Currency: Currency = <Currency>{};

  constructor(
    private configService: ConfigService,
    private appComponent: AppComponent,
    private router: Router,
    private translate: TranslateService,
    private interactionService: InteractionService,
    private modalsService: ModalsService
  ) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.initializeBankinfo();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  initializeBankinfo() {
    this.configService.getUser(parseInt(localStorage.getItem('currentUser')))
      .subscribe(data => {
        if (data.bank != null) {
          this.userBank = data.bank;
          this.userAgency = data.agency;
          this.userAccount = data.account;
          this.cellphone = data.cellPhone;
          this.userAccountType = data.accountType > 0 ? data.accountType : 1;

          if (this.userAccountType <= 2) {
            this.paymentMethod = 'bank';
            this.bankList.find(x => x.ind == this.userAccountType).selected = true;
          }
          else {
            this.paymentMethod = 'pix';
            this.pixList.find(x => x.ind == this.userAccountType).selected = true;
          }
        }

        this.userCredit = data.credit;
      });

    // if (this.user.bank != null) {
    //   this.userBank = this.appComponent.userAdmin.bank;
    //   this.userAgency = this.appComponent.userAdmin.agency;
    //   this.userAccount = this.appComponent.userAdmin.account;
    //   this.userAccountType = this.appComponent.userAdmin.accountType > 0 ? this.appComponent.userAdmin.accountType : 1;

    //    if (this.userAccountType <= 2) {
    //      this.paymentMethod = 'bank';
    //      this.bankList.find(x => x.ind == this.userAccountType).selected = true;
    //    }
    //    else {
    //      this.paymentMethod = 'pix';
    //      this.pixList.find(x => x.ind == this.userAccountType).selected = true;
    //    }
    // }

    // this.userCredit = this.appComponent.userAdmin.credit;

  }

  checkedTest(payMethod: string) {
    if (this.paymentMethod == payMethod) {
      return true;
    }
    return false;
  }

  // newRequestCredit(paymentMethod: number) {
  //   // chekcing fields
  //   if (this.userBank == '' || this.userAgency == '' || this.userAccount == '' ||
  //     (this.requestedCredit <= 0 || typeof (this.requestedCredit) == 'undefined') ||
  //     (this.userAccountType < 3 && paymentMethod == 1) || (this.userAccountType > 2 && paymentMethod == 2)) {
  //     this.appComponent.msgStandard(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-required-fields"), 4);
  //     return;
  //   }

  //   // checking user credit
  //   //if (this.requestedCredit > this.appComponent.userAdmin.credit || this.requestedCredit < 50) {
  //   if (this.refineValue(this.requestedCredit) > this.appComponent.userAdmin.credit || this.refineValue(this.requestedCredit) < 20) {
  //     this.appComponent.msgStandard(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-request-credit"), 4);
  //     return;
  //   }

  //   let userId = this.appComponent.userAdmin.id;
  //   this.configService.UpdateUserBankInfo(userId, this.userBank, this.userAgency, this.userAccount, this.userAccountType)
  //     .subscribe(bankData => {
  //       this.configService.getDocumentCount(userId)
  //         .subscribe(documentData => {
  //           if (documentData < 3) {
  //             this.appComponent.msgStandard(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("withdraw.allowed-docs"), 4);
  //             return;
  //           }
  //           else {
  //             this.configService.postWithdraw(userId, this.refineValue(this.requestedCredit))
  //               .subscribe(withdrawData => {
  //                 this.appComponent.msgStandard(this.translate.instant("pg-checkout.success-request"), this.translate.instant("pg-checkout.success-request-info"), 3);
  //                 this.updateUserCredit(userId, this.refineValue(this.requestedCredit));
  //               }, errorWithdraw => {
  //                 this.appComponent.msgStandard(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-request-info"), 4);
  //                 console.log("Error WithDraw");
  //               });
  //           }
  //         }, errorDocument => {
  //           this.appComponent.msgStandard(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-request-info"), 4);
  //           console.log("Error Docuemnts");
  //         })
  //     }, errroBank => {
  //       this.appComponent.msgStandard(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-request-info"), 4);
  //       console.log("Error Bank");
  //     });
  // }

  newRequestCredit(paymentMethod: number) {
    // chekcing fields
    if (this.userAccount == '' || !this.cellphone || this.email == '' ||
      (this.requestedCredit <= 0 || typeof (this.requestedCredit) == 'undefined')) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-required-fields"), true);
      return;
    }

    if (this.refineValue(this.requestedCredit) > this.userCredit || this.refineValue(this.requestedCredit) < 20) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-checkout.fail-request"), this.translate.instant("pg-checkout.fail-request-credit"), true);
      return;
    }

    let withdrawRequest: WithdrawRequest = {
      userId: this.appComponent.userAdmin.id,
      bank: this.userBank,
      agency: this.userAgency,
      account: this.userAccount,
      accountType: this.userAccountType,
      cellphone: this.cellphone,
      email: this.email,
      creditRequest: this.refineValue(this.requestedCredit)
    }

    this.configService.postWithdraw(withdrawRequest)
      .subscribe(withdrawData => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-checkout.success-request"), this.translate.instant("pg-checkout.success-request-info"), false);
        this.userAccount = "";
        this.requestedCredit = 0;
        this.cellphone = 0;
        this.email = "";
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-checkout.fail-request"), this.translate.instant(error.error), true);
      });
  }

  changePaymentMethod(type: number) {
    if (type == 1) // means it's PIX account
    {
      this.paymentMethod = 'pix';
      this.userBank = 'pix';
      this.userAgency = 'pix';
    }
    else {
      this.paymentMethod = 'bank';
      this.userBank = '';
      this.userAgency = '';
    }
  }

  roundingFunction(value) {
    return Math.round(value * this.Currency.quote);
  }

  refineValue(value) {
    value = (value / this.Currency.quote).toFixed(2);
    return parseFloat(value)
  }

  updateUserCredit(userId: number, requestCredit: number) {
    let transactionType: number = 2;
    let bolaoId: number = 1;
    this.configService.updateUserCredit(bolaoId, requestCredit, transactionType, userId)
      .subscribe(data => {
      });
  }

  AdjustCurrency() {
    var element = this.configService.formatCurrencyValue(20)
    return element;
  }

  RedirectToAddDocs() {
    this.router.navigate(['/upload-doc']);
  }

}
