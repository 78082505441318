import { Component, OnInit } from '@angular/core';
import { SignalrService } from '../../signalr.service';
import { Round } from '../../round.interface';
import { DatePipe, LocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../../config.service';
import { AppComponent } from '../../app.component';
import { InteractionService } from '../../interaction.service';
import { Ticket } from '../../ticket.interface';
import { Currency } from '../../currency.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { EEventSinglematch } from '../../Interfaces/Enumerators/EEventSingleMatch';
import { RoundService } from 'src/app/round.service';
import { error } from 'console';
import { ShieldHelper } from 'src/app/Helpers/ShieldHelper';
import { ModalsService } from 'src/app/services/modals/modals.service';

const formula1Shields = [
  { team: 'Alpha Romeo', shield: 'f1-alpha-romeo.png', drivers: 'guanyu valtteri bottas' },
  { team: 'AlphaTauri', shield: 'f1-alphatauri.png', drivers: 'pierre gasly yuki tsunda' },
  { team: 'Alpine', shield: 'f1-alpine.png', drivers: 'esteban ocon fernando alonso' },
  { team: 'Aston Martin', shield: 'f1-aston-martin.png', drivers: 'sebastian vettel lance stroll' },
  { team: 'Ferrari', shield: 'f1-ferrari.png', drivers: 'charles leclerc carlos sainz zhou' },
  { team: 'Haas F1 Team', shield: 'f1-haas-f1-team.png', drivers: 'kevin magnussen mick schumacher hulkenberg' },
  { team: 'McLaren', shield: 'f1-mclaren.png', drivers: 'lando norris daniel ricciardo piastri' },
  { team: 'Mercedes', shield: 'f1-mercedes.png', drivers: 'george russell lewis hamilton' },
  { team: 'Red Bull', shield: 'f1-red-bull.png', drivers: 'max verstappen sergio perez tsunoda' },
  { team: 'Williams', shield: 'f1-williams.png', drivers: 'alexander albon nicholas latifi sargeant' }
];

@Component({
  selector: 'app-newbetgrid',
  templateUrl: './newbetgrid.component.html',
  styleUrls: ['./newbetgrid.component.scss']
})


export class NewbetgridComponent implements OnInit {
  rounds: any[];
  currentTicket: any = {} as Ticket;
  round: Round;
  Currency: Currency = <Currency>{};
  games: any;
  currentRankID: number = 0;
  otionSelected: number = 0;
  currentRank: number = 1;
  positionRank: number = 1;
  rankArray: number[] = [0];
  rankTypeRound: boolean = false;
  singleMatchRound: boolean = false;
  jiuJitsuRound: boolean = false;
  singleMatchOptions: any[] = [
    "Final Result",
    "More Ball Possession",
    "Total Goals",
    "Goals Home Team",
    "Goals Away Team",
    "Total Corners",
    "Corners Home Team",
    "Corners Away Team",
    "Shoots On Target",
    "Shoots On Target Home Team",
    "Shoots On Target Away Team",
    "Total Faults",
    "Yellow Cards",
    "Will be Penalty",
    "Goal in the 1st Half",
    "Goal in the 2nd Half",
    "GG (Both Score)",
    "Red Card",
  ]
  openedCollapses: { [key: string]: boolean } = {};
  roundIdParam: number;
  isOpenedRound: boolean;
  constructor(private datePipe: DatePipe,
    private translate: TranslateService, private configService: ConfigService,
    private appComponent: AppComponent, private interactionService: InteractionService,
    public router: Router, private activatedRoute: ActivatedRoute, private roundService: RoundService,
    private modalsService: ModalsService) {
    this.interactionService.betConfirmedSignal.subscribe(() => {
      this.openedCollapses[this.round.uuid4] = false;
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.roundIdParam = params['roundId'];
    })
    this.getCompleteActivatedRounds();

    this.getGlobalCurrency();
    this.configTicketPanelStateOnInit();

    localStorage.removeItem("Sport");
    localStorage.removeItem("SportCountry");
  }

  ngOnDestroy() { }

  getCompleteActivatedRounds() {
    this.roundService.roundList.subscribe(
      (completeRounds: any) => {
        if (!completeRounds) return
        completeRounds.sort((a, b) => {
          const dateA = new Date(a.startDateTime).getTime();
          const dateB = new Date(b.startDateTime).getTime();
          return dateA - dateB;
        });
        let today = this.datePipe.transform(new Date(), 'dd/MM');

        for (let i = 0; i < completeRounds.length; i++) {
          let suportDate = new Date(completeRounds[i].startDateTime);
          let differenceMinutes = localStorage.getItem('minute') != null ? localStorage.getItem('minute') : '0';
          let minutes = parseInt(differenceMinutes, 10);

          suportDate = new Date(suportDate.setMinutes(suportDate.getMinutes() + minutes));

          completeRounds[i].startDateTime = suportDate;

          let dateToDisplay = this.datePipe.transform(completeRounds[i].startDateTime, 'dd/MM');

          if (today === dateToDisplay) {
            dateToDisplay = `${this.translate.instant('pg-home.today')} ${this.datePipe.transform(completeRounds[i].startDateTime, 'HH:mm')}`;
          }
          else {
            dateToDisplay = this.datePipe.transform(completeRounds[i].startDateTime, 'dd/MM HH:mm');
          }

          completeRounds[i].startDateTimeFormatted = dateToDisplay;

          let name: string[] = [];
          if (completeRounds[i].singleMatchRound && completeRounds[i].jiuJitsuRound) {
            let teamNames = completeRounds[i].name.split((" X ").toUpperCase());
            name[0] = ShieldHelper.getBasicShield(teamNames[0]);
            name[1] = ShieldHelper.getBasicShield(teamNames[1]);
          }
          if (!completeRounds[i].singleMatchRound && !completeRounds[i].jiuJitsuRound) {
            name[0] = this.getMatchShield(completeRounds[i].name);
            name[1] = '';
            completeRounds[i].amountEvents = completeRounds[i].matches.length;
          }
          completeRounds[i].homeShield = name[0]
          completeRounds[i].awayShield = name[1]

        }
        completeRounds?.forEach(round => {
          if (!(round.uuid4 in this.openedCollapses)) {
            this.openedCollapses[round.uuid4] = false;
          }
        });

        this.rounds = completeRounds;
        if (this.rounds && this.roundIdParam && !this.isOpenedRound) {
          const rounduuid4 = this.rounds.find(x => x.id == this.roundIdParam)?.uuid4;
          const index = completeRounds.findIndex(x => x.uuid4 == rounduuid4);

          this.selectRound(rounduuid4, index)
          this.isOpenedRound = true;
        }
      }, error => {
        console.error(error);
      });
  }

  selectRound(roundUuid4: any, indexRound) {
    this.round = this.rounds.find(x => x.uuid4 === roundUuid4);
    if (this.openedCollapses[roundUuid4]) {
      this.openedCollapses[roundUuid4] = false;
    } else {
      Object.keys(this.openedCollapses).forEach(key => {
        this.openedCollapses[key] = false;
      });
      this.openedCollapses[roundUuid4] = true;
    }

    if (this.round.jiuJitsuRound == 1 && this.round.singleMatchRound == 1) {
      this.games = this.round.events;
    } else {
      this.games = this.round.matches;
    }

    this.interactionService.setHomeVisibleRound(this.round);
    this.interactionService.selectedRound = this.round;
    this.initRound();
    if (this.rounds && this.roundIdParam && !this.isOpenedRound) {
      setTimeout(() => {
        window.innerWidth >= 990 ? this.scrollTo('heading' + indexRound) : this.scrollTo('heading' + roundUuid4);
      }, 500);
    }
  }

  trackByRounds(item) {
    return item.uuid4;
  }

  scrollTo(element: any): void {
    (document.getElementById(element) as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }

  getGlobalCurrency() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  configTicketPanelStateOnInit() {
    this.interactionService.ticketPanelState
      .subscribe(data => {
        if (data != null) {
          this.currentTicket = data;
        } else
          this.initRound();
      });

    this.interactionService.matchRankArray
      .subscribe(data => {
        if (data != null) {
          if (this.positionRank == 0) {
            this.rankArray = data;
          }
          if (localStorage.getItem("currentRank") == "1") {
            localStorage.setItem("currentRank", "2")
            this.rankArray = data;
          }
          else this.currentRank = this.positionRank;
          for (let index = 1; index < this.rankArray.length + 1; index++) {
            if (!this.rankArray[index]) {
              this.currentRank = index;
              break;
            }
          }
        }
      });
  }

  AdjustCurrency(value) {
    value = this.configService.formatCurrencyValue(value)
    return value;
  }

  countEvents(round: Round) {
    if (round.singleMatchRound) {
      return 18
    } else if (round.jiuJitsuRound) {
      return 19
    } else {
      return round.matches.length
    }
  }

  getMatchShield(name: string) {
    let searchText = this.remove_accents(name);
    for (var i = 0; i < searchText.length; i++) {
      if (searchText.charAt(i) == " ") searchText = searchText.replace(" ", "%20")
    }
    searchText = searchText.replace("|", '');
    searchText = searchText.toLocaleLowerCase()
    let ret = 'https://poolbet365.com/assets/shields/' + searchText + '.png';
    if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
      if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
        return ret = '../../../assets/image/ChampionShipFlags/estrDavy.png'
      }
      return ret = '../../../assets/image/ChampionShipFlags/poker.png'
    }
    if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/laliga.png'
    if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/brasileirao.png'
    if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/image/ChampionShipFlags/motogp.png'
    if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/image/ChampionShipFlags/f1.png'
    if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/bl.png'
    if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/it.png'
    //if(name.toLocaleUpperCase().includes(("copa do mundo").toLocaleUpperCase()))return ret = '../../../assets/image/ChampionShipFlags/cp.png'
    if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase()) || name.toLocaleUpperCase().includes(("campeonato frânces").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/fr.png'
    if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/pl.png'
    if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/uefa.png'
    if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/international.png'
    return ret;
  }

  getFormula1Shield(driverName: string) {
    let searchText = driverName.split(" ");
    let ret = '../../../assets/shields/default-logo.png';
    searchText.forEach(element => {
      let found = formula1Shields.find(x => x.drivers.includes(element.toLowerCase()) && element.toLowerCase().length > 2);
      if (found != null) {
        ret = `https://poolbet365.com/assets/shields/${found.shield}`;
      }
    });
    return ret;

  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  setSingleMatchAverage(match) {
    if (match.homeName == 'Total Goals') {
      return match.totalGoals
    }
    if (match.homeName == 'Goals Home Team') {
      return match.goalsHomeTeam
    }
    if (match.homeName == 'Goals Away Team') {
      return match.goalsAwayTeam
    }
    if (match.homeName == 'Total Corners') {
      return match.totalCorners
    }
    if (match.homeName == 'Corners Home Team') {
      return match.cornerHomeTeam
    }
    if (match.homeName == 'Corners Away Team') {
      return match.cornerAwayTeam
    }
    if (match.homeName == 'Shoots On Target') {
      return match.shootsOnTarget
    }
    if (match.homeName == 'Shoots On Target Home Team') {
      return match.shootsOnTargetHomeTeam
    }
    if (match.homeName == 'Shoots On Target Away Team') {
      return match.shootsOnTargetAwayTeam
    }
    if (match.homeName == 'Total Faults') {
      return match.totalFaults
    }
    if (match.homeName == 'Yellow Cards') {
      return match.yellowCards
    }
  }

  rankCheck(id, select) {
    if (this.otionSelected != 0) this.otionSelected = select
    if (this.currentRankID == 0) {
      this.currentRankID = id;
    }
    if (this.currentRankID != id) {
      this.endRankSetBet()
    }
  }

  endRankSetBet() {
    this.currentRank = 1
    this.positionRank = 0;
    this.initRound();
    this.otionSelected = 0;
    this.currentRankID = 0;
    this.rankArray = [0];
    this.interactionService.clearMatchRank(this.rankArray)
  }

  async initRound() {
    if (!this.round) return;
    // this.idRound = this.round.number;
    // this.dateBegin = this.round.startDateTime.toString();
    // this.dateEnd = this.round.endDateTime.toString();
    this.rankTypeRound = this.round.rankRound ? true : false;
    this.singleMatchRound = this.round.singleMatchRound ? true : false;
    this.jiuJitsuRound = this.round.jiuJitsuRound ? true : false;
    this.rankArray = [0];
    const reset = localStorage.getItem("reset")
    if (reset == "true") {
      this.interactionService.clearMatchRank(this.rankArray)
    }
    if (this.currentRankID != this.round.id) {
      this.currentRank = 1;
    }
  }

  getSelectedRank(index: number) {
    if (this.currentTicket == null ||
      this.currentTicket.matches == null ||
      this.currentTicket.round.id != this.interactionService.homeVisibleRound.value.id ||
      !this.currentTicket.matches.find(e => e.id == index) ||
      this.currentTicket.matches.find(e => e.id == index).matchResult === '') {
      return '-';
    }
    return this.currentTicket.matches.find(e => e.id == index).matchResult;
  }

  setXY(event) {
    this.interactionService.setClickX(event.clientX);
    this.interactionService.setClickY(event.clientY);
    // event.srcElement.classList.toggle('bg-yellow');
  }

  formatingCheck(index: number, option: string) {
    if (this.currentTicket == null || this.currentTicket.matches == null || this.currentTicket.round.id != this.interactionService.homeVisibleRound.value.id) {
    } else {
      if (this.rankTypeRound && this.currentTicket.matches.find(e => e.id == index) && this.currentTicket.matches.find(e => e.id == index).matchResult) {
        return true;
      }
      if (this.currentTicket.matches.length >= (index + 1) && this.currentTicket.matches[index].matchResult.toUpperCase().includes(option.toUpperCase())) {
        return true;
      }
    }
    return false;
  }

  setBet(gameIndex, optionChoosen) {
    localStorage.setItem("reset", "false")
    if (1 > 2/*new Date(Date.now()) > this.round.startDateTime*/) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
    } else {
      // creating new ticket whether is the case
      this.interactionService.ticketPanelState
        .subscribe(data => {
          if (!data || !this.currentTicket.round || data.round.id != this.interactionService.homeVisibleRound.value.id) {
            this.currentTicket.round = this.round;
            this.games.forEach(element => {
              element.matchResult = '';
            });
            this.currentTicket.matches = this.games;
            this.currentTicket.quantity = 1;
            this.currentTicket.finalValue = this.round.value;
            this.currentTicket.date = new Date(Date.now());
          }
        });
      if (this.round.rankRound && !this.round.singleMatchRound && this.currentTicket.matches.find(e => e.id == gameIndex)) {
        if (this.currentTicket.matches.find(e => e.id == gameIndex).matchResult !== '') {
          this.rankArray[parseInt(this.currentTicket.matches.find(e => e.id == gameIndex).matchResult)] = 0;
          this.currentTicket.matches.find(e => e.id == gameIndex).matchResult = '';
        } else {
          this.currentTicket.matches.find(e => e.id == gameIndex).matchResult = optionChoosen;
          this.rankArray[parseInt(optionChoosen)] = 1;
          this.interactionService.setPlayBallAnimation(true);
        }
        //this.currentRank = 1;
        for (let index = 1; index < this.rankArray.length + 1; index++) {

          if (!this.rankArray[index]) {
            this.currentRank = index;
            break;
          }
        }
        // Sort by rank
        this.currentTicket.matches.sort((n1, n2) => parseInt(n1.matchResult) - parseInt(n2.matchResult));
      }
      if (!this.round.rankRound && !this.round.jiuJitsuRound) {
        // fulfill the array as needed
        if (this.currentTicket.matches[gameIndex].matchResult.includes(optionChoosen.toUpperCase())) {
          this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult.replace(optionChoosen.toUpperCase(), '');
        } else {

          this.currentTicket.matches[gameIndex].matchResult += optionChoosen.toUpperCase();
          this.interactionService.setPlayBallAnimation(true);
        }
        // formating and ordering items
        let one, x, two; //MoreBalla, MoreBallb, MoreBallx, totalGoalsa, totalGoalsb, TotalCornersa, TotalCornersb, YellowCardsa, YellowCardsb, CornerHomea, CornerHomeb, CornerAwaya, CornerAwayb, TotalFaultsa, TotalFaultsb, ShootsOnTargeta, ShootsOnTargetb, Penalty90Mina, Penalty90Minb, Goal1stHalfa, Goal1stHalfb, Goal2ndHalfa, Goal2ndHalfb, GGBothScorea, GGBothScoreb, RedCarda, RedCardb: boolean;
        if (this.currentTicket.matches[gameIndex].matchResult.includes('1')) {
          one = true;
        }
        if (this.currentTicket.matches[gameIndex].matchResult.includes('X')) {
          x = true;
        }
        if (this.currentTicket.matches[gameIndex].matchResult.includes('2')) {
          two = true;
        }

        this.currentTicket.matches[gameIndex].matchResult = '';
        if (one) {
          this.currentTicket.matches[gameIndex].matchResult = '1';
        }
        if (x) {
          this.currentTicket.matches[gameIndex].matchResult += 'X';
        }
        if (two) {
          this.currentTicket.matches[gameIndex].matchResult += '2';
        }
      }

      if (this.round.jiuJitsuRound && this.round.singleMatchRound) {
        if (this.currentTicket.matches[gameIndex].matchResult.includes(optionChoosen)) {
          this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult.replace(optionChoosen, '');
        } else {
          this.currentTicket.matches[gameIndex].homeName = this.currentTicket.matches[gameIndex].name;
          if (optionChoosen == "1") {
            this.currentTicket.matches[gameIndex].matchResult = "1" + this.currentTicket.matches[gameIndex].matchResult;
          }

          if (optionChoosen == "2") {
            this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult + "2";
          }

          if (optionChoosen != "1" && optionChoosen != "2") {
            if (this.currentTicket.matches[gameIndex].matchResult == "1") {
              this.currentTicket.matches[gameIndex].matchResult = this.currentTicket.matches[gameIndex].matchResult + "x";
            }

            if (this.currentTicket.matches[gameIndex].matchResult == "2") {
              this.currentTicket.matches[gameIndex].matchResult = "x" + this.currentTicket.matches[gameIndex].matchResult;
            }

            if (this.currentTicket.matches[gameIndex].matchResult == "12") {
              this.currentTicket.matches[gameIndex].matchResult = "1x2";
            }

            if (this.currentTicket.matches[gameIndex].matchResult == "") {
              this.currentTicket.matches[gameIndex].matchResult = "x";
            }
          }
        }
      }

      // check final ticket's value
      let doubleBets = 0;
      let tripleBets = 0;
      this.currentTicket.singleBet = true;
      if (!this.round.rankRound || (this.round.singleMatchRound == 1 && this.round.jiuJitsuRound == 1))
        this.currentTicket.matches.forEach(element => {
          if (element.matchResult.length == 2) {
            doubleBets += 1;
            this.currentTicket.singleBet = false;
          } else if (element.matchResult.length == 3) {
            tripleBets += 1;
            this.currentTicket.singleBet = false;
          }
        });
      this.currentTicket.round.value = Math.round(this.currentTicket.round.value);
      this.currentTicket.finalValue = Math.round((2 ** doubleBets) * (4 ** tripleBets) * (this.currentTicket.round.value * this.currentTicket.quantity));
      this.currentTicket.currencySymbol = this.Currency.currencySymbol;
      this.currentTicket.currencyQuote = this.Currency.quote;

      // update the global ticket's service
      this.interactionService.setTicketPanelState(this.currentTicket);
      this.interactionService.setMatchRank(this.rankArray);
    }
  }

  ticketListMobileOpen() {
    document.getElementById("dropdown-content")?.classList.toggle("show");
  }

  showEvent(element) {
    if (((this.checkSport(element.betradarSport.id) || this.checkCountry(element.countryId)) && localStorage.getItem('Sport') != null) || element.startDateTime < new Date()) return "hidden";
  }

  checkSport(id: string) {
    if (id != localStorage.getItem('Sport')) return true

    return false
  }

  checkCountry(id: string) {
    if (id != localStorage.getItem('SportCountry')) return true

    return false
  }

  showBlink(gameIndex) {
    if ((this.currentTicket == null || this.currentTicket.round == null)) {
      if (gameIndex == 0)
        return false;
      else
        return true;
    }

    if (this.rounds == null) {
      return true;
    }
    else {
      let openedPanel = this.rounds.find(x => x.isOpened);
      if (openedPanel != null && typeof (openedPanel) !== 'undefined' && openedPanel.id != this.currentTicket.round.id) {
        return true;
      }
    }
    let indexToBlink = 0;
    indexToBlink = this.currentTicket.matches.findIndex(checkResult);

    function checkResult(element) {
      return element.matchResult == '';
    }

    if (this.interactionService.selectedRound.id != this.currentTicket.round.id) {
      indexToBlink = 0;
    }

    return indexToBlink == gameIndex ? false : true;
  }

}
