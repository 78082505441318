<div class="container">
  <div class="row">
    <div class="card mb-5">

      <div class="card-body bg-theme bg-checkout">

        <div class="row checkoutBody">
          <div class="col-12 pt-3">
            <h3>{{ 'pg-checkout.request-payment' | translate }} </h3>
            <hr />
          </div>
          <div class="infoCheckout">
            <div style="width: 57%; font-size: 1.2rem;" class="sideLeftCheckout">
              <div class="theme_greyDG mb-2 p-3">
                {{'pg-payment.msg1' | translate}}
              </div>
              <div class="theme_greyDG p-3">
                {{'pg-payment.msg2' | translate}}
              </div>
            </div>

            <div class="theme_greyDG sideRightCheckout">   
              <span>{{ 'pg-checkout.wich-method' | translate }}:</span>
              <select name="checkout-method" id="checkout-method" class="infoCheckout_select mb-4">
                <option value="Pix">Pix</option>
              </select>
              
              <span>{{ 'pg-checkout.chave' | translate }}:</span>
              <input type="text" placeholder="Digite a sua chave pix" class="infoCheckout_input pl-2 mb-4" id="Pixkey" [(ngModel)]="userAccount">

              <div class="d-flex justify-content-between align-items-center">
                <span for="amount-value"> {{'round-header.value' | translate}}:</span>
                <span style="font-size: .5rem;">{{ 'pg-payment.min-value' | translate }}: {{AdjustCurrency()}}</span>
              </div>
              <input type="number" class="infoCheckout_input pl-2 mb-4" [(ngModel)]="requestedCredit" placeholder="Digite o valor">

              <span>{{ 'pg-settings.cellphone' | translate }} / Whatsapp:</span>
              <input type="number" class=" infoCheckout_input pl-2 mb-4" [(ngModel)]="cellphone" placeholder="Digite o seu contato">
            
              <span>E-mail:</span>
              <input type="email" class=" infoCheckout_input pl-2 mb-5" [(ngModel)]="email" placeholder="Digite o e-mail">

              <button  class="" (click)="newRequestCredit(1)" class="infoCheckout_button">
                {{ 'pg-checkout.request-credit' | translate }}
              </button>
            </div>
          </div>
        </div>
        <!--
        <p style="font-size: 15px; padding: 5px; padding-top: 5%;">
          {{ 'pg-checkout.doc-info1' | translate }}
          <a routerLink="/upload-doc">{{ 'menu.upload-doc' | translate }}</a> {{ 'pg-checkout.doc-info2' |
          translate }}
        </p>
        -->
      </div>

    </div>
  </div>

</div>

<div mdbModal #documentFail="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1"
  role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-primary modalVideoTutorial" role="document">
    <div class="modal-content bg-theme modalVideoTutorial__content">

      <div class="modal-header" style="background-color: #dc3545;">
        <p class="heading lead" style="font-weight: bold; margin-left: 30%; ">
          {{ 'pg-banners.operation-fail' | translate}}
        </p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="documentFail.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="text-center" style="color: white">
          <p>
            {{msgDocumentFail}}
          </p>
        </div>
      </div>

      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn class="btn" mdbWavesEffect (click)="RedirectToAddDocs()" data-dismiss="modal"
          style="text-transform: capitalize;width: 300px;background-color: #dc3545;border: 1px solid transparent;border-color: #dc3545;color: #fff;font-weight: bold; font-size: 1.3rem; padding: 10px;">
          {{ 'upload-doc.documents' | translate}}</a>
      </div>

    </div>
  </div>
</div>

<!-- OLD PAGE
<div class="card-group rule-spaces">
  <mdb-card class="bg-theme">
    <mdb-card-body>
      <mdb-card-title>

      </mdb-card-title>

      <hr>

      <div style="color: white !important">
        {{ 'pg-checkout.form-description' | translate }}<br />
        <span class="obs">{{ 'pg-checkout.form-obs' | translate }}</span>
      </div>
      <div style="text-align: center;">
        <div class="row">
          <div class="col col-6">
            <div class="mb-2 row">
              <mat-label class="col col-6">{{ 'withdraw.bank' | translate }}</mat-label>
              <input matInput class="center-content code-field col col-6" [(ngModel)]="userBank">
            </div>
            <div class="mb-2 row">
              <mat-label class="col col-6">{{ 'withdraw.agency' | translate }}</mat-label>
              <input matInput class="center-content code-field col col-6" [(ngModel)]="userAgency">
            </div>
            <div class="mb-2 row">
              <mat-label class="col col-6">{{ 'withdraw.account' | translate }}</mat-label>
              <input matInput class="center-content code-field col col-6" [(ngModel)]="userAccount">
            </div>
          </div>
          <div class="col col-6">
            <button class="btn btn-primary" (click)="onBankInfoSaved()">Save</button>
          </div>
        </div>
        <mat-form-field>
          <mat-label>{{ 'pg-checkout.desired-value' | translate }}</mat-label>
          <span matPrefix>R$ </span>
          <input matInput value="0" class="center-content code-field" [(ngModel)]="requestedCredit">
          <span matSuffix>,00</span>
        </mat-form-field>
        <br />
        <button mat-flat-button color="primary" (click)="requestCredit()">{{ 'pg-checkout.request' | translate
          }}</button>
      </div>
    </mdb-card-body>
  </mdb-card>
</div> -->
