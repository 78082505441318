import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AppComponent } from 'src/app/app.component';
import { ConfigService } from 'src/app/config.service';
import { User } from 'src/app/user.interface';
import { Currency } from '../../currency.interface';
import { InteractionService } from 'src/app/interaction.service';
import { ActivatedRoute } from '@angular/router';
import { Login } from 'src/app/login.interface';

@Component({
  selector: 'app-api-lobby',
  templateUrl: './api-lobby.html',
  styleUrls: ['./api-lobby.scss']
})
export class ApiLobbyComponent implements OnInit {
  userAdmin: User;
  Currency: Currency = <Currency>{};
  login: string;
  email: string;
  credit: number;
  parentId: number;
  visitorId: number;
  key: string;

  constructor(private configService: ConfigService, private interactionService: InteractionService, private cookieService: CookieService,
              private activatedRoute: ActivatedRoute, private appComponent: AppComponent) { }

  ngOnInit(): void {
    this.login = this.activatedRoute.snapshot.paramMap.get("login");
    this.email = this.activatedRoute.snapshot.paramMap.get("email");
    this.credit = +this.activatedRoute.snapshot.paramMap.get("credit");
    this.parentId = +this.activatedRoute.snapshot.paramMap.get("parentId");
    this.visitorId = +this.activatedRoute.snapshot.paramMap.get("visitorId");
    this.key = this.activatedRoute.snapshot.paramMap.get("key");

    this.getCurrency();
    this.registerAPIUser();
  }

  // getUser(userId: number) {
  //   this.configService.getUser(userId)
  //   .subscribe((user: User) => {
  //     this.userAdmin = user;
  //   });
  // }

  getCurrency() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  registerAPIUser() {
    this.configService.registerAPIUser(this.login, this.email, this.credit, this.parentId, this.visitorId, this.key)
      .subscribe((data: User) => {
        let loginData: Login = {
          user: data.login,
          token: this.key,
          message: 'Login success'
        }
        // this.getUser(data.id);
        this.userAdmin = data;
        this.interactionService.setLoginData(loginData);
        this.appComponent.userAdmin = data;
        this.appComponent.userAdmin.userPermissions =
          [{ value: true }, { value: false }, { value: false }, { value: false }, { value: false }, { value: false }]
        localStorage.setItem('currentUser', data.id.toString());
        localStorage.setItem('currentUserToken', this.key);
        if (data.country == null) {
          localStorage.setItem('currentUserCountry', 'Brazil');
        } else {
          localStorage.setItem('currentUserCountry', data.currencyCode);
        }
        this.interactionService.setIsLogged(true);
        this.cookieService.set('user', JSON.stringify(data));
        this.configService.setGlobalCurrency();
      }, error => {
        console.log(error);
      });
  }

}
