<div class="row mt-2 rowlajust mobile-hide" style="background-color: #161515;">
  <div class="col-12 p-0">
    <div class="side-menu">
      {{'pg-tickets.tickets-list' | translate }}
    </div>
    <div class="serch-ticket">

      <div class="row mt-2 d-flex justify-content-center ml-1 mr-1 mb-1 p-1"
        style="border-radius: 5px; font-weight: bold; font-size: 16px; background-color: rgb(33 32 32); color: whitesmoke;">
        <div [innerHtml]="title">
        </div>
      </div>

      <div class="d-flex justify-content-around m-0 pl-2 pr-2 flex-colum" style="margin-bottom: .5rem">
        <div class="adjust-init-round" style="padding: 0 2px; width: 50%;">
          <input type="text" value="{{countDown}}{{'Start Round' | translate}}" disabled
            style="background-color: #F6B40E; color: #000; font-size: 16px; font-weight: 450;" />
        </div>

        <div class="m-0 adjust-center">
          <span class="rectangle" style="background-color: rgb(33 32 32); font-weight: 450;">
            {{numberEvents}} {{ ('events' | translate).toLowerCase() }}
          </span>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12 table-responsive">

          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource">

              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef class="text-center adjust-font"> {{ 'pg-result.ticket' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index" (click)="detailCoupon(element.betUuid8)"
                  class="coupom-code">
                  <span class="link-id adjust-font">{{element.betUuid8.slice(0,8)}}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="login">
                <th mat-header-cell *matHeaderCellDef class="text-center adjust-font"> {{ 'pg-tickets.player' | translate}} </th>
                <td class="adjust-font" mat-cell *matCellDef="let element; let i = index">
                  {{element.playerName}} </td>
              </ng-container>

              <ng-container matColumnDef="country">
                <th mat-header-cell *matHeaderCellDef class="text-center adjust-font"> {{ 'pg-app-component.country' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index;" style="color: black!important;">
                  <!-- <img src="https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg" width="15" /> -->
                  <img src="{{getFlagByName(element.country)}}" width="20" />
                </td>
              </ng-container>

              <tr mat-header-row  *matHeaderRowDef="displayedColumns" class="text-center" style="background-color: #161515;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="bg-theme-grey text-center" style="border: none;">
              </tr>

            </table>

            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons
              (page)="onPageChange()" style="background-color: #131313;"></mat-paginator>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
