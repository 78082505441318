<div mdbModal #ticketModal="mdbModal" class="modal fade right" id="frameModalTop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
    <div class="modal-dialog modal-notify modal-success" role="document" id="msgModalStandard">
        <div class="modal-content  bg-theme" style="height: auto; max-height: 700px;">
            <div class="modal-header text-center">
                <h4 class="modal-title w-100 font-weight-bold" style="color: white" [innerHtml]="ticketTitle"></h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="ticketModal.hide()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-3">
                <p style="color: white" id="ticketBody" [innerHtml]="ticketBody"></p>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <a type="button" color="waves-light button-font" style="padding:.54rem 2.14rem; background-color:#33a0df;" class="btn" mdbWavesEffect (click)="ticketModal.hide()"
                    data-dismiss="modal">OK</a>
            </div>
        </div>
    </div>
</div>