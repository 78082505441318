import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { ModalDirective } from 'angular-bootstrap-md';

import { AppComponent } from '../app.component';
import { ConfigService } from '../config.service';

import { User, UserLoginForm } from '../user.interface';
import { Jurisdiction } from '../jurisdiction.interface';
import { TranslateService } from '@ngx-translate/core';
import axios from "axios";
import { Currency } from '../currency.interface';
import { InteractionService } from '../interaction.service';
import { subscribeOn } from 'rxjs/operators';
import { UploadDocComponent } from '../upload-doc/upload-doc.component';
import { MatDialog } from '@angular/material/dialog';
import { UserDetailComponent } from '../user-detail/user-detail.component';
import { DocumentResponseDto } from '../document.interface';
import { Withdraw } from '../withdraw.interface';
import { debug } from 'console';
import { ModalsService } from '../services/modals/modals.service';
interface JurisdictionNode {
  name: string;
  login: string;
  jurisdictionId: number;
  id: number;
  children?: JurisdictionNode[];
}

interface DescriptionNode {
  descId: number;
  userId: number;
  status: number;
  type: number;
  description: string;
}

@Component({
  selector: 'app-jurisdiction',
  templateUrl: './jurisdiction.component.html',
  styleUrls: ['./jurisdiction.component.scss']
})
export class JurisdictionComponent implements OnInit {
  @ViewChild('frame', { static: true }) modalCreate: ModalDirective;
  @ViewChild('frame1', { static: true }) modalDetail: ModalDirective;
  @ViewChild('frame2', { static: true }) modalDelete: ModalDirective;
  @ViewChild('frame3', { static: true }) modalEdit: ModalDirective;
  @ViewChild('frame4', { static: true }) modalDescription: ModalDirective;
  @ViewChild('frame6', { static: true }) modalCreditTransfer: ModalDirective;
  @ViewChild('frame7', { static: true }) modalConfirmTransfer: ModalDirective;
  @ViewChild('frame8', { static: true }) modalConfirmDelete: ModalDirective;

  displayedColumns: string[] = ['id', 'login', 'name', 'email', 'actions'];
  descItem: DescriptionNode = {
    descId: 0,
    userId: 0,
    status: 1,
    type: 0,
    description: ''
  };
  //displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  //dataSource2 = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource2 = new MatTableDataSource<User>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  editUserInfo: User;
  myUsers: User;
  parentUser = {} as User;
  treeList: User[];
  treeListRemove: User[];
  jurisdictionToDelete: number;
  jurisdictions: Jurisdiction[];
  msgResponse: string = "";
  isLoaded: boolean = false;
  senhaOk: boolean = false;
  emailOk: boolean = false;
  coupon: string;
  availableValue: number;
  isPlayer: boolean = false;

  userEditable: User;
  showPassword: boolean = false;
  userCredit: number;
  Currency: Currency = <Currency>{};
  jurisdictionForm = this.fb.group({
    jurisdictionId: ['', Validators.required],
    login: ['', Validators.required],
    //commission: ['', Validators.required],
    commission: [0],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    parentId: ['', Validators.required],
    obs: [''],
    name: ['', Validators.required]
  });

  TREE_USERS: JurisdictionNode[] = [];
  treeControl = new NestedTreeControl<JurisdictionNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<JurisdictionNode>();
  isAdmin: boolean;
  userSelected: any;
  //usersTable: User[] = [];
  paises: string[];
  imageShowFlag: boolean;
  imageSrc: string;
  customerInfo: User;
  docList: DocumentResponseDto[];
  withdrawList: Withdraw[];
  descList: string[] = [
    'upload-doc.doc_type_0',
    'upload-doc.doc_type_1',
    'upload-doc.doc_type_2',
    'upload-doc.doc_type_3',
    'upload-doc.doc_type_4'
  ];
  statusList: string[] = [
    'upload-doc.PENDING',
    'upload-doc.FAILED',
    'upload-doc.SUCCESS',
  ];
  selectedUser: any;

  crediTtransferSelected = "deposit";
  transferValue: number;
  newValue: number;

  constructor(
    private interactionService: InteractionService,
    private configService: ConfigService,
    public appComponent: AppComponent,
    private fb: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private modalsService: ModalsService
  ) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.imageSrc = '';
    this.imageShowFlag = false;
    this.docList = [];
    this.withdrawList = [];
  }

  hasChild = (_: number, node: JurisdictionNode) => !!node.children && node.children.length > 0;

  ngOnInit() {

    this.isAdmin = false;
    this.TREE_USERS = [];
    this.isLoaded = false;
    this.senhaOk = false;
    this.emailOk = false;

    this.listTree();
    this.listJurisdiction();
    this.listUsers();
    this.getUsersTreeList();
    this.dataSource2.paginator = this.paginator;
    this.userEditable = this.appComponent.userAdmin;
    this.userCredit = Number((this.userEditable.credit * this.Currency.quote).toFixed(2));

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }


  permissionModal(id, frame) {
    this.configService.getUser(id).subscribe((data: any) => {
      this.selectedUser = data
    })
    frame.show()
  }
  cancelPermission(frame) {
    frame.hide()
  }
  updatePermission(frame) {
    this.selectedUser.userPermissions.forEach((element, i) => {
      this.configService.updatePermissions(this.selectedUser.id, element).subscribe(res => {
        if (this.selectedUser.userPermissions.length - 1 == i) {
          frame.hide()
        }
      })
    });
  }

  permissionChange(event, i) {
    this.selectedUser.userPermissions[i].value = event.checked
  }

  AddCountry() {
    const pais = 'https://free.currconv.com/api/v7/countries?&apiKey=085a5c4bbbd6e6750bfc'
    axios.get(pais)
      .then((response) => {
        var countries = response.data.results;
        for (const prop in countries) {
          this.configService.AddCountry(countries[prop].currencyId, countries[prop].name, countries[prop].currencySymbol)
            .subscribe(data => {


            }, error => {

            });
        }
      })
  }

  async transferDecline(frame) {
    if (this.descItem.type == 0) { //Doc
      this.docList = await this.configService.updateDocument(this.descItem.userId, this.descItem.descId, this.descItem.status, this.descItem.description).toPromise();
    } else {
      this.withdrawList = await this.configService.updateWithdraw(this.descItem.userId, this.descItem.descId, this.descItem.status, this.descItem.description).toPromise();
    }
    frame.hide();
  }

  onEditClicked(customer: User, frame) {
    this.editUserInfo = customer;
    this.getDocuments();
    this.getWithdraws();
    // const dialogRef = this.dialog.open(UserDetailComponent, {
    //   width: '650px',
    //   data: {userId: customer.id, name: customer.login, customer: customer},
    //   panelClass: 'backdropBackground'
    // });
    //
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log('The dialog was closed');
    // });
    frame.show();
  }

  async getDocuments(): Promise<void> {
    if (this.editUserInfo.id) {
      this.docList = await this.configService.getDocument(this.editUserInfo.id).toPromise();
    }
  }

  async getWithdraws(): Promise<void> {
    if (this.editUserInfo.id) {
      this.withdrawList = await this.configService.getWithdraw(this.editUserInfo.id).toPromise();
    }
  }

  async onAgreeClicked(docId: number) {
    this.docList = await this.configService.updateDocument(this.editUserInfo.id, docId, 2, '').toPromise();
    // this.getDocuments();
  }

  onDeclineClicked(docId: number, frame) {
    this.descItem.type = 0;
    this.descItem.status = 1;
    this.descItem.userId = this.editUserInfo.id;
    this.descItem.descId = docId;
    frame.show();
  }

  onDeclineWithdrawClicked(withdrawId: number, frame) {
    this.descItem.type = 1;
    this.descItem.status = 1;
    this.descItem.userId = this.editUserInfo.id;
    this.descItem.descId = withdrawId;
    frame.show();
  }

  onDescriptionClicked(type: number, status: number, descId: number, desc: string, frame) {
    this.descItem.type = type;
    this.descItem.status = status;
    this.descItem.userId = this.editUserInfo.id;
    this.descItem.descId = descId;
    this.descItem.description = desc;
    frame.show();
  }

  async onAgreeWithdrawClicked(withdrawId: number) {
    this.withdrawList = await this.configService.updateWithdraw(this.editUserInfo.id, withdrawId, 2, '').toPromise();
    // this.getWithdraws();
  }

  onImageOpen(fileName: string) {
    let src = `../../assets/documents/${fileName}`;
    this.imageSrc = src;
    this.imageShowFlag = true;
  }

  onImageClose() {
    this.imageShowFlag = false;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource2.filter = filterValue.trim().toLowerCase();
  }

  getUsersTreeList() {
    if (this.appComponent.userAdmin.jurisdictionId === 1) { this.isAdmin = true; }
    //this.configService.GetUsersList(this.appComponent.userAdmin.id)
    this.configService.getUsersTreeList(this.appComponent.userAdmin.id)
      .subscribe(data => {
        //this.dataSource2.data = data.filter(x => x.deleted == false);
        this.dataSource2.data = data.filter(x => x.deleted == false).sort((a, b) => (a.id < b.id) ? 1 : -1);
      }, error => {
        console.log(error);
        this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.operation-failed"), this.translate.instant("pg-jurisdiction.operation-failed-info"), true);
      })
  }

  listUsers() {
    this.configService.getUsersTreeList(this.appComponent.userAdmin.id)
      .subscribe(data => {
        this.treeList = data;
        this.treeListRemove = data;
      }, error => {
        console.log(error);
      });
  }

  listJurisdiction() {
    this.configService.getJurisdictionsById(this.appComponent.userAdmin.jurisdictionId)
      .subscribe(data => {
        this.jurisdictions = data;
      }, error => {
        console.log(error);
      });
  }

  listTree() {
    this.configService.getUserTree(this.appComponent.userAdmin.id)
      .subscribe(data => {
        this.myUsers = data;

        this.TREE_USERS.push({
          name: this.myUsers.login,
          login: this.myUsers.login,
          id: this.myUsers.id,
          jurisdictionId: this.myUsers.jurisdictionId,
          children: this.myUsers.children
        });

        this.dataSource.data = this.TREE_USERS;

        document.getElementById("spinner-loading").classList.add("hidden");
        this.isLoaded = true;

      }, error => {
        console.log(error);
      });
  }

  updateListUsers(jurisdictionLevel: number) {
    this.configService.GetJurisdictionLevelByUser(this.appComponent.userAdmin.id, jurisdictionLevel)
      .subscribe(data => {
        this.treeList = data;
        this.treeListRemove = data;
        this.jurisdictionForm.get("parentId").enable();
        this.selectSingleParent();
        if (jurisdictionLevel == 7)
          this.isPlayer = true;
        else
          this.isPlayer = false;
      }, error => {
        console.log(error);
      });
  }

  addNewUserAdmin(frame) {
    /* new-user page created; this function is not used anymore
    let newUser: UserLoginForm = {
      email: this.jurisdictionForm.get('email').value,
      jurisdictionId: this.jurisdictionForm.get('jurisdictionId').value,
      login: this.jurisdictionForm.get('login').value,
      commission: this.jurisdictionForm.get('commission').value,
      parentId: this.jurisdictionForm.get('parentId').value,
      password: this.jurisdictionForm.get('password').value,
      name: this.jurisdictionForm.get('name').value,
      lastName: ""
    }

    this.configService.addNewUser(newUser)
    .subscribe(() => {
      frame.hide();
      this.appComponent.msgStandard(this.translate.instant("pg-jurisdiction.operation-successfull"), this.translate.instant("pg-jurisdiction.user") + this.jurisdictionForm.get('login').value + this.translate.instant("pg-jurisdiction.successfull-created"), 3);
      this.jurisdictionForm.reset(this.jurisdictionForm.value);
      this.ngOnInit();
    }, error => {
      console.log(error);
      this.appComponent.msgStandard(this.translate.instant("pg-jurisdiction.operation-failed"), this.translate.instant("pg-jurisdiction.operation-failed-info"), 4);
    });
    */
  }

  /*
  removeUserAdmin(frame) {
    this.configService.removeUser(this.jurisdictionToDelete)
    .subscribe((data: User) => {
      //alert("Usuário " +  data.name + " removido com sucesso.");
      this.appComponent.msgStandard(this.translate.instant("pg-jurisdiction.operation-successfull"), this.translate.instant("pg-jurisdiction.user") +  data.name + this.translate.instant("pg-jurisdiction.successfull-deleted"), 3);
      this.ngOnInit();
      frame.hide();
    }, error => {
      this.appComponent.msgStandard(this.translate.instant("pg-jurisdiction.operation-failed"), this.translate.instant("pg-jurisdiction.operation-failed-info"), 4);
      console.log(error);
    });
  }*/

  blockUser(userId: number) {
    this.configService.blockUser(userId)
      .subscribe((data: User) => {
        this.modalConfirmDelete.hide();
        this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.operation-successfull"), this.translate.instant("pg-jurisdiction.user") + this.userEditable.login + this.translate.instant("pg-jurisdiction.successfull-deleted"), false);
        this.ngOnInit();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.fail"), this.translate.instant("pg-jurisdiction.operation-failed-info"), true);
        console.log(error);
      });
  }

  editJurisdiction(userId: number) {
    if (userId == this.appComponent.userAdmin.id) {
      this.router.navigate(['/settings']);
    }
    else {
      this.configService.getUser(userId)
        .subscribe(data => {
          this.userEditable = data;
          this.configService.getBasicUser(data.parentId)
            .subscribe(parent => {
              this.parentUser = parent
            }, err => {
              this.parentUser.name = 'hidden';
            });
          this.userCredit = Number((data.credit * this.Currency.quote).toFixed(2));
          this.coupon = "";
          this.availableValue = 0;
          this.configService.getCoupon(userId)
            .subscribe(data => {
              if (data != null) {
                this.coupon = data.code;
                this.availableValue = data.availableValue;
              }
            });
          this.modalEdit.show();
        }, error => {
          console.log(error);
        });
    }
  }

  generateCoupon() {
    this.configService.generateJurisdictionCoupon()
      .subscribe(data => {
        this.coupon = data.code;
      }, error => {
        this.modalsService.openNotificationModal("Erro na Requisição", "Não foi possível gerar novo código de cupom.", true);
      })
  }

  showPasswordField() {
    this.showPassword = true;
  }

  openEditUser(userId: number) {
    this.router.navigate(['/edit-user/' + userId]);
  }

  editUser(form: NgForm) {
    if (form.value.userEmail != "") {
      this.modalEdit.hide();
      this.isLoaded = false;
      this.userEditable.name = form.value.userName;
      if (form.value.userPassword != undefined) {
        this.userEditable.password = form.value.userPassword;
      }
      this.userEditable.lastName = form.value.lastName;
      this.userEditable.email = form.value.userEmail;
      this.userEditable.country = form.value.country;
      this.userEditable.city = form.value.city;
      this.configService.updateUser(this.userEditable.id, this.userEditable)
        .subscribe(data => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.edited-successfull"), this.translate.instant("pg-jurisdiction.edited-successfull-info"), false);
          this.configService.saveJurisdictionCoupon(this.userEditable.id, this.coupon, this.availableValue)
            .subscribe(data => {
              //...
            }, error => {
              //...
            })
          this.ngOnInit();
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.edited-failed"), this.translate.instant("pg-jurisdiction.edited-failed-info"), true);
          console.log(error);
          this.ngOnInit();
        });
    }
    else {
      this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.empty-email"), this.translate.instant("pg-jurisdiction.empty-email-info"), true);
    }
  }

  //compararSenha(){
  comparePassword() {
    this.senhaOk = this.jurisdictionForm.get('confirmPassword').value === this.jurisdictionForm.get('password').value;
  }

  //emailErrado() {
  wrongEmail() {
    this.emailOk = !this.jurisdictionForm.get('email').hasError('email');
  }

  autoLogin(userId: number) {
    this.configService.getUser(userId)
      .subscribe(data => {
        //let token = localStorage.getItem('currentUserToken');
        //window.open("/api/" + data.login + "/" + data.email + "/" + data.parentId + "/" + data.id + "/" + token);
        localStorage.setItem('currentUser', data.id.toString());
        location.reload();
      }, error => {
        //..
      });
  }

  openCreditTransferModal(userId: number) {
    this.configService.getUser(userId)
      .subscribe(data => {
        this.userEditable = data;
      }, error => {
        console.log(error);
      });
    this.modalCreditTransfer.show();
  }

  openConfirmCreditTransferModal() {
    // check errors
    if (typeof this.transferValue === 'undefined' || this.transferValue == 0 || this.transferValue == null) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.fail"), this.translate.instant("pg-jurisdiction.fail-value-zero"), true);
    }
    else if (this.crediTtransferSelected == 'withdraw' && this.userEditable.credit < this.transferValue) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-jurisdiction.fail"), this.translate.instant("pg-jurisdiction.fail-value-higher"), true);
    }
    else {
      if (this.crediTtransferSelected == 'deposit') {
        this.newValue = this.transferValue + this.userEditable.credit;
      }
      else {
        this.newValue = this.userEditable.credit - this.transferValue;
      }

      this.modalConfirmTransfer.show();
    }
  }

  closeCreditTransferModal() {
    this.transferValue = null;
    this.modalCreditTransfer.hide();
  }

  creditTransfer() {
    let valueToTransfer = this.transferValue / this.Currency.quote;

    let transactionType = this.crediTtransferSelected == 'deposit' ? 1 : 2;
    this.configService.updateUserCredit(this.appComponent.userAdmin.id, valueToTransfer, transactionType, this.userEditable.id)
      .subscribe(data => {
        this.configService.getUser(this.appComponent.userAdmin.id)
          .subscribe(user => {
            this.appComponent.userAdmin.credit = user.credit;
          });
        this.modalConfirmTransfer.hide();
        this.closeCreditTransferModal();
        this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-done"), this.translate.instant("pg-credit-transfer.transfer-done-info"), false);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-credit-transfer.transfer-failed"), this.translate.instant("pg-credit-transfer.transfer-failed-info"), true);
        console.log(error);
      });
  }

  openDeleteModal(userId: number) {
    this.configService.getUser(userId)
      .subscribe(data => {
        this.userEditable = data;
      }, error => {
        console.log(error);
      });
    this.modalConfirmDelete.show();
  }

  selectSingleParent() {
    if (this.treeList.length == 1) {
      this.userSelected = this.treeList[0].id;
    }
  }

  formatingGreyCheck(index: number) {
    if ((index % 2) != 0) {
      return true;
    }
    return false;
  }

  formatingRedCheck(delFlag: number) {
    if (delFlag == 1) {
      return true;
    }
    return false;
  }

}
