import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { AppComponent } from '../../app.component';
import { ConfigService } from '../../config.service';
import { TranslateService } from '@ngx-translate/core';
import { Ticket } from '../../ticket.interface';
import { Round } from '../../round.interface';
import { InteractionService } from '../../interaction.service';
import { Prize } from '../../prize.interface';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Currency } from '../../currency.interface';
import { TableChoicesMadeService } from '../../services/table-choices-made/table-choices-made.service';
import { ModalsService } from '../../services/modals/modals.service';

@Component({
  selector: 'app-ticket-panel',
  templateUrl: './ticket-panel.component.html',
  styleUrls: ['./ticket-panel.component.scss'],
  providers: [DatePipe]
})
export class TicketPanelComponent implements OnInit {

  @ViewChild('frame', { static: true }) modalCreateTicket: ModalDirective;
  @ViewChild('frame2', { static: true }) modalFinishTicket: ModalDirective;
  @ViewChild('frame3', { static: true }) modalWhatsapp: ModalDirective;
  @ViewChild('frame4', { static: true }) modalLogin: ModalDirective;
  @ViewChild('frame5', { static: true }) modalWithoutCredit: ModalDirective;
  htmlToAdd: string = "";

  RoundName: string;
  teamHome: string;
  teamAway: string;
  TGol: string;
  GolHome: string;
  GolAway: string;
  CornerAway: string;
  CornerHome: string;
  Shoot: string;
  ShootHome: string;
  ShootAway: string;
  Tfault: string
  TCorners: string;
  yCard: string = null;
  ticketState = {} as Ticket;
  playerName: string = "";
  isChecked: boolean = false;
  round: Round;
  rankArray: number[] = [0];
  prizes: Prize[] = [];
  prizeAmount: any;
  jackpotAmount: any;
  roundName: string;
  info: any = [];
  posteCoupon: boolean = false;
  Currency: Currency = <Currency>{};
  noEnoughCredit: string[] = [];
  prize: string = "";
  CreationDate;
  showShields: boolean = false;
  names: string[] = [];
  totalMatchs: number;
  processingBet: boolean = false;

  constructor(public appComponent: AppComponent,
    private configService: ConfigService, public translate: TranslateService,
    private interactionService: InteractionService, private router: Router, public datepipe: DatePipe, private cdr: ChangeDetectorRef,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.interactionService.ticketPanelState
      .subscribe(data => {
        this.ticketState = data;
      });

    this.interactionService.homeVisibleRound
      .subscribe(round => {
        if (round != null) {
          this.round = round;
          if (this.round.jiuJitsuRound) {
            this.round.homeName = this.splitFighterName(this.round.name, 1);
            this.round.awayName = this.splitFighterName(this.round.name, 2);
          }

          this.setPrizes(round);
        }
      });

    this.interactionService.matchRankArray
      .subscribe(data => {
        if (data != null) {
          this.rankArray = data;
        }
      });

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  setPrizes(round: Round) {
    this.prizeAmount = round.prizes[1].gathered;
    this.jackpotAmount = round.prizes[0].gathered;
  }

  roundOrganizer() {
    this.setTotalMatchs();
    if (this.ticketState.round.singleMatchRound == 1) {
      this.RoundName = this.ticketState.round.name
      let nameTeam: string[]
      this.RoundName = this.RoundName.replace(" (", " X ")
      this.RoundName = this.RoundName.replace(" - ", " X ")
      nameTeam = this.RoundName.split(" X ");
      this.teamHome = nameTeam[0]
      this.teamAway = nameTeam[1]
      this.ticketState.matches.find(elemetn => {
        this.TGol = elemetn.totalGoals;
        this.CornerAway = elemetn.cornerAwayTeam;
        this.CornerHome = elemetn.cornerHomeTeam;
        this.Shoot = elemetn.shootsOnTarget;
        this.Tfault = elemetn.totalFaults;
        this.TCorners = elemetn.totalCorners;
        this.yCard = elemetn.yellowCards;
        this.GolHome = elemetn.goalsHomeTeam;
        this.GolAway = elemetn.goalsAwayTeam;
        this.ShootHome = elemetn.shootsOnTargetHomeTeam;
        this.ShootAway = elemetn.shootsOnTargetAwayTeam;
      });
    }
    return
  }

  getTranslate(str) {
    return this.translate.instant(str);
  }

  splitResults(matchResult: string) {
    if (this.ticketState.round.name != this.RoundName) this.roundOrganizer();
    return matchResult.split("");
  }

  roundingFunction(value) {
    return Math.round(value * this.ticketState.currencyQuote);
  }

  onChangeQuantity(ticketquantity: number) {
    if (ticketquantity == 0) {
      ticketquantity = 1;
    }
    // update final ticket's value when change its quantity
    this.ticketState.quantity = ticketquantity;
    let doubleBets = 0;
    let tripleBets = 0;
    this.ticketState.singleBet = true;
    if (!this.round.rankRound) {
      this.ticketState.matches.forEach(element => {
        if (element.matchResult.length == 2) {
          doubleBets += 1;
          this.ticketState.singleBet = false;
        }
        else if (element.matchResult.length == 3) {
          tripleBets += 1;
          this.ticketState.singleBet = false;
        }
      });
    }
    this.ticketState.finalValue = (2 ** doubleBets) * (3 ** tripleBets) * (this.ticketState.round.value * this.ticketState.quantity);
    this.interactionService.setTicketPanelState(this.ticketState);
  }

  verifyBet() {
    let prize = this.translate.instant('pg-home.prize');
    this.prize = prize[0] + prize.substring(1).toLowerCase();
    this.CreationDate = this.datepipe.transform(this.ticketState.date, 'short');
    this.showShields = false;

    this.posteCoupon = true;
    switch (this.appComponent.activeLang) {
      case 'pt':
        this.roundName = this.ticketState.round.name;
        break;
      case 'en':
        this.roundName = this.ticketState.round.nameEng;
        break;
      case 'it':
        this.roundName = this.ticketState.round.nameItaly;
        break;
      case 'es':
        this.roundName = this.ticketState.round.nameSpan;
        break;
      default:
        break;
    }

    let contentModal: string = "";
    if (this.playerName == "" && this.appComponent.userAdmin == undefined) {
      contentModal = this.translate.instant("pg-home.must-be-logged");
      this.posteCoupon = false;
      this.modalLogin.show();
      this.htmlToAdd = contentModal;
      return
    }
    if (this.playerName == "")
      this.playerName = this.appComponent.userAdmin.login;

    this.ticketState.playerName = this.playerName;
    this.interactionService.setTicketPanelState(this.ticketState);
    if (this.appComponent.userAdmin == null) { // check if user is logged in
      contentModal = this.translate.instant("pg-home.must-be-logged");
      this.posteCoupon = false;
    }
    else if (this.appComponent.userAdmin.jurisdictionId < 6 && this.appComponent.userAdmin.jurisdictionI > 1) { // allow only clubs and players to create bets
      contentModal = this.translate.instant("pg-home.must-be-player");
      this.posteCoupon = false;
    }
    else {
      this.ticketState.playerName = this.playerName;
      // this.appComponent.checkAPIVisitorCredit();
      this.ticketState.userAdmin = this.appComponent.userAdmin;
      this.interactionService.setTicketPanelState(this.ticketState);
      this.isChecked = true;
      this.ticketState.matches.forEach(element => {
        if (element.status == 0) element.matchResult = "X";
        if (element.matchResult == "") {
          this.isChecked = false;
        }
      });

      if (!this.isChecked) { // verify whether all results are choosen
        this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"),
        `<h5 class='text-center w-100 mb-2 h-4'>${this.translate.instant("pg-home.select-all-bets").split(".")[0]}</h5>` +
        `<div class='w-100 d-flex align-items-center justify-content-center'>
            <p class='text-center w-75 mb-3'>${this.translate.instant("pg-home.select-all-bets").split(".")[1]}.</p>
        </div>`, true);
        return
      }
      else {
        let results: string = "<table id='sagartest' border='0' width='100%'>";
        results += `<div> ${this.translate.instant('pg-ticket-panel.selections done by you')}: </div>`;
        this.ticketState.matches.forEach((element: any) => {
          if (this.round.rankRound == 1 && this.round.singleMatchRound == 0 && this.round.jiuJitsuRound == 0) {
            results += TableChoicesMadeService.GenerateConfirmTableRankMatch(element.homeName, `${element.matchResult}°`);
          }
          else {
            var mresult;
            if (this.round.rankRound == 1 && this.round.singleMatchRound == 1 && this.round.jiuJitsuRound == 1) {
              switch (element.type) {
                case 2:
                  results += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant(element.name), element.matchResult, element.odd, this.translate.instant("Over"), this.translate.instant("Under"));
                  break;
                case 3:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Yes"), this.translate.instant("No"));
                  break;
                case 1:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, "1", "2");
                  break;
                case 0:
                  results += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant(element.name), element.matchResult);
                  break;
                case 4:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Even"), this.translate.instant("Odd"));
                  break;
                case 5:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Home"), this.translate.instant("Away"));
                  break;
                case 6:
                  results += TableChoicesMadeService.GenarateConfirmTable3ChoicesDynamic(this.translate.instant(element.name), element.matchResult, this.translate.instant("Home"), this.translate.instant("Draw"), this.translate.instant("Away"));
                  break;
              }
            }
            else {
              mresult = element.matchResult
              if (element.status == 1) {
                results += TableChoicesMadeService.GenarateConfirmTable3Choices(`${element.homeName} - ${element.awayName}`, mresult.toLowerCase());
              } else {
                results += "<tr><td><s>" + element.homeName + "</s> - <s>" + element.awayName + "</s></td><td> " + this.translate.instant("pg-tickets.cancelled") + "</td></tr>";
              }
            }

          }
        });
        results += "</table>";
        contentModal = "<p>" + results + "</p>";
      }
    }
    this.htmlToAdd = contentModal;
    this.modalCreateTicket.show();
  }

  Confirm() {
    this.modalCreateTicket.hide();
    this.postTicket();
  }

  postTicket() {
    this.processingBet = true;
    this.configService.postTicket(this.ticketState, this.round.rankRound, this.round.name, this.round.betradarSport.name)
      .subscribe(data => {
        this.ticketState.uuid8 = data.betId;
        this.interactionService.setTicketPanelState(this.ticketState);
        this.modalCreateTicket.hide();
        this.modalFinishTicket.show();
        this.processingBet = false;
      }, error => {
        if (error.status == 400) {
          if (error.error == "Without Credit.") {
            this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.no-enough-credit"), true);
          } else {
            this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
          }
        } else {
          this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
        }
        console.log(error);
        this.ngOnInit();
        this.processingBet = false;
      });
  }

  updateUserCredit(userId: number, newUserCredit: number) {
    let transactionType = 3;
    this.configService.updateUserCredit(userId, this.ticketState.finalValue, transactionType, 0, this.ticketState.uuid8)
      .subscribe(data => {
      }, error => {
        console.log(error);
        //this.appComponent.msgStandard(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), 4);
      });
  }

  splitFighterName(fightName: string, result: number) {
    if (fightName.includes('X')) {
      fightName = fightName.replace('X', 'x')
    }
    if (result == 1) {
      return fightName.split('x')[0].trim();
    }
    if (result == 2) {
      return fightName.split('x')[1].trim();
    }
  }

  closeFinishModal() {
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();
    this.ngOnInit();
    this.clearBet();
    this.modalFinishTicket.hide();
  }

  closeWhatsapp() {
    this.modalWhatsapp.hide();
    this.clearBet();
  }

  setMenuFeedback() {
    if (document.getElementsByClassName('button-header').length > 0)
      document.getElementsByClassName('button-header')[0].classList.remove('button-header');
    document.getElementById('headerTicket').classList.add('button-header');
  }

  showModalWhatsapp() {
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();
    this.modalWhatsapp.show();
  }

  whatsappTicket(cel: string) {
    cel = cel.trim();

    this.configService.saveWhatsappNumber(this.ticketState.playerName, cel, this.ticketState.uuid8)
      .subscribe(data => {
        //...
      }, error => {
        console.log(error);
      });

    let whatsappMsg = this.translate.instant("pg-print.ticket") + " " + this.ticketState.uuid8.slice(0, 8) + ": "
      + this.configService.Domain + "/print/" + this.ticketState.uuid8 + "/" + this.ticketState.userAdmin.login + "/"
      + this.ticketState.round.id;

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + whatsappMsg);
    this.modalWhatsapp.hide();
    this.clearBet();
  }

  printTicket() {
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();
    window.open('/print/' + this.ticketState.uuid8 + "/" + this.ticketState.userAdmin.login + "/" + this.round.id, "", "width=700,height=700");
    this.clearBet();
  }

  removeResult(i, result) {
    if (this.round.rankRound)
      this.rankArray[parseInt(this.ticketState.matches[i].matchResult)] = 0;
    this.ticketState.matches[i].matchResult = this.ticketState.matches[i].matchResult.replace(result, '');
    let checkExistingResult = false;
    this.ticketState.matches.forEach(element => {
      if (element.matchResult != "") {
        checkExistingResult = true;
      }
    });
    if (!checkExistingResult) {
      this.clearBet();
    }
    this.interactionService.setTicketPanelState(this.ticketState);
    this.interactionService.setMatchRank(this.rankArray);
  }

  clearBet() {
    localStorage.setItem("reset", "true");
    this.ticketState = null;
    this.playerName = "";
    this.interactionService.setTicketPanelState(this.ticketState);
    this.rankArray = [0];
    this.interactionService.clearMatchRank(this.rankArray);

    let tempRound = this.round;
    this.posteCoupon = false;
    this.configService.getRoundById(tempRound.id)
      .subscribe(data => {
        this.interactionService.setHomeVisibleRound(data);
        this.interactionService.setHomeVisibleRound(tempRound);
      });
  }

  RedirectToPayment() {
    this.clearBet();
    this.router.navigate(['/payment']);
  }

  setShields(name: string) {
    let names1 = this.roundName.split(" X ");
    this.names[0] = this.getMatchShield(names1[0]);
    this.names[1] = this.getMatchShield(names1[1]);
  }

  getMatchShield(name: string) {
    let searchText = this.remove_accents(name);
    for (var i = 0; i < searchText.length; i++) {
      if (searchText.charAt(i) == " ") searchText = searchText.replace(" ", "%20")
    }
    searchText = searchText.toLocaleLowerCase()
    let ret = 'https://poolbet365.com/assets/shields/' + searchText + '.png';
    if (name.toLocaleUpperCase().includes(("POKER").toLocaleUpperCase())) {
      if (name.toLocaleUpperCase().includes(("Israelita").toLocaleUpperCase())) {
        return ret = '../../../assets/image/ChampionShipFlags/estrDavy.png'
      }
      return ret = '../../../assets/image/ChampionShipFlags/poker.png'
    }
    if (name.toLocaleUpperCase().includes(("LA LIGA").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/laliga.png'
    if (name.toLocaleUpperCase().includes(("campeonato BRASILEIRO").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/brasileirao.png'
    if (name.toLocaleUpperCase().includes("MOTO GP")) return ret = '../../../assets/image/ChampionShipFlags/motogp.png'
    if (name.toLocaleUpperCase().includes("FÓRMULA 1")) return ret = '../../../assets/image/ChampionShipFlags/f1.png'
    if (name.toLocaleUpperCase().includes(("Bundesliga").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/bl.png'
    if (name.toLocaleUpperCase().includes(("campeonato italiano").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/it.png'
    if (name.toLocaleUpperCase().includes(("campeonato frances").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/fr.png'
    if (name.toLocaleUpperCase().includes(("premier league").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/pl.png'
    if (name.toLocaleUpperCase().includes(("uefa").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/uefa.png'
    if (name.toLocaleUpperCase().includes(("Internacionais").toLocaleUpperCase())) return ret = '../../../assets/image/ChampionShipFlags/international.png'
    return ret;
  }

  remove_accents(str) {
    let accented = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    let withoutAccent = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    let namewithoutAccent = "";
    let j = str.length;
    for (let i = 0; i < j; i++) {
      let troca = false;
      for (let a = 0; a < accented.length; a++) {
        if (str.substr(i, 1) == accented.substr(a, 1)) {
          namewithoutAccent += withoutAccent.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        namewithoutAccent += str.substr(i, 1);
      }
    }
    return namewithoutAccent;
  }

  isDisabled() {
    let count = this.ticketState.matches.filter(match => {
      return match.matchResult != '';
    }).length;

    if (count == this.totalMatchs) {
      return false;
    }
    return true;
  }

  setTotalMatchs() {
    this.totalMatchs = this.ticketState.matches.length;
    this.ticketState.matches.forEach(x => {
      if (x.status == 0 && this.totalMatchs != 0) this.totalMatchs--;
    });
  }

  sendBetConfirmedSignal() {
    this.interactionService.sendBetConfirmedSignal();
  }

}
