import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { Router, ActivatedRoute } from '@angular/router';
//import { HomeComponent } from '../home/home.component';
import { AppComponent } from '../app.component';
import { Bet } from '../bet.interface';
import { User } from '../user.interface';
import { ConfigService } from '../config.service';
import { Game } from '../game.interface';
import { Round } from '../round.interface';
import { Currency } from '../currency.interface';
import { Prize } from '../prize.interface';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from 'src/app/interaction.service';
import { find } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Ticket } from 'src/app/ticket.interface';
import { coin } from 'src/app/coin.interface';
import { ModalsService } from '../services/modals/modals.service';

interface EventsType {
  homeTeam: string,
  awayTeam: string,
  dateTime: string,
  result: string
}

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  @ViewChild('frame4', { static: true }) modalPrint: ModalDirective;
  @ViewChild('frame2', { static: true }) modalFinishTicket: ModalDirective;
  @ViewChild('frame3', { static: true }) modalWhatsapp: ModalDirective;

  playerName: string = "";
  count: number = 0;
  rankArray: number[] = [0];
  ticketState = {} as Ticket;
  isPrinting = true;
  isLoadded = false;
  htmlToAdd: string = "";
  betId: string;
  operator: string;
  roundId: number;
  bet: Bet;
  user: User;
  round: Round;
  games: any[] = [];
  Currency: Currency;
  prizes: Prize[] = [];
  qrValues: string = "";
  roundName: string;
  info: any = [];

  displayDateTime;
  displayPrize;
  displayJackpot;
  displayMultiples;
  displayEvents: any[] = [];

  constructor(private http: HttpClient, private interactionService: InteractionService, private configService: ConfigService, private router: Router,
    private appComponent: AppComponent, private translate: TranslateService, private modalsService: ModalsService,
    private activatedRoute: ActivatedRoute) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    this.betId = this.activatedRoute.snapshot.paramMap.get("betId");
    this.operator = this.activatedRoute.snapshot.paramMap.get("operator");
    this.roundId = parseInt(this.activatedRoute.snapshot.paramMap.get("roundId"));

    this.getPrizeList();

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data != null) {
          this.Currency = data;
        }
      });

  }

  // getPrize() {
  //   this.configService.getPrize()
  //     .subscribe(data => {
  //       this.prizes = data;
  //       this.getBetToPrint();
  //     }, error => {
  //       console.log(error);
  //     });
  // }

  getPrizeList() {
    this.configService.getPrize().subscribe((data: Prize[]) => {
      data.forEach(element => {
        this.prizes.push({
          id: element.id,
          gathered: element.gathered,
          paidOn: element.paidOn,
          roundId: element.roundId,
          type: element.type
        })
      })
    });
    this.getBetToPrint();
  }

  getBetToPrint() {
    this.configService.getBetByUuid(this.betId)
      .subscribe(data => {
        this.bet = data;
        this.getGames(data);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-print.print-fail"), this.translate.instant("pg-print.print-fail-info"), true);
        console.log(error);
        window.close();
      });
  }

  getGames(bet) {
    this.configService.getGames(this.roundId)
      .subscribe(data => {
        this.games = data;
        this.getRound(bet);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-print.print-fail"), this.translate.instant("pg-print.print-fail-info"), true);
        console.log(error);
        window.close();
      });
  }

  getRound(bet) {
    this.configService.getRoundById(this.roundId)
      .subscribe(data => {
        this.round = data;
        //console.log(this.round);
        switch (this.appComponent.activeLang) {
          case 'pt':
            this.roundName = this.round.name;
            break;
          case 'en':
            this.roundName = this.round.nameEng;
            break;
          case 'it':
            this.roundName = this.round.nameItaly;
            break;
          case 'es':
            this.roundName = this.round.nameSpan;
            break;
          default:
            break;
        }
        this.info.push({
          id: this.round.id,
          startDateTime: this.round.startDateTime,
          endDateTime: this.round.endDateTime,
          name: this.roundName,
          number: this.round.number
        });
        this.configService.GetCurrencyUser(bet.userAdminId)
          .subscribe(data => {
            this.Currency = data;
            //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
            this.http.get('https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId)
              .subscribe((response: JSON) => {
                var Eco = JSON.stringify(response)
                Eco = Eco.replace("[", "").replace("]", "")
                let obj: coin = JSON.parse(Eco)
                let price = parseFloat(obj.ask)
                if (data.currencyId == "BRL") {
                  this.Currency.quote = 1;
                  this.Currency.coin = 'BRL_' + data.currencyId;
                  this.Currency.currencySymbol = data.currencySymbol;
                  this.Currency.currency = data.currencyId;
                  this.interactionService.setGlobalCountry(this.Currency);
                }
                else {
                  this.Currency.quote = price;
                  this.Currency.coin = 'BRL_' + data.currencyId;
                  this.Currency.currencySymbol = data.currencySymbol;
                  this.Currency.currency = data.currencyId;
                  this.interactionService.setGlobalCountry(this.Currency);
                }
                return;
              }, error => {
                console.log(error);
                this.Currency.coin = 'BRL_BRL';
                this.Currency.quote = 1;
                this.Currency.currencySymbol = 'R$';
                this.Currency.country = 'Brazil';
                this.Currency.currency = 'BRL';
                this.interactionService.setGlobalCountry(this.Currency);
              });
          }, err => {
            console.log(err);
          });

        this.openModal();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-print.print-fail"), this.translate.instant("pg-print.print-fail-info"), true);
        console.log(error);
        window.close();
      });
  }

  setRoundPrizes(round) {

    if (this.prizes.length === 0) return [0, 0, 0, 0];

    var jackpot = (this.prizes.find(x => x.roundId == round.id && x.type == 2).gathered);
    var prizeAmount = (this.prizes.find(x => x.roundId == round.id && x.type == 1).gathered);
    var fullPrize = (+(jackpot) + +(prizeAmount));
    var roundValue = round.value;

    return [jackpot, prizeAmount, fullPrize, roundValue];
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  newGenerateTicket() {
    let showDateHour = new Date(this.bet.betDate);

    let rankRound = this.round.rankRound == 1 && this.round.jiuJitsuRound == 0 ? true : false;
    let championship = this.round.rankRound == 0 && this.round.jiuJitsuRound == 0 && this.round.singleMatchRound == 0 ? true : false;
    let dynamicStyle = this.round.singleMatchRound == 1 && this.round.rankRound == 1 && this.round.jiuJitsuRound == 1 ? true : false;

    let msgResult = "<table width='100%' border='0'>";
    let result = this.bet.results;

    var array = result.split("|");
    let round_prizes = this.setRoundPrizes(this.round);
    const currencySymbol = (this.Currency ? this.Currency.currencySymbol : 'R$');
    // check final ticket's value
    let doubleBets = 0;
    let tripleBets = 0;
    let totalValue = this.bet.value;
    let betResult = "";

    if (!rankRound) {
      array.forEach(element => {
        if (element.length == 2) {
          doubleBets += 1;
        }
        if (element.length == 3) {
          tripleBets += 1;
        }
      });
      totalValue = ((2 ** doubleBets) * (3 ** tripleBets) * (this.round.value * this.bet.quantity)) * this.Currency.quote;
      if (this.games.length - doubleBets - tripleBets == 1) betResult += this.games.length - doubleBets - tripleBets + " " + this.translate.instant("pg-print.simple") + " ";
      else betResult += this.games.length - doubleBets - tripleBets + " " + this.translate.instant("pg-print.simple") + "s ";

      if (doubleBets == 1) betResult += doubleBets + " " + this.translate.instant("pg-print.double") + " ";
      else betResult += doubleBets + " " + this.translate.instant("pg-print.double") + "s ";

      if (tripleBets == 1) betResult += tripleBets + " " + this.translate.instant("pg-print.triple");
      else betResult += tripleBets + " " + this.translate.instant("pg-print.triple") + "s";

      this.displayMultiples = this.translate.instant("pg-print.simple") + " " + (this.games.length - doubleBets - tripleBets) + " " + this.translate.instant("pg-print.double") + " " + doubleBets + " " + this.translate.instant("pg-print.triple") + " " + tripleBets;
    }


    //QR value
    this.qrValues += "ID: " + this.betId + ", " + showDateHour + ", " +
      this.translate.instant("pg-print.operator") + ": " + this.operator + ", " +
      this.translate.instant("pg-print.round-id") + ": " + this.round.uuid4 + ", " +
      //this.translate.instant("pg-print.round") + ": " + this.round.name + ", " +
      this.translate.instant("pg-print.round") + ": " + this.roundName + ", " +
      this.translate.instant("pg-home.round-value") + ": " + currencySymbol + this.round.value.toFixed(2) + ", [";
    for (let i = 0; i < this.games.length; i++) {
      this.bet.results.replace("|", "");
      //msgResult += this.games[i].homeName + " - " + this.games[i].awayName + ": <b>" + this.bet.results.substring((i + 1), (i + 2)) +"</b><br/>";

      if(dynamicStyle){
        msgResult += `<tr> <td><b> ${this.games[i].name}</b></td><td style='text-align: right'>${ array[i].split("").join(" - ") }</td></tr>`;

        this.displayEvents.push({
          homeTeam: this.games[i].name,
          awayTeam: "",
          result: array[i],
          dateTime: ""
        });
      }

      if(championship){
        msgResult += "<tr><td><b>" + this.games[i].homeName + " - " + this.games[i].awayName + "</b></td><td style='text-align: right'>" + array[i].split("").join(" - ") + "</td></tr>";
          // NORMAL ROUND: defining events to be displayed
          this.displayEvents.push({
            homeTeam: this.games[i].homeName,
            awayTeam: this.games[i].awayName,
            result: array[i],
            dateTime: this.getCurrentDateTime(this.games[i].dateTime).toString()
          });
      }

      if(rankRound){

        msgResult += "<tr><td><b>" + array[i] + "</b></td><td style='text-align: right'>" + (i + 1) + "</td></tr>";
        // RANK ROUND: defining events to be displayed
        this.displayEvents.push({
          homeTeam: array[i],
          awayTeam: "",
          result: (i + 1).toString() + "°",
          dateTime: this.getCurrentDateTime(this.games[i].dateTime).toString()
          //dateTime: this.games[i].dateTime
        });
      }
      this.qrValues += this.games[i].homeName + " - " + this.games[i].awayName + ": " + array[i] + ", ";

    }
    this.qrValues += "]";
    //console.log(result);
    msgResult += "</table>";
    this.htmlToAdd += "<p class='print-betid'>ID: " + this.betId + "</p>" +
      showDateHour.getDate() + '/' + (showDateHour.getMonth() + 1) + '/' + showDateHour.getFullYear() + '<br/>' +
      this.addZero(showDateHour.getHours()) + ':' + this.addZero(showDateHour.getMinutes()) + '<br/><hr/>' +
      this.translate.instant("pg-print.operator") + ": <b>" + this.operator + "</b><br/>" +
      this.translate.instant("pg-home.player-name") + ": <b>" + this.bet.playerName + "</b><br/>" +
      this.translate.instant("pg-print.round-id") + ": <b>" + this.round.uuid4 + "</b><br/>" +
      //this.translate.instant("pg-print.round") + ": <b>" + this.round.name + "</b><br/>" +
      this.translate.instant("pg-print.round") + ": <b>" + this.roundName + "</b><br/>" +
      this.translate.instant("pg-home.round-value") + ": <b>" + currencySymbol + (this.Currency.quote * +this.round.value).toFixed(2) + "</b><br/>" +
      "<p class='print-betid'>" + this.translate.instant("round-header.jackpot") + ": <b>" + currencySymbol + (this.Currency.quote * round_prizes[0]).toFixed(2) + "</b></p>" +
      this.translate.instant("round-header.prize") + ": <b>" + currencySymbol + (this.Currency.quote * round_prizes[1]).toFixed(2) + "</b><hr/>" +
      // Coupon, ticket
      "<table width='100%' border='0'><tr><td>" +
      this.translate.instant("pg-home.quantity-tickets") + ":</td><td class='print-betid'>" + this.bet.quantity.toFixed(0) + "</td></tr><tr><td>" +
      (betResult == "" ? "" : (betResult + "</td><td></td></tr><tr><td>")) +
      this.translate.instant("pg-home.total-value") + "</td><td class='print-betid'>" + currencySymbol + totalValue.toFixed(2) + "</td></tr></table><hr/>" +
      msgResult +
      "<hr/>" + this.translate.instant("pg-print.final-warnning");

    // set values
    this.displayDateTime = showDateHour;
    this.displayPrize = round_prizes[1];
    this.displayJackpot = round_prizes[0];

  }

  roundingFunction(value) {
    //console.log("v: " + value, "q: " + this.Currency.quote, "c: " + Math.round(value * this.Currency.quote));
    return Math.round(value * this.Currency.quote);
  }

  getCurrentDateTime(dateToConvert: Date) {
    return new Date(dateToConvert).getTime() - (parseInt(localStorage.getItem('minute'), 10) * 60000);
  }

  /*generateTicket() {
    let showDateHour = new Date(this.bet.betDate);
    let msgResult = "<table width='100%' border='0'>";
    let result = this.bet.results;

    var array = result.split("|");
    let round_prizes = this.setRoundPrizes(this.round);
    const currencySymbol = (this.Currency ? this.Currency.currencySymbol : 'R$');
    // check final ticket's value
    let doubleBets = 0;
    let tripleBets = 0;
    let totalValue = this.bet.value;
    let betResult = "";
    if (this.round.rankRound == 0) {
      array.forEach(element => {
        if (element.length == 2) {
          doubleBets += 1;
        } else if (element.length == 3) {
          tripleBets += 1;
        }
      });
      totalValue = ((2 ** doubleBets) * (3 ** tripleBets) * (this.round.value * this.bet.quantity)) * this.Currency.quote;
      if (this.games.length - doubleBets - tripleBets == 1) betResult += this.games.length - doubleBets - tripleBets + " " + this.translate.instant("pg-print.simple") + " ";
      else betResult += this.games.length - doubleBets - tripleBets + " " + this.translate.instant("pg-print.simple") + "s ";

      if (doubleBets == 1) betResult += doubleBets + " " + this.translate.instant("pg-print.double") + " ";
      else betResult += doubleBets + " " + this.translate.instant("pg-print.double") + "s ";

      if (tripleBets == 1) betResult += tripleBets + " " + this.translate.instant("pg-print.triple");
      else betResult += tripleBets + " " + this.translate.instant("pg-print.triple") + "s";
    }


    //QR value
    this.qrValues += "ID: " + this.betId + ", " + showDateHour + ", " +
      this.translate.instant("pg-print.operator") + ": " + this.operator + ", " +
      this.translate.instant("pg-print.round-id") + ": " + this.round.uuid4 + ", " +
      //this.translate.instant("pg-print.round") + ": " + this.round.name + ", " +
      this.translate.instant("pg-print.round") + ": " + this.roundName + ", " +
      this.translate.instant("pg-home.round-value") + ": " + currencySymbol + this.round.value.toFixed(2) + ", [";
    for (let i = 0; i < this.games.length; i++) {
      this.bet.results.replace("|", "");
      //msgResult += this.games[i].homeName + " - " + this.games[i].awayName + ": <b>" + this.bet.results.substring((i + 1), (i + 2)) +"</b><br/>";
      if (this.round.rankRound == 0) {
        if (this.round.singleMatchRound == 1) {

          msgResult += "<tr><td><b> " + this.translate.instant('Final Result') + "</b></td><td style='text-align: right'>" + array[0].split("").join(" - ") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('More Ball Possession') + "</b></td><td style='text-align: right'>" + array[1].split("").join(" - ") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Total Goals') + "</b></td><td style='text-align: right'>" + (array[2] == "12" ? "Over - Under" : array[2] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Total Corners') + "</b></td><td style='text-align: right'>" + (array[3] == "12" ? "Over - Under" : array[3] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Yellow Cards') + "</b></td><td style='text-align: right'>" + (array[4] == "12" ? "Over - Under" : array[4] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Corners Home Team') + "</b></td><td style='text-align: right'>" + (array[5] == "12" ? "Over - Under" : array[5] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Corners Away Team') + "</b></td><td style='text-align: right'>" + (array[6] == "12" ? "Over - Under" : array[6] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Total Faults') + "</b></td><td style='text-align: right'>" + (array[7] == "12" ? "Over - Under" : array[7] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Shoots on Target') + "</b></td><td style='text-align: right'>" + (array[8] == "12" ? "Over - Under" : array[8] == "1" ? "Over" : "Under") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Will be Penalty') + "</b></td><td style='text-align: right'>" + (array[9] == "12" ? "Yes - No" : array[9] == "1" ? "Yes" : "No") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Goal in the 1st Half') + "</b></td><td stylie='text-align: right'>" + (array[10] == "12" ? "Yes - No" : array[10] == "1" ? "Yes" : "No") + "</td></tr>";

          msgResult += "<tr><td><b>" + this.translate.instant('Goal in the 2nd Half') + "</b></td><td stylie='text-align: right'>" + (array[11] == "12" ? "Yes - No" : array[11] == "1" ? "Yes" : "No") + "</td></tr>";
          msgResult += "<tr><td><b>" + this.translate.instant('GG (Both Score)') + "</b></td><td stylie='text-align: right'>" + (array[12] == "12" ? "Yes - No" : array[12] == "1" ? "Yes" : "No") + "</td></tr>";
          msgResult += "<tr><td><b>" + this.translate.instant('Red Card') + "</b></td><td stylie='text-align: right'>" + (array[13] == "12" ? "Yes - No" : array[13] == "1" ? "Yes" : "No") + "</td></tr>";
        } else {
          msgResult += "<tr><td><b>" + this.games[i].homeName + " - " + this.games[i].awayName + "</b></td><td style='text-align: right'>" + array[i].split("").join(" - ") + "</td></tr>";
        }
      } else {
        msgResult += "<tr><td><b>" + array[i] + "</b></td><td style='text-align: right'>" + (i + 1) + "</td></tr>";
      } this.qrValues += this.games[i].homeName + " - " + this.games[i].awayName + ": " + array[i] + ", ";
    }
    this.qrValues += "]";
    //console.log(result);
    msgResult += "</table>";
    this.htmlToAdd += "<p class='print-betid'>ID: " + this.betId + "</p>" +
      showDateHour.getDate() + '/' + (showDateHour.getMonth() + 1) + '/' + showDateHour.getFullYear() + '<br/>' +
      this.addZero(showDateHour.getHours()) + ':' + this.addZero(showDateHour.getMinutes()) + '<br/><hr/>' +
      this.translate.instant("pg-print.operator") + ": <b>" + this.operator + "</b><br/>" +
      this.translate.instant("pg-home.player-name") + ": <b>" + this.bet.playerName + "</b><br/>" +
      this.translate.instant("pg-print.round-id") + ": <b>" + this.round.uuid4 + "</b><br/>" +
      //this.translate.instant("pg-print.round") + ": <b>" + this.round.name + "</b><br/>" +
      this.translate.instant("pg-print.round") + ": <b>" + this.roundName + "</b><br/>" +
      this.translate.instant("pg-home.round-value") + ": <b>" + currencySymbol + (this.Currency.quote * +this.round.value).toFixed(2) + "</b><br/>" +
      "<p class='print-betid'>" + this.translate.instant("round-header.jackpot") + ": <b>" + currencySymbol + (this.Currency.quote * round_prizes[0]).toFixed(2) + "</b></p>" +
      this.translate.instant("round-header.prize") + ": <b>" + currencySymbol + (this.Currency.quote * round_prizes[1]).toFixed(2) + "</b><hr/>" +
      // Coupon, ticket
      "<table width='100%' border='0'><tr><td>" +
      this.translate.instant("pg-home.quantity-tickets") + ":</td><td class='print-betid'>" + this.bet.quantity.toFixed(0) + "</td></tr><tr><td>" +
      (betResult == "" ? "" : (betResult + "</td><td></td></tr><tr><td>")) +
      this.translate.instant("pg-home.total-value") + "</td><td class='print-betid'>" + currencySymbol + totalValue.toFixed(2) + "</td></tr></table><hr/>" +
      msgResult +
      "<hr/>" + this.translate.instant("pg-print.final-warnning");

  }*/

  openModal() {
    this.newGenerateTicket();
    this.modalPrint.show();
    //this.print();
  }


  print() {
    window.print();
  }

  print2(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>PoolBet365</title>
          <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">
          <style>
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
              font-size: 13px;
            }
            table {
              border-width: thin;
            }
            b {
                font-weight: 600;
            }
            .print-betid {
                margin: 0;
                float: right;
            }
            .bet-qrcode {
                display: flex;
                justify-content: center;
            }
            .bet-qrcode img{
                max-width: 150px;
            }
            /*@page {
              size: auto;
              margin: 10;
            }
            @media print {
              html, body {
                width: 210mm;
                height: 297mm;
              }*/
          </style>
        </head>
        <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  urlBuilder() {
    var urlAlvo = "https://www.poolbet365.com/print/"
    //console.log(urlAlvo + this.betId + "/" + this.operator + "/" + this.roundId)
    return urlAlvo + this.betId + "/" + this.operator + "/" + this.roundId;
  }

  closePrintModal() {
    window.close();
  }

  /* Whatssap Modal init */

  closeWhatsapp() {
    this.modalWhatsapp.hide();
    this.modalPrint.show();
    this.clearBet();
  }

  showModalWhatsapp() {
    this.modalPrint.hide();
    // this.modalFinishTicket.hide();
    this.modalWhatsapp.show();
  }

  whatsappTicket(cel: string) {
    cel = cel.trim();

    this.configService.saveWhatsappNumber(this.operator, cel, this.bet.uuid8)
      .subscribe(data => {
        //...
      }, error => {
        console.log(error);
      });

    let whatsappMsg = this.translate.instant("pg-print.whatsapp-mensage") + " : "
      + "https://poolbet365.com/print/" + this.bet.uuid8 + "/" + this.operator + "/"
      + this.bet.roundId;

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + whatsappMsg);
    this.modalWhatsapp.hide();
    this.modalPrint.show();
    this.clearBet();

  }

  clearBet() {
    localStorage.setItem("reset", "true");
    this.ticketState = null;
    this.playerName = "";
    this.interactionService.setTicketPanelState(this.ticketState);
    this.rankArray = [0];
    this.count = 0;
    this.interactionService.clearMatchRank(this.rankArray);
  }

  /* Whatssap Modal finish */

}


