import { Injectable, ɵCodegenComponentFactoryResolver, EventEmitter, forwardRef, OnInit } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { formatCurrency } from '@angular/common';
import { Observable } from 'rxjs';
import { User, UserLoginForm } from './user.interface';
import { Round } from './round.interface';
import { Flags, LiveMatchModel, RoundGroup, StreamGroup, StreamResult } from './round-group.interface';
import { Game } from './game.interface';
import { Prize } from './prize.interface';
import { Jackpot } from './jackpot.interface';
import { Bet } from './bet.interface';
import { Jurisdiction } from './jurisdiction.interface';
import { Contact } from './contact.interface';
import { Report } from './report.interface';
import { ReportFilter } from './report-filter.interface';
import { Transactionsfilter } from './transactions-filter.interface';
import { Transaction, LoginHistory } from './transaction.interface';
import { Banner } from './banner.interface';
import { Slides } from './slide.interface';
import { InteractionService } from './interaction.service';
import { JurisdictionCoupon } from './jurisdiction-coupon.interface';
import { Ticket } from './ticket.interface';
import { DocumentDto, DocumentResponseDto } from './document.interface';
import { Withdraw } from './withdraw.interface';
import axios from "axios";
import { BetradarData } from './betradar-data.interface';
import { BetradarSport } from './betradar-sport.interface';
import { Currency } from './currency.interface';
import { coin } from 'src/app/coin.interface';
import { SignalrService } from './signalr.service';
import { RecoveryPasswordRequestModel } from './requestModels/RecoveryPasswordRequestModel';
import { IGetMyBetsResponse } from "./Interfaces/ManageTicket/GetMyBetsResponse.interface"
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements OnInit {
  apiUrl = this.signalRService.apiUrl;
  creatorId: number;
  recvSelLan: EventEmitter<string>;
  Currency: Currency = <Currency>{};

  MainClubId: number = 8; // Site owner club jurisdictionId
  Domain: string = "https://poolbet365.com";

  constructor(private http: HttpClient, private interactionService: InteractionService, @Inject(LOCALE_ID) public locale: string, private signalRService: SignalrService) {
    this.recvSelLan = new EventEmitter<string>();

    var request = new XMLHttpRequest();
    request.open('GET', '/assets/skin-config.json', false);
    request.send();

    if (request.status == 200) {
      var response = JSON.parse(request.responseText);
      this.interactionService.setCreatorId(response.hubID);
      this.interactionService.setSkinConfig(response);
    }


    this.creatorId = this.interactionService.creatorId.getValue();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
        else {
          this.setGlobalCurrency();
        }
      });
  }
  ngOnInit(): void {}


  getLanguageId(activeLang: string) {
    switch (activeLang) {
      case "en":
        return 1;

      case "es":
        return 2;

      case "it":
        return 3;

      case "pt":
        return 4;

      case "bul":
        return 5;

      case "fre":
        return 6;

      case "rus":
        return 7;

      case "hi":
        return 8;

      case "chi":
        return 9;

      case "ron":
        return 10;

      case "gre":
        return 11;

      case "ger":
        return 12;

      case "tur":
        return 13;

      default:
        return 1;
    }
  }

  raiseSelLanEvent(lan: string): void {
    this.recvSelLan.emit(lan);
  }

  getLogin(log: string, pass: string, apiVisitor?: boolean): Observable<any> {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    const loginObj = { login: log, password: pass, apiVisitor: apiVisitor, partnerId: this.creatorId };

    return this.http.post(this.apiUrl + '/users/login', loginObj, { headers: header });

  }

  checkLogged(key: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.post(this.apiUrl + '/common/LoginDencryption/?key=' + key, { headers: header });
  }

  getLoginEncryption(username: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.get(this.apiUrl + '/common/LoginEncryption/?login=' + username, { headers: header });
  }

  listUsers() {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User[]>(`${this.apiUrl}/users/`, { headers: header });
  }

  listCommonUsers() {
    return this.http.get<User[]>(`${this.apiUrl}/common/CommonGetUsers/`)
  }

  getUser(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User>(`${this.apiUrl}/users/` + userId, { headers: header });
    //return this.http.get<User>(`${this.apiUrl}/users/` + userId);
  }

  // getVisitorCredit(type: number, userId: number, credit: number) { // ESTAVA VINCULADO AO LOGIN,  NAO FUNCIONAVA (NÃO SEI PRA QUE SERVE {DIEGO})
  //   const header = new HttpHeaders({
  //     'Content-type': 'application/json',
  //     'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
  //   });

  //   return this.http.post<any>(`${this.apiUrl}/common/APIUserInfo/?type=` + type + "&userId=" + userId + "&cred=" + credit, { headers: header });
  // }

  //New methods added
  postLoginHistory(loginHistory: any, username: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let jsonToPass = {
      "Status": loginHistory.status,
      "UserId": loginHistory.userId,
      "IpAddress": loginHistory.ipAddress,
    }

    return this.http.post<LoginHistory>(this.apiUrl + '/LoginHistory/' + username, jsonToPass, { headers: header });
  }

  getIPAddress() {
    //local test
    return axios.get('https://api.ipify.org/?format=json');
  }

  getCountry(ipaddress: any) {
    //local test
    return axios.get('https://api.ipfind.com?ip=' + ipaddress + '&auth=7e2a21d0-0e27-44b6-aa06-8a0d015e266c');
  }

  getTimeZone(ipAddress: string) {
    return this.http.get<any>(`https://ipapi.co/${ipAddress}/json`);
  }

  getBasicUser(userId: number) {
    return this.http.get<User>(`${this.apiUrl}/common/GetBasicUser/?userId=` + userId);
  }

  getUserTree(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User>(`${this.apiUrl}/common/GetUserTree?userId=` + userId, { headers: header });
  }

  getUsersTreeList(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      //'Accept'       : 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User[]>(`${this.apiUrl}/common/GetUsersTreeList?userId=` + userId, { headers: header });
  }

  GetUsersList(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.get<User[]>(`${this.apiUrl}/common/GetUsersList?userId=` + userId, { headers: header });
  }

  GetJurisdictionLevelByUser(userId: number, jurisditicionLevel: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User[]>(`${this.apiUrl}/common/GetJurisdictionLevelByUser?userId=` + userId + '&jurisdictionLevel=' + jurisditicionLevel, { headers: header });
  }

  getJurisdictionsById(jurisdictionId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<Jurisdiction[]>(`${this.apiUrl}/common/GetJurisdictionsById?jurisdictionId=` + jurisdictionId, { headers: header });
  }

  getJurisdictionsType(jurisdictionId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<User[]>(`${this.apiUrl}/common/GetJurisdictions?jurisdictionId=` + jurisdictionId, { headers: header });
  }

  updateUserCredit(fromUserId: number, credit: number, transactionType: number, toUserId: number = 0, uuid8: string = null) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    //console.log(`${this.interactionService.loginData.value.token}`);

    return this.http.put<any>(`${this.apiUrl}/common/UpdateCreditTransfer/?fromUserId=` + fromUserId + '&toUserId=' + toUserId + '&credit=' + credit + '&transactionType=' + transactionType + '&bet_uuid8=' + uuid8, {}, { headers: header });
  }

  addUserCredit(fromUserId: number, credit: Number, transactionType: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    //console.log(`${this.interactionService.loginData.value.token}`);

    return this.http.put<any>(`${this.apiUrl}/common/AddUserCredit/?fromUserId=` + fromUserId + '&credit=' + credit + '&transactionType=' + transactionType, { headers: header });
  }

  updateUser(userId: number, user: User) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    /*
    let jsonToPass = {
      "Id": user.id,
      "Name": user.name,
      "LastName": user.lastName,
      "Email": user.email,
      "Login": user.login,
      "Password": user.password,
      "Deleted": user.deleted,
      "Commission": user.commission,
      "ParentId": user.parentId,
      "JurisdictionId": user.jurisdictionId,
      "Country": user.country,
      "City": user.city,
      "Credit": user.credit
    }*/

    return this.http.put<User>(this.apiUrl + '/users/' + userId, user, { headers: header });
  }

  getJackpot() {
    return this.http.get<Jackpot[]>(`${this.apiUrl}/jackpot/`);
  }

  deleteJackpotRemaining(roundId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<Jackpot>(`${this.apiUrl}/jackpot/` + roundId, { headers: header });
  }

  // getPrize() {
  //   return this.signalRService.getAsyncPrizeList();
  // }

  getPrize() {
    return this.http.get<Prize[]>(`${this.apiUrl}/prizes/`);
  }

  updateQuote(quote: string, coin: string, currencySymbol: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.put<any>(`${this.apiUrl}/common/UpdateQuote/?quote=` + quote + "&coin=" + coin + "&currencySymbol=" + currencySymbol, { headers: header });


  }

  getUpdateQuote(country: string) {
    return this.http.get<any>(`${this.apiUrl}/common/GetUpdateQuote/?country=` + country);
  }

  getUpdateQuoteBRL(country: string) {
    return this.http.get<any>(`${this.apiUrl}/common/getUpdateQuoteBRL/?country=` + country);
  }

  GetQuote(country: string) {
    return this.http.get<any>(`${this.apiUrl}/common/GetQuote/?country=` + country);
  }

  GetQuoteBRL(country: string) {
    return this.http.get<any>(`${this.apiUrl}/common/GetQuoteBRL/?country=` + country);
  }

  GetCountryCurrency(name: string) {

    return this.http.get<any>(`${this.apiUrl}/common/GetCountryCurrency/?name=` + name);

  }

  AddCountry(currencyId: string, name: string, currencySymbol: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<any>(`${this.apiUrl}/common/AddCountry/?currencyId=` + currencyId + "&name=" + name + "&currencySymbol=" + currencySymbol, { headers: header });


  }

  getMyBets(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<IGetMyBetsResponse[]>(`${this.apiUrl}/users/mybets?userId=${userId}`, { headers: header })
  }

  GetCountry() {
    return this.http.get<any>(`${this.apiUrl}/common/GetCountry/`);
  }

  getCountries() {
    return this.http.get<any[]>(`${this.apiUrl}/country`);
  }

  GetCurrencyUser(Id: number) {
    return this.http.get<any>(`${this.apiUrl}/common/GetCurrencyUser/?Id=` + Id);
  }

  getRoundJackpot(roundId: number) {
    return this.http.get<Prize>(`${this.apiUrl}/common/GetRoundJackpot/?roundId=` + roundId);
  }

  updateRoundJackpot(newJackpot: Prize) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<Prize[]>(this.apiUrl + '/common/UpdateRoundJackpot/?roundId=' + newJackpot.roundId, newJackpot, { headers: header });
  }

  updateJackpot(newJackpot: Prize, ticketTotalAmount: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.put<Prize>(this.apiUrl + '/prizes/' + newJackpot.id + "?ticketTotalAmount=" + ticketTotalAmount, newJackpot, { headers: header });
  }

  updateApportionment(newApportionment: Prize, ticketTotalAmount: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.put<Prize>(this.apiUrl + '/prizes/' + newApportionment.id + "?ticketTotalAmount=" + ticketTotalAmount, newApportionment, { headers: header });
  }

  getRounds() {
    return this.http.get<Round[]>(`${this.apiUrl}/rounds/`);
  }

  getSelectedRounds(IdUser: number) {
    return this.http.get<Round[]>(`${this.apiUrl}/rounds/GetSelecteRounds/?idUser=` + IdUser);
  }

  // getDescActivatedRounds() {
  //   return this.http.get<any[]>(`${this.apiUrl}/common/GetDescActivatedRounds`);
  // }

  getRoundsWithSportsName() {
    return this.http.get<Round[]>(`${this.apiUrl}/rounds/getRoundsWithSportsName`);
  }

  getRodadinhas() {
    return this.http.get<any[]>(`${this.apiUrl}/bets/testev2`);
  }

  getActivatedRoundGroups() {
    return this.http.get<RoundGroup[]>(`${this.apiUrl}/roundgroups/`);
  }

  getCompleteActivatedRounds(): Observable<any> {
    return this.http.get(`${this.apiUrl}/common/GetCompleteActivatedRounds`);
  }

  // getStreamingData() {
  //   return this.http.get<StreamGroup[]>(`${this.apiUrl}/Common/GetLiveStreamingData/`);
  // }

  getFlags() {
    return this.http.get<Flags>(`https://countriesnow.space/api/v0.1/countries/flag/images`);
  }

  getLiveMatches(sportsId: number, spId: number) {
    return this.http.get<LiveMatchModel[]>(`${this.apiUrl}/Common/GetLiveMatches/?sportsId=` + sportsId + '&spId=' + spId);
  }

  getMatchLink(streamCode: string, userId: number) {
    return this.http.get<StreamResult>(`${this.apiUrl}/Common/GetStreamingLink/?streamCode=` + streamCode + `&userId=` + userId);
  }

  getAllRoundGroups() {
    //return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetAllRoundGroups/`);
    return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetRoundGroupTreeList/`);
    //return this.http.get<RoundGroup[]>(`${this.apiUrl}/roundgroups/`);
  }

  addRoundGroup(newRoundGroup: string, jurisdictionFather: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<RoundGroup>(this.apiUrl + '/common/AddRoundGroup/?newRoundGroup=' + newRoundGroup + "&jurisdictionFather=" + jurisdictionFather, {}, { headers: header });
  }

  editRoundGroup(currentGroup: RoundGroup) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<RoundGroup>(`${this.apiUrl}/roundgroups/` + currentGroup.id, currentGroup, { headers: header });
  }

  deleteRoundGroup(groupId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.delete<RoundGroup>(`${this.apiUrl}/roundgroups/` + groupId, { headers: header });
  }

  getListedRoundGroups() {
    return this.http.get<RoundGroup[]>(`${this.apiUrl}/common/GetListedAllRoundGroups/`);
  }

  getRoundGroupRound() {
    return this.http.get<any[]>(`${this.apiUrl}/common/GetRoundGroupRound/`);
  }

  getRoundById(id: number) {
    return this.http.get<Round>(`${this.apiUrl}/common/GetRoundById?id=` + id);
  }

  getLastRound() {
    return this.http.get<Round>(`${this.apiUrl}/common/getlastround`);
  }

  getLastActivatedRound() {
    return this.http.get<Round>(`${this.apiUrl}/common/getlastactivatedround/${this.creatorId}`);
  }

  // getNextActivatedRound() {
  //   return this.http.get<Round>(`${this.apiUrl}/common/GetNextActivatedRound`);
  // }

  GetTicketsByRoundOrLastActiveRound(roundId: number = 0): Observable<any> {
    return this.http.get(`${this.apiUrl}/bets/GetTicketsByRoundOrLastActiveRound?roundId=${roundId}&creatorId=${this.creatorId}`);
  }

  getRound(roundId: number) {
    return this.http.get<Round>(`${this.apiUrl}/rounds/` + roundId);
  }


  getRoundsFinished() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundFinished`);
  }

  // test 2 result
  getChampionshipsRounds() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipsRounds`);
  }

  getRoundsOpended() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundOpened`);
  }

  getRoundsPosted() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetRoundPosted`);
  }

  getChampionshipRounds() {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipRounds`);
  }

  getChampionshipByRound(roundId: number) {
    return this.http.get<Round[]>(`${this.apiUrl}/common/GetChampionshipByRound/?roundId=` + roundId);
  }

  createRound(groupParent: number, roundName: string, rnEng: string, rnItaly: string, rnSpan: string, newJackpot: number, roundValue: number, parentId: number/*,two_options:number*/, sportId: number, rankRound: number, newGames: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    var postedResul = this.http.post<string>(`${this.apiUrl}/common/PostNewRound/?groupParent=` + groupParent +
      // tslint:disable-next-line:max-line-length
      '&roundName=' + roundName + '&roundNameEng=' + rnEng + '&roundNameItaly=' + rnItaly + '&roundNameSpan=' + rnSpan + '&newJackpot=' + newJackpot + '&roundValue=' + roundValue + '&parentId=' + parentId + /*'&two_options=' + two_options + */ '&rankRound=' + rankRound + '&sportId=' + sportId,
      newGames, { headers: header });

    //console.log(postedResul);

    return postedResul;
  }

  postResult(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/PostResult/`, games, { headers: header });
  }

  getBet(betId: string) {
    return this.http.get<Bet>(`${this.apiUrl}/bets/` + betId);
  }

  getBetById(betId: number) {
    return this.http.get<Bet>(`${this.apiUrl}/bets/GetBetById/${betId}`);
  }

  getBetByUuid(betId) {
    return this.http.get<any>(`${this.apiUrl}/bets/GetBetByUuid/${betId}`);
  }

  getBets(roundId: number) {
    return this.http.get<Bet[]>(`${this.apiUrl}/common/GetActivatedBetsByRound/?roundId=` + roundId);
  }

  getBetsByUserTree(userAdminId: number, roundId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByUserTree/?userAdminId=` + userAdminId + "&roundId=" + roundId, { headers: header });
  }

  // GetRoundListByUserById(userId: number) {
  //   const header = new HttpHeaders({
  //     'Content-type': 'application/json',
  //     'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
  //   });
  //   return this.http.get<any>(`${this.apiUrl}/common/GetRoundAndBetListByUserId/?UserId=` + userId, { headers: header });
  // }

  GetListRoundsWinners() {
    return this.http.get<any>(`${this.apiUrl}/common/GetListRoundsWinners`);
  }

  getRoundWinnersAndActivateRounds() {
    return this.http.get<any>(`${this.apiUrl}/common/GetRoundWinnersAndActivateRounds/${this.creatorId}`);
  }

  getAllBets(roundId: number) {
    return this.http.get<Bet[]>(`${this.apiUrl}/common/GetBetsByRound/?roundId=` + roundId);
  }

  getLastUserBet(userId: number) {
    return this.http.get<Bet>(`${this.apiUrl}/common/GetLastUserBet/?userId=` + userId);
  }

  postBet(bet: any) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let jsonToPass = {
      "betDate": new Date(),
      "resultBetId": 1,
      "playerId": 1,
      "playerName": bet.playerName,
      "roundId": bet.roundId,
      "userAdminId": bet.userId,
      "results": bet.resultToPass,
      "status": 1,
      "value": bet.value
    }

    return this.http.post<any>(this.apiUrl + '/bets/', jsonToPass, { headers: header });
  }

  postTicket(bet: Ticket, rankRound: number, roundName: string, sportName: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let results: string = "";
    bet.matches.forEach(element => {
      if (bet.round.rankRound == 1 && bet.round.singleMatchRound == 0 && bet.round.jiuJitsuRound == 0)
        results += element.homeName + "|";
      else
        results += element.matchResult + "|";
    });

    results = results.substring(0, results.length - 1);

    var tzoffset = (new Date()).getTimezoneOffset() * 60000;
    var localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);

    let jsonToPass = {
      "betDate": localISOTime,
      "resultBetId": 1,
      "playerId": 1,
      "playerName": bet.playerName,
      "roundId": bet.round.id,
      "userAdminId": bet.userAdmin.id,
      "results": results,
      "status": 1,
      "value": bet.finalValue,
      "Quantity": bet.quantity,
      "betradarSportName": sportName,
      "roundName": roundName,
      "minute": parseInt(localStorage.getItem("minute"))
    }
    return this.http.post<any>(this.apiUrl + '/bets/', jsonToPass, { headers: header });
  }

  // updateBet(bet: Bet) {
  //   const header = new HttpHeaders({
  //     'Content-type': 'application/json'
  //   });

  //   let jsonToPass = {
  //     "id": bet.id,
  //     "betDate": bet.betDate,
  //     "resultBetId": bet.resultBetId,
  //     "playerId": bet.playerId,
  //     "playerName": bet.playerName,
  //     "roundId": bet.roundId,
  //     "userAdminId": bet.userAdminId,
  //     "results": bet.results,
  //     "value": bet.value
  //   }

  //   return this.http.put<Bet>(`${this.apiUrl}/bets/` + bet.id, bet, { headers: header });
  // }

  getGames(roundId: number) {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetMatchesByRound/?roundId=` + roundId);
  }

  getJiuJitsuGames(roundId: number) {
    return this.http.get<any>(`${this.apiUrl}/common/GetJiuJitsuMatchesByRound/?roundId=` + roundId);
  }

  getEvents(roundId: number) {
    return this.http.get<any[]>(`${this.apiUrl}/Rounds/GetGames/${roundId}`);
  }

  getAllGames() {
    return this.http.get<Game[]>(`${this.apiUrl}/common/GetAllMatches`);
  }

  getPostedRoundInfo(roundId: number) {
    return this.http.get<any>(`${this.apiUrl}/rounds/getRoundInfos/` + roundId);
  }

  createGame(newGame: Game) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    let jsonToPass = {
      "betradarMatchId": newGame.betradarMatchId,
      "HomeName": newGame.homeName,
      //"AwayTeamId" : newGame.awayTeamId,
      "AwayName": newGame.awayName,
      "RoundId": newGame.roundId,
      "BetradarMatchId": 0,
      "AwayTeamScore": 0,
      "DateTime": newGame.dateTime
    };

    return this.http.post<Game>(this.apiUrl + '/matches/', jsonToPass, { headers: header });
  }

  /*
  updateMatches(games: Array<Game>){
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });
    return this.http.put<Game[]>(`${this.apiUrl}/matches/UpdateMatches/`, games, { headers: header });
  }
  */

  listRoundWinners(roundId: number) {
    return this.http.get<any[]>(`${this.apiUrl}/common/GetWinnersByRound/?roundId=` + roundId);
  }

  addJurisdiction(newJurisdiction: Jurisdiction) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.post<Jurisdiction>(this.apiUrl + '/jurisdictions/', newJurisdiction, { headers: header });
  }

  addNewUser(user: UserLoginForm) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Jurisdiction>(`${this.apiUrl}/users/`, user, { headers: header });
  }

  createUser(user: User, jurisdictionCoupon: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });
    console.log(jurisdictionCoupon)
    return this.http.post<User>(`${this.apiUrl}/users/?jurisdictionCoupon=` + jurisdictionCoupon, user, { headers: header });
  }

  sendPasswordToEmail(name: string, login: string, email: string, password: string) {
    return this.http.get(`${this.apiUrl}/common/SendPasswordMail/?name=` + name + "&login=" + login + "&email=" + email + "&password=" + password);
  }

  sendRecoveryPassword(recoveryDTO: RecoveryPasswordRequestModel) {
    return this.http.post<any>(`${this.apiUrl}/Users/recoveryPassword`, recoveryDTO);
  }

  removeUser(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.delete<User>(`${this.apiUrl}/users/` + userId, { headers: header });
  }

  blockUser(playerId: number) {
    return this.http.get<any>(`${this.apiUrl}/PlayerAction/blockUser?playerId=` + playerId);
  }

  saveWhatsappNumber(playerName: string, phoneNumber: string, betUuid8: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    let whatsappLogs = {
      "PlayerName": playerName,
      "PhoneNumber": phoneNumber,
      "BetUuid8": betUuid8
    }

    //return this.http.post<any>(`${this.apiUrl}/WhatsappLogs/`, whatsappLogs, { headers: header });
    return this.http.post<any>(`${this.apiUrl}/common/SaveWhatsappNumber/`, whatsappLogs, { headers: header });
  }

  sendContactMessage(contact: any) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    //onsole.log("nome: " + contact.name + " - email: " + contact.email + " - assunto: " + contact.subject + " - msg: " + contact.message);

    return this.http.get(`${this.apiUrl}/common/SendContactMessage/?name=` + contact.name +
      "&email=" + contact.email + "&whatsapp=" + contact.whatsapp + "&subject=" + contact.subject +
      "&device=" + contact.device + "&message=" + contact.message, { headers: header });
  }

  sendWorkMessage(contact: any) {
    const header = new HttpHeaders({
      'Content-type': 'application/json'
    });

    return this.http.post<any>(`${this.apiUrl}/common/SendWorkMessage/`, contact, { headers: header });
  }

  getUnreadMessages() {
    return this.http.get<Contact[]>(`${this.apiUrl}/contacts/`);
  }

  getReport(filter: ReportFilter) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Report[]>(`${this.apiUrl}/common/GetReport/`, filter, { headers: header });
  }

  getTransactionsFilter(filter: Transactionsfilter) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Transaction[]>(`${this.apiUrl}/common/GetTransactionsFilter/`, filter, { headers: header });
  }

  getTransactionsFilterWithStatus(userId: number, activeLang: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<any>(`${this.apiUrl}/common/GetTransactionsWithStatus?userid=${userId}&activelang=${activeLang}`, { headers: header });
  }

  postBanner(banner: FormData, userId: number, title: string, subtitle: string) {
    const header = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/SendSlider/?userId=` + userId +
      "&title=" + title + "&subtitle=" + subtitle, banner, { headers: header });
  }

  postDocument(docInfo: FormData, userId: number, title: number) {
    const header = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/SendDocument/?userId=` + userId +
      "&title=" + title, docInfo, { headers: header });
  }

  getDocument(userId: number): Observable<DocumentResponseDto[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.get<DocumentResponseDto[]>(`${this.apiUrl}/common/GetDocument?userId=` + userId, { headers: header });
  }

  GetDocumentPending(): Observable<DocumentResponseDto[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.get<DocumentResponseDto[]>(`${this.apiUrl}/common/GetDocumentPending?status=0`, { headers: header });
  }

  GetWithdrawPending(): Observable<Withdraw[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.get<Withdraw[]>(`${this.apiUrl}/common/GetWithdrawPending?status=0`, { headers: header });
  }

  getDocumentCount(userId: number): Observable<number> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<number>(`${this.apiUrl}/common/getDocumentCount?userId=` + userId, { headers: header });
  }

  getWithdraw(userId: number): Observable<Withdraw[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<Withdraw[]>(`${this.apiUrl}/common/GetWithdraw?userId=` + userId, { headers: header });
  }

  updateDocument(userId: number, docId: number, status: number, description: string): Observable<DocumentResponseDto[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<DocumentResponseDto[]>(`${this.apiUrl}/common/updateDocument?userId=` + userId + `&docId=` + docId + `&status=` + status + `&description=` + description, {}, { headers: header });
  }

  UpdateUserBankInfo(userId: number, bank: string, agency: string, account: string, accountType: number, cellphone: string, email: string, creditRequest?: number): Observable<User> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.put<User>(`${this.apiUrl}/common/UpdateUserBankInfo?userId=` + userId + `&bank=` + bank + `&agency=` + agency + `&account=` + account + `&accountType=` + accountType + `&cellphone=` + cellphone + `&email=` + email + `&creditRequest=` + creditRequest, {}, { headers: header });
  }

  postWithdraw(withdrawRequest: any): Observable<string> {
    const header = new HttpHeaders({
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/withdraws`, withdrawRequest, { headers: header });
  }

  updateWithdraw(userId: number, widthdrawId: number, status: number, description: string): Observable<Withdraw[]> {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });
    return this.http.put<Withdraw[]>(`${this.apiUrl}/common/updateWithdraw?userId=` + userId + `&widthdrawId=` + widthdrawId + `&status=` + status + `&description=` + description, {}, { headers: header });
  }

  /*
  getBanners(){
    return this.http.get<any[]>(`${this.apiUrl}/banners/`);
  }
  */
  getMasterParent(userId: number) {
    return this.http.get<User>(`${this.apiUrl}/common/GetMasterParent/?userId=` + userId);
  }

  getOwnBanners(userId: number) {

    return this.http.get<Banner[]>(`${this.apiUrl}/common/GetOwnSliders/?userId=` + userId);
  }

  getOwnSlides(userId: number, languageId: number, type: string) {
    return this.http.get<Slides[]>(`${this.apiUrl}/common/GetOwnSlides/?userId=` + this.creatorId + "&languageId=" + languageId + "&type=" + type);
  }

  saveBannerState(userId: number, banners: Banner[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/SaveSlidersState/?userId=` + userId, banners, { headers: header });
  }

  deleteBanner(bannerId: number, userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.delete(`${this.apiUrl}/banners/` + bannerId + '/?userId=' + userId, { headers: header });
  }

  getFeedMatches() {
    return this.http.get(`${this.apiUrl}/common/GetFeedMatches/`);
  }

  GetBetradarMatches(idCompetition: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get(`${this.apiUrl}/common/GetBetradarMatches/?idCompetition=` + idCompetition, { headers: header });
  }

  getBetradarSports() {
    return this.http.get<BetradarSport[]>(`${this.apiUrl}/BetradarSports/`);
  }

  getBetradarCategoriesBySportId(sportId: number) {
    return this.http.get(`${this.apiUrl}/BetradarSports/${sportId}/Categories`);
  }

  getBetradarTournamentsByCategoryId(categoryId: number) {
    return this.http.get(`${this.apiUrl}/BetradarCategories/${categoryId}/Tournaments`);
  }

  getBetradarTournaments() {
    return this.http.get(`${this.apiUrl}/BetradarTournaments/`);
    // return this.http.get('../../assets/data/betradar-list.json');
  }

  getBetradarDataByTournamentId(tournamentId: number) {
    return this.http.get(`${this.apiUrl}/Betradar/Tournament/${tournamentId}`);
  }

  getBetradarTeams(listBetradarData: BetradarData[]) {
    const ids = listBetradarData.map(data => {
      return `id=${data.teamHomeId}&id=${data.teamAwayId}`;
    }).join("&");
    return this.http.get(`${this.apiUrl}/BetradarTeams/GetBetradarTeams?${ids}`);
  }

  getBetRadarResults(games: Game[]) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<Game[]>(`${this.apiUrl}/common/GetBetradarResults/`, games, { headers: header });
  }

  getTransactionsByUserId(userId, activeLang) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<Transaction[]>(`${this.apiUrl}/common/GetTransactions/?userId=` + userId + "&activeLang=" + activeLang, { headers: header });
  }

  registerAPIUser(login, email, credit, parentId, visitorId, key) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${key}`
      //'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImFkbWluIiwiSnVyaXNkaWN0aW9uIjoiYWRtaW4iLCJuYmYiOjE1OTI5MjMwMDYsImV4cCI6MTU5MzUyNzgwNiwiaWF0IjoxNTkyOTIzMDA2fQ.gRdOAXV9BSv9owmD7Uz8O4E8damIo_RtdDR1-LQkRRs`
    });

    return this.http.post(`${this.apiUrl}/common/RegisterAPIUser/?login=` + login +
      '&email=' + email + '&credit=' + credit + '&parentId=' + parentId + '&visitorId=' + visitorId, {}, { headers: header });
  }

  requestCredit(userId: number, requestedCredit: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get(`${this.apiUrl}/common/RequestCredit/?userId=` + userId +
      "&requestedCredit=" + requestedCredit, { headers: header });
  }

  getCoupon(userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<JurisdictionCoupon>(`${this.apiUrl}/common/GetCouponByJurisdictionId/?userId=` + userId, { headers: header });
  }

  generateJurisdictionCoupon() {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<JurisdictionCoupon>(`${this.apiUrl}/common/GenerateJurisdictionCoupon/`, { headers: header });
  }

  saveJurisdictionCoupon(userId: number, coupon: string, availableValue: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    let jsonToPass: JurisdictionCoupon = {
      "id": 0,
      "code": coupon,
      "availableValue": availableValue,
      "jurisdictionId": userId
    };

    return this.http.post<JurisdictionCoupon>(`${this.apiUrl}/common/SaveJurisdictionCoupon/`, jsonToPass, { headers: header });
  }

  getCurrencyQuote() {
    return this.http.get('https://economia.awesomeapi.com.br/json/all');
  }

  createGerencianetTransaction(creditValue: number, userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/CreateGerencianetTransaction/?creditValue=` + creditValue + '&userId=' + userId, { headers: header });
  }

  generateLinkGerencianet(charge_id: number, defaultMessage: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/GenerateLinkGerencianet/?charge_id=` + charge_id + "&defaultMessage=" + defaultMessage, { headers: header });
  }

  checkoutPagseguro(creditValue: number, userId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/CheckoutPagseguro/?creditValue=` + creditValue + '&userId=' + userId, { headers: header });
  }

  linkPagseguro(checkoutCode: string) {
    /*const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/common/CheckoutPagseguro/?creditValue=` + creditValue + '&userId=' + userId, { headers: header} );
  */
  }

  createPixCob(userId: number, value: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/CreatePixCob/?userId=` + userId + '&value=' + value, {}, { headers: header });
  }

  getGeneralAPIPIXToken() {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<any>(`${this.apiUrl}/common/GetGeneralAPIPIXToken`, { headers: header });
  }

  createGeneralAPIPIXPayment(token: string, userId: number, amount: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post<any>(`${this.apiUrl}/common/CreateGeneralAPIPIXPayment/?token=` + token + "&userId=" + userId + "&amount=" + amount, {}, { headers: header });
  }

  updatePermissions(userid, jurisdiction) {
    return this.http.put<any>(`${this.apiUrl}/permissions/` + userid, jurisdiction);
  }

  updatePassword(id, Login, isPassword, password, oldPassword) {
    return this.http.put<any>(`${this.apiUrl}/users/` + id, { id, Login, isPassword, password, oldPassword });
  }

  setMenuFromDomain(clubId: number) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.get<any>(`${this.apiUrl}/Common/SetMenuFromDomain/?clubId=${clubId}`, { headers: header });
  }

  formatCurrencyValue(value: number) {
    return formatCurrency(parseFloat((Math.round(value * this.Currency.quote)).toFixed(2)), this.locale, this.Currency.currencySymbol, this.Currency.currency);
  }

  setGlobalCurrency() {

    this.GetCountryCurrency(localStorage.getItem('currentUserCountry'))
      .subscribe(data => {
        var DataURL: string = undefined;
        if (data.currencyId == "BRL") {
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        } else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        var DataURL: string = undefined;
        if (data.currencyId == "BRL") {
          DataURL = DataURL = 'https://economia.awesomeapi.com.br/json/USD-BRL';
        } else DataURL = 'https://economia.awesomeapi.com.br/json/BRL-' + data.currencyId;

        //this.http.get('https://free.currconv.com/api/v7/convert?q=BRL_' + data.currencyId + '&apiKey=085a5c4bbbd6e6750bfc')
        this.http.get(DataURL)
          .subscribe((obj: JSON) => {
            var Eco = JSON.stringify(obj)
            Eco = Eco.replace("[", "").replace("]", "")
            let response: coin = JSON.parse(Eco)
            var e = parseFloat(response.ask);
            if (response && data.currencyId != "BRL" /*&& response.data*/) {
              // var price = (response.results['BRL-' + data.currencyId].val);
              var price = parseFloat(response.ask);
              this.Currency.quote = price;
              this.Currency.coin = 'BRL_' + data.currencyId;
              this.Currency.currencySymbol = data.currencySymbol;
              this.Currency.currency = data.currencyId;
              this.interactionService.setGlobalCountry(this.Currency);
              return;
            } else {
              this.Currency.coin = 'BRL_BRL';
              this.Currency.quote = 1;
              this.Currency.currencySymbol = 'R$';
              this.Currency.country = 'Brazil';
              this.Currency.currency = 'BRL';
              this.interactionService.setGlobalCountry(this.Currency);
              return
            }
          }, error => {
            //console.log(error);
            this.Currency.coin = 'BRL_BRL';
            this.Currency.quote = 1;
            this.Currency.currencySymbol = 'R$';
            this.Currency.country = 'Brazil';
            this.Currency.currency = 'BRL';
            this.interactionService.setGlobalCountry(this.Currency);
          });
      });
  }

  openPokerSession(userName: string) {
    return this.http.get(`${this.apiUrl}/poker/open-session/${userName}`, { responseType: 'text' });
  }

  getRiseUpGameListByProvider(provider: string) {
    return this.http.get(`${this.apiUrl}/riseup/getGamesbyProvider?provider=${provider}`);
  }

  getRiseUpGameLink(userId: number, idGame: string, typeGame: string, inDevice: string, gameName: string, provider: string) {
    const header = new HttpHeaders({
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('currentUserToken')}`
    });

    return this.http.post(`${this.apiUrl}/riseup/GetGameLink?userId=` + userId + "&idGame=" + idGame + "&typeGame=" + typeGame + "&inDevice=" + inDevice + "&gameName=" + gameName + "&provider=" + provider, { header: header });
  }

  getPrizeAmountByRoundId(roundId) {
    return this.http.get<Prize>(`${this.apiUrl}/prizes/GetPrizeByRoundIdAsync/${roundId}`);
  }

  getCountBetsByRound(roundId) {
    return this.http.get<number>(`${this.apiUrl}/bets/GetCountBetsByRoundAsync/${roundId}`);
  }

  getSportbookUrl(userId: number) {
    return this.http.get<number>(`${this.apiUrl}/sportbook/getUrl?userId=${userId}`);
  }

  getSportsActive(creatorId: number) {
    return this.http.get<BetradarSport[]>(`${this.apiUrl}/BetradarSports/sportsactives/${this.creatorId}`);
  }
}
