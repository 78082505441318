<div class="container">
  <div class="row">
    <!-- Card -->
    <div class="card mb-5 w-100">
      <!-- Card content -->
      <div class="card-body bg-theme">

        <!-- BEGIN FIRST DEPOSIT SCREEN -->
        <ng-container *ngIf="TakeLocation();else CreditCard">
        <div class="row" *ngIf="screen == 1">
          <div class="col-12 pt-3">
            <h3>
               {{ 'menu.credit' | translate }}
            </h3>
            <hr />
          </div>

          <div class="infoPayment">
            <div class="sideLeftpayment">
              <div class="theme_greyDG mb-2 p-3">
                {{'pg-payment.msg1' | translate}}
              </div>
              <div class="theme_greyDG p-3">
                {{'pg-payment.msg2' | translate}}
              </div>
            </div>

            <div class="theme_greyDG sideRightpayment">
              <div class="mb-5">
                <span>{{'pg-payment.pay-mode' | translate}}:</span>
                <select name="pay-mode" id="pay-mode" class="infoPayment_select">
                  <option value="Pix">Pix</option>
                </select>
              </div>

              <div class="mb-5">
                <div class="d-flex justify-content-between align-items-center" style="height: 2vh;">
                  <label for="amount-value"> {{'round-header.value' | translate}}:</label>
                  <span style="font-size: .5rem;">{{ 'pg-payment.min-value' | translate }}: {{AdjustCurrency()}}</span>
                </div>
                <input id="amount-value" class="pl-2 infoPayment_input" autocomplete="off" placeholder="Digite o valor" type="number"/>
              </div>

              <button class="infoPayment_button" type="button" (click)="createGeneralAPIPIX()">
                {{ 'pg-payment.to-deposit' | translate }}
              </button>
            </div>
          </div>
        </div>
      </ng-container>
        <!-- END FIRST DEPOSIT SCREEN -->

        <!-- BEGIN SECOND DEPOSIT SCREEN -->
        <div class="row w-100" *ngIf="screen == 2">
          <div class="col-12 pt-3">
            <h3>
              <i class="fas fa-dollar-sign" style="font-size: 25px;"></i> {{ 'menu.credit' | translate }}
            </h3>
            <hr />
            <p>{{ 'pg-payment.transfer-data' | translate }}</p>
          </div>

          <div class="row w-100 pl-5">
            <div class="col-12 d-flex justify-content-center">
              <div class="sub-section">
                <div class="screen2-area">
                  <p>
                    {{ 'pg-payment.info-p1' | translate }}<br />
                    {{ 'pg-payment.info-p2' | translate }}
                  </p>
                  <p>
                    <img src="../../assets/image/little-green-ball.png" />
                    {{ 'pg-payment.read' | translate }}
                    <a routerLink="/global-terms">
                      {{ 'pg-global-terms.title' | translate }}
                    </a>
                  </p>
                  <hr />
                  <p class="text-left">{{ 'pg-payment.info-p3' | translate }}</p>
                  <p class="purchase-value">R$ {{credit}},00</p>
                  <p>
                    <ngx-qrcode [value]="qrCode" [width]="170"></ngx-qrcode>
                    <br />
                    {{ 'pg-pix.pix-code' | translate }}:<br />
                    <span class="qrcode-text" id="qrcode-text">{{qrCode}}</span>
                  </p>
                  <p>
                    <button mdbBtn class="form-sub2" type="button" color="success" mdbWavesEffect
                      (click)="copyMessage()">
                      {{ 'pg-payment.copy' | translate }} QR Code
                    </button>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <!-- END SECOND DEPOSIT SCREEN -->

      </div>
    </div>
    <!-- Card -->
  </div>
</div>

 <!-- OLD SYSTEM -->
 <ng-template #CreditCard>
<div class="container">
  <div class="row">
    <div class="card mb-5">
      <div class="card-body bg-theme">

        <div class="row">
          <div class="col-12 pt-3">
            <h3><i class="fas fa-dollar-sign" style="font-size: 25px;"></i> {{ 'pg-payment.credit-purchase' | translate
              }}
            </h3>
            <hr />
            <p>
              {{ 'pg-payment.credit-description' | translate }}
            </p>
          </div>

          <div class="row">

            <div class="col-12 d-flex justify-content-center">
              <div class="min-value"><span>{{ 'pg-payment.minimum' | translate }} R$ 5,00</span></div>
            </div>

            <div class="col-12 d-flex justify-content-center">
              <div class="field-area">
                <span>R$</span> <input id="credit" class="value-field" type="number" /><span>,00</span>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-center">
              <button mdbBtn class="pay-button" type="submit" color="success" mdbWavesEffect
                (click)="creditPagseguro()">
                {{ 'pg-payment.pay' | translate }}
              </button>
            </div>


          </div>

          <div class="col-12 text-center">

            <div class="row">
              <div class="col-12 sub-title">
                {{ 'pg-payment.pay-mode' | translate }}
              </div>
              <div class="col-12 sub-content">
                <div class="row">
                  <div class="col-md-3 col-sm-6">
                    <img src="../../assets/image/icon-pix.png" /><br />
                    <span>PIX</span>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <img src="../../assets/image/icon-bank-slip.png" /><br />
                    <span>{{ 'pg-payment.bank-slip' | translate }}</span>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <img src="../../assets/image/icon-credit-card.png" /><br />
                    <span>{{ 'pg-payment.credit-card' | translate }}</span>
                  </div>

                  <div class="col-md-3 col-sm-6">
                    <img src="../../assets/image/icon-transfer.png" /><br />
                    <span>{{ 'pg-payment.transfer' | translate }}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</div>
</ng-template>


<!--Modal PIX
<div mdbModal #framePix="mdbModal" class="modal fade" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false, ignoreBackdropClick: true}">
  <div class="modal-dialog modal-notify modal-info" role="document">
    <div class="modal-content bg-theme">
      <div class="modal-header">
        <p class="heading lead">PIX</p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="framePix.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <span style="color: white">{{ 'pg-pix.description' | translate }}</span>
          </div>
          <div class="col-12 text-center" style="line-height: 15px;">
            <ngx-qrcode [value]="qrCode" [width]="170"></ngx-qrcode>
            <br />
            {{ 'pg-pix.pix-code' | translate }}:<br />
            <span class="qrcode-text">{{qrCode}}</span>
          </div>
        </div>

      </div>

      <div class="modal-footer justify-content-center">
        <button mdbBtn style="background-color:#5394ff; color: white;" mdbWavesEffect (click)="framePix.hide()"
          data-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>
Modal PIX-->
