import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
import { Round } from '../round.interface';
import { Currency } from '../currency.interface';
import { Bet } from '../bet.interface';
import { User } from '../user.interface';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table'
import { InteractionService } from '../interaction.service';
import { Flags } from '../round-group.interface';
import { RoundService } from '../round.service';
import { ShieldHelper } from '../Helpers/ShieldHelper';
import { ModalsService } from '../services/modals/modals.service';
import { IActivatedTicketList } from '../Interfaces/ActivatedTicketList.interface'
@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  displayedColumns: string[] = ['id', 'login', 'country'];

  dataSource = new MatTableDataSource<Bet>();

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  currentRound: Round;
  allRounds: Round[] = [];
  roundName: string;
  dateHour: Date;
  totalBets: number;
  countryFlags: Flags;
  bets: Bet[];
  users: User[];
  userNameToShow = new Array<string>();
  countryToShow = new Array<string>();
  cityToShow = new Array<string>();
  isLoaded: boolean = false;
  searchReturn: boolean = false;
  resultSearch: string;
  info: any = [];
  Currency: Currency = <Currency>{};
  countDown: string;
  numberEvents: number;
  title: string = '';
  hidePageSize: boolean = true;
  roundStartDate: Date = new Date();

  constructor(private configService: ConfigService, private interactionService: InteractionService,
    private appComponent: AppComponent, private translate: TranslateService, private roundService: RoundService,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);

    this.configService.getFlags().subscribe(data => {
      this.countryFlags = data;
    });
  }

  ngOnInit() {
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    this.interactionService.activatedRound()
      .subscribe(res => {
        if (this.currentRound != res) {
          this.currentRound = res;
          this.getActivatedTicketList(res.id);
        }
      }, error => console.log(error))


    setInterval(() => {
      this.CountDown();
    }, 60000);
  }

  onPageChange() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }

  getActivatedTicketList(roundId: number = 0) {
    this.configService.GetTicketsByRoundOrLastActiveRound(roundId)
      .subscribe((data: IActivatedTicketList) => {
        this.currentRound = data.round
        this.dataSource.data = data.bets;
        this.setPagination();
        this.roundStartDate = data.round.startDateTime;
        this.CountDown();
        this.GenerateTitles();
        this.numberEvents = this.currentRound.matches ? this.currentRound.matches.length : this.currentRound.events.length;
      }, error => {
        console.log(error);
      });
  }

  setPagination() {
    this.dataSource.paginator = this.paginator;
  }

  getFlagByName(name: string) {
    name = name == "England" ? "United Kingdom" : name == "" ? "Brazil" : name;
    if (this.countryFlags.data.length > 0) {
      var result = this.countryFlags.data.filter(obj => {
        return obj.name === name;
      });
      if (result.length > 0) {
        return result[0].flag;
      }
      else {
        return "/assets/svgs/flag.svg";
      }
    }
  }


  detailCoupon(ticketCode) {
    this.modalsService.openDetailTickeModal(0, ticketCode.slice(0, 8));
  }

  CountDown() {
    let dateStart = new Date(this.roundStartDate);
    let atualDate = new Date();

    let dateDiff = dateStart.getTime() - atualDate.getTime();

    dateDiff = dateDiff / 1000;
    let seconds = Math.floor(dateDiff % 60);

    dateDiff = dateDiff / 60;
    let minutes = Math.floor(dateDiff % 60);

    dateDiff = dateDiff / 60;
    let hours = Math.floor(dateDiff % 24);

    let days = Math.floor(dateDiff / 24);
    if (days > 0) {
      this.countDown = days + "D " + hours + "H " + minutes + "MIN" + " ";
      return;
    }

    hours = hours + (days * 24);

    this.countDown = hours + "H " + minutes + "MIN" + " ";
  }



  GenerateTitles() {
    if (this.currentRound.singleMatchRound || this.currentRound.jiuJitsuRound) {
      var names = this.currentRound.name.split(" X ");
      this.title = `
        <img *ngIf="showShields" placement="auto" triggers="mouseenter:mouseleave" width="35px"
        class="mr-1" src="${ShieldHelper.getBasicShield(names[0])}" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
        ${this.currentRound.name}
        <img *ngIf="showShields" placement="auto" triggers="mouseenter:mouseleave" width="35px"
        class="ml-1" src="${ShieldHelper.getBasicShield(names[1])}" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
      `;
      return;
    }
    this.title = `
    <img *ngIf="showShields" placement="auto" triggers="mouseenter:mouseleave" width="35px"
    class="mr-1" src="${ShieldHelper.getChampionshipLogos(this.currentRound.name)}" onerror="onerror=null;src='https://poolbet365.com/assets/shields/default-logo.png'">
    ${this.currentRound.name}
    `;
  }

}
