import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { DocumentDto, DocumentResponseDto } from '../document.interface';
import { CookieService } from 'ngx-cookie-service';
import { MatTableDataSource } from '@angular/material/table';
import { ModalsService } from '../services/modals/modals.service';

@Component({
  selector: 'app-upload-doc',
  templateUrl: './upload-doc.component.html',
  styleUrls: ['./upload-doc.component.scss']
})
export class UploadDocComponent implements OnInit {
  imageShowFlag: boolean;
  imageSrc: string;

  ELEMENT_DATA: DocumentResponseDto[] = [];
  displayedColumns: string[] = [/*'id', */'createdDate', 'type', 'status', 'responseDate', 'description'];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);

  Files: any[] = [];
  FilesNames: string[] = ['', '', '', '', '', ''];
  FileStatus: number[] = [0, 0, 0, 0, 0, 0];

  isImage: boolean;
  type_val: string;
  type_identity: string;
  type_identity_back: string;
  type_address: string;
  type_selfie: string;
  type_paper: string;
  fileName: any;
  docList: DocumentResponseDto[];
  descList: string[] = [
    'upload-doc.doc_type_0',
    'upload-doc.doc_type_1',
    'upload-doc.doc_type_2',
    'upload-doc.doc_type_3',
    'upload-doc.doc_type_4',
    'upload-doc.doc_type_5',
  ];
  statusList: string[] = [
    'upload-doc.PENDING',
    'upload-doc.SUCCESS',
    'upload-doc.FAILED',
  ];

  docsTypes: any[] = [
    { name: 'upload-doc.doc_type_1', type: 1 },
    { name: 'upload-doc.doc_type_2', type: 2 },
    { name: 'upload-doc.doc_type_5', type: 5 }
  ];

  constructor(
    private configService: ConfigService,
    private appComponent: AppComponent,
    private translate: TranslateService,
    private cookieService: CookieService,
    private modalsService: ModalsService
  ) {
    this.imageSrc = '';
    this.imageShowFlag = false;
    this.isImage = false;
    this.docList = [];
    this.translate.setDefaultLang(this.appComponent.activeLang);
    this.type_val = this.type_identity;
    this.type_identity = '1';
    this.type_identity_back = '5'
    this.type_address = '2';
    this.type_selfie = '3';
    this.type_paper = '4';
  }

  ngOnInit() {
    this.getDocuments();
  }

  onFileSelected(event, type) {
    if (event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/png') {
      this.Files.push({
        file: event.target.files[0],
        type: type
      });
      this.isImage = true;
      this.FilesNames[type] = event.target.files[0].name;
      this.FileStatus[type] = 0;

      let name = document.querySelector(`#FileName${type}`);
      name.classList.remove('theme_rejected');
      // do something here
    } else {
      this.isImage = false;
      this.modalsService.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("upload-doc.alert"), true);
      // do some other thing here
    }
  }

  // async uploadFile(files): Promise<void> {
  //   let select_type = (<HTMLInputElement>document.getElementById('select_type')).value;
  //   if (files.length === 0 || this.isImage == false || select_type == '') {
  //     this.appComponent.msgStandard(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("upload-doc.fail-required-fields"), 4);
  //     return;
  //   }
  //   let fileToUpload = <File>files[0];
  //   const formData = new FormData();
  //   formData.append('file', fileToUpload, fileToUpload.name);

  //   this.configService.postDocument(formData, this.appComponent.userAdmin.id, Number.parseInt(select_type))
  //     .subscribe(data => {
  //       this.appComponent.msgStandard(this.translate.instant("pg-banners.well-done"), this.translate.instant("pg-banners.upload-successfull-info"), 3);
  //       this.ngOnInit();
  //       this.fileName = "";
  //     }, error => {
  //       this.appComponent.msgStandard(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("upload-doc.fail-general-info"), 4);
  //       console.log(error);
  //     });
  //   this.getDocuments();
  // }

  async uploadFile(): Promise<void> {
    if (this.Files.length === 0 || this.isImage == false) {
      this.modalsService.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("upload-doc.fail-general-info"), true);
      return;
    }
    let listType = [];
    this.Files.forEach(file => {
      if (file == null) {
        this.modalsService.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), "Unexpected Error", true);
        return;
      }
      let formData = new FormData();
      let fileToUpload = <File>file.file;
      formData.append('file', fileToUpload, fileToUpload.name);
      this.configService.postDocument(formData, this.appComponent.userAdmin.id, file.type)
        .subscribe(data => {

          let btn = document.querySelector(`#btnFile${file.type}`);
          btn.classList.add("dNone");

          let fileName = document.querySelector(`#FileName${file.type}`);
          fileName.classList.add("theme_wait");

          this.FilesNames[file.type] = "upload-doc.wating";

          file = null;
        }, error => {
          this.modalsService.openNotificationModal(this.translate.instant("pg-banners.operation-fail"), this.translate.instant("upload-doc.fail-general-info"), true);
          console.log(error);
        });
    })
  }

  async getDocuments(): Promise<void> {
    //this.docList = await this.configService.getDocument(Number (localStorage.getItem('currentUser'))).toPromise();
    this.ELEMENT_DATA = await this.configService.getDocument(Number(localStorage.getItem('currentUser'))).toPromise();
    this.ELEMENT_DATA.forEach(doc => {
      let btn = document.querySelector(`#btnFile${doc.type}`);
      btn.classList.add('dNone');

      //this.FilesNames[doc.type] = doc.fileName;

      let name = document.querySelector(`#FileName${doc.type}`);
      switch (doc.status) {
        case 0:
          name.classList.add('theme_wait');
          this.FilesNames[doc.type] = "upload-doc.waiting";
          break;

        case 1:
          name.classList.add('theme_approved');
          this.FilesNames[doc.type] = "upload-doc.approved";
          break;

        case 2:
          name.classList.add('theme_rejected');
          this.FilesNames[doc.type] = "upload-doc.rejected";
          this.FileStatus[doc.type] = 2;
          break;
      }
    });
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.removeUploaded();
  }

  removeUploaded() {
    this.dataSource.data.forEach(element => {
      let docToRemove = this.docsTypes.find(x => x.type == element.type);
      var index = this.docsTypes.indexOf(docToRemove);
      if (index !== -1) {
        this.docsTypes.splice(index, 1);
      }
    });
  }

  onImageOpen(fileName: string) {
    //let src = `../../assets/documents/${fileName}`;
    let src = `http://poolbet365.com/assets/documents/${fileName}`;
    this.imageSrc = src;
    this.imageShowFlag = true;
  }
  onImageClose() {
    this.imageShowFlag = false;
  }

}
