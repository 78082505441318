import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'angular-bootstrap-md';
import { TranslateService } from '@ngx-translate/core';
import { InteractionService } from '../../interaction.service';
import { Ticket } from '../../ticket.interface';
import { Round } from '../../round.interface';
import { AppComponent } from '../../app.component';
import { TicketPanelComponent } from '../../components/ticket-panel/ticket-panel.component';
import { ConfigService } from '../../config.service';
import { Prize } from '../../prize.interface';
import { DatePipe } from '@angular/common';

import {
  trigger,
  state,
  style,
  animate,
  transition
  // ...
} from '@angular/animations';
import { Currency } from '../../currency.interface';
import { Router } from '@angular/router';
import { debug } from 'console';
import { TableChoicesMadeService } from '../../services/table-choices-made/table-choices-made.service';
import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-cart-badge',
  templateUrl: './cart-badge.component.html',
  styleUrls: ['./cart-badge.component.scss'],
  providers: [DatePipe],
  animations: [
    trigger('ballMoving', [
      state('stoped', style({
        visibility: 'hidden',
        top: '{{topStart}}',
        right: '{{rightStart}}'
      }), { params: { topStart: '25px', rightStart: '15px' } }
      ),
      state('moving', style({
        visibility: 'visible',
        top: '25px',
        right: '15px'
      })),
      transition('stoped => moving', [
        animate('0.6s')
      ])
    ])
  ]
})
export class CartBadgeComponent implements OnInit {

  @ViewChild('frame', { static: true }) modalCreateTicket: ModalDirective;
  @ViewChild('frame2', { static: true }) modalFinishTicket: ModalDirective;
  @ViewChild('frame3', { static: true }) modalWhatsapp: ModalDirective;
  @ViewChild('frame4', { static: true }) modalCoupon: ModalDirective;
  @ViewChild('frame5', { static: true }) modalWithoutCredit: ModalDirective;

  htmlToAdd: string = "";

  ticketState;
  nameOfTheMatch: string;
  bollPossSelected: string;
  teamHome: string;
  teamAway: string;
  TGol: string;
  CornerAway: string;
  CornerHome: string;
  Shoot: string;
  Tfault: string
  TCorners: string;
  yCard: string;
  prizeRound: number;
  count: number = 0;
  total: number = 0;
  playerName: string = "";
  isChecked: boolean = false;
  round: Round;
  rankArray: number[] = [0];
  prizes: Prize[] = [];
  prizeAmount: any;
  jackpotAmount: any;
  betInprogres: number = 0;
  betEnd: boolean = true;
  islogIn: boolean = false;
  // animation's variables
  ballMoving = 'stoped';
  topStartPosition: string = '25px';
  rightSartPosition: string = '15px';
  noEnoughCredit: string[] = [];
  processingBet: boolean = false;
  Currency: Currency = <Currency>{};

  constructor(private interactionService: InteractionService,
    public appComponent: AppComponent, private configService: ConfigService,
    private translate: TranslateService, public datepipe: DatePipe, private router: Router,
    private modalsService: ModalsService) {
    this.updatePositions();
  }

  ngOnInit() {
    // if (this.prizes == null) this.getPrizeList();
    let playAnimation: boolean;
    this.interactionService.playBallAnimation
      .subscribe(data => {
        playAnimation = data;
      });

    this.interactionService.mTicketPanelState
      .subscribe(data => {
        if (!data) {
          this.count = 0;
          this.total = 0;
        }
        else {
          this.count = data.matches.filter(match => {
            return match.matchResult != '';
          }).length;
          this.total = data.matches.length;
          data.matches.forEach(x => {
            if (x.status == 0 && this.total != 0) this.total--;
          });
        }
        if (playAnimation) {
          setTimeout(() => {
            this.updatePositions();
          }, 600);
        }

        this.ticketState = data;
      });

    this.interactionService.homeVisibleRound
      .subscribe(round => {
        if (round != null) {
          this.round = round;
          this.setPrizes(round);
        }
      });
    this.interactionService.matchRankArray
      .subscribe(data => {
        if (data != null) {
          this.rankArray = data;
        }
      });

    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });

    let semCreditos = this.translate.instant("pg-home.no-enough-credit");
    this.noEnoughCredit = semCreditos.split('.');
  }

  getPrizeList() {
    this.configService.getPrize().subscribe((data: Prize[]) => {
      data.forEach(element => {
        this.prizes.push({
          id: element.id,
          gathered: element.gathered,
          paidOn: element.paidOn,
          roundId: element.roundId,
          type: element.type
        })
      })
    });
  }

  setPrizes(round: Round) {
    this.prizeAmount = round.prizes[1].gathered;
    this.jackpotAmount = round.prizes[0].gathered;
  }

  endRound(str) {
    //if (this.islogIn == null) {
    if (this.betEnd == true) {
      if (this.count == this.total) {
        // this.SetFinalResultName(this.ticketState);
        //this.openModal();
      }
    }
    //}
    //this.closeFinishModal();
    return str
  }

  isLoggedOn() {
    if (this.islogIn == true) {
      this.modalCoupon.show()
    }
  }

  splitResults(matchResult: string) {
    return matchResult.split("");
  }

  roundingFunction(value) {
    return Math.round(value * this.ticketState.currencyQuote);
  }

  updatePositions() {
    // set initial ball position from mouse click
    this.interactionService.clickY
      .subscribe(data => {
        this.topStartPosition = (data - 70) + 'px';
      });
    this.interactionService.clickX
      .subscribe(data => {
        let wide = document.documentElement.offsetWidth;
        this.rightSartPosition = (wide - data - 25) + 'px';
      });
    this.animatingBall();
  }

  animatingBall() {

    // change animation's state and then set it back to start position after a time
    this.ballMoving == 'stoped' ? this.ballMoving = 'moving' : this.ballMoving = 'stoped';
    setTimeout(() => {
      this.ballMoving = 'stoped';
      this.interactionService.setPlayBallAnimation(false);
    }, 600);
    if (this.betEnd != true) {
      this.betEnd = true
    }
  }

  onChangeQuantity(ticketquantity: number) {
    if (ticketquantity == 0) {
      ticketquantity = 1;
    }

    // update final ticket's value when change its quantity
    this.ticketState.quantity = ticketquantity;
    let doubleBets = 0;
    let tripleBets = 0;
    this.ticketState.singleBet = true;
    if (!this.round.rankRound) {
      this.ticketState.matches.forEach(element => {
        if (element.matchResult.length == 2) {
          doubleBets += 1;
          this.ticketState.singleBet = false;
        }
        else if (element.matchResult.length == 3) {
          tripleBets += 1;
          this.ticketState.singleBet = false;
        }
      });
    }
    this.ticketState.finalValue = (2 ** doubleBets) * (3 ** tripleBets) * (this.ticketState.round.value * this.ticketState.quantity);
    this.interactionService.setTicketPanelState(this.ticketState);
  }

  openModal() {
    if (this.appComponent.userAdmin == null) { // check if user is logged in
      this.isChecked = false;
      // this.SetFinalResultName(this.ticketState);
      this.htmlToAdd = this.translate.instant("pg-home.must-be-logged");
      this.modalCreateTicket.show();
    } else if (this.total !== this.count || this.count == 0) {
      this.isChecked = false;
      this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"),
      `<h5 class='text-center w-100 mb-2 h-4'>${this.translate.instant("pg-home.select-all-bets").split(".")[0]}</h5>` +
      `<div class='w-100 d-flex align-items-center justify-content-center'>
            <p class='text-center w-75 mb-3'>${this.translate.instant("pg-home.select-all-bets").split(".")[1]}.</p>
        </div>`, true);
      return
    }
    else {
      this.islogIn = true;
      this.modalCoupon.show();
    }
  }

  verifyBet() {
    this.modalCoupon.hide();
    if (this.playerName == "")
      this.playerName = this.appComponent.userAdmin.login;

    this.ticketState.playerName = this.playerName;
    this.interactionService.setTicketPanelState(this.ticketState);
    let contentModal: string = "";
    if (this.appComponent.userAdmin == null) { // check if user is logged in
      contentModal = this.translate.instant("pg-home.must-be-logged");
    }
    else {
      // API Visitor credit check and update
      // this.appComponent.checkAPIVisitorCredit();
      this.ticketState.userAdmin = this.appComponent.userAdmin;
      if (this.appComponent.userAdmin.jurisdictionId == 7) {
        this.ticketState.playerName = this.appComponent.userAdmin.login;
      }
      this.interactionService.setTicketPanelState(this.ticketState);
      this.isChecked = true;
      this.ticketState.matches.forEach(element => {
        if (element.status == 0) element.matchResult = "X";
        if (element.matchResult == "") {
          this.isChecked = false;
        }
      });

      if (!this.isChecked) { // verify whether all results are choosen
        contentModal = this.translate.instant("pg-home.required-results");
      }
      else {
        let results: string = "<table border='0' width='100%'>";
        this.ticketState.matches.forEach(element => {
          if (this.round.rankRound && this.round.singleMatchRound == 0 && this.round.jiuJitsuRound == 0) {
            var mresult;
            mresult = element.matchResult
            if (element.status == 1) {
              results += "<tr><td>" + element.homeName + " - " + element.awayName + "</td><td> " + mresult + "</td></tr>";
            } else {
              results += "<tr><td><s>" + element.homeName + "</s> - <s>" + element.awayName + "</s></td><td> " + this.translate.instant("pg-tickets.cancelled") + "</td></tr>";
            }
          }
          else {
            if (this.round.jiuJitsuRound == 1 && this.round.singleMatchRound == 1) {
              switch (element.type) {
                case 0:
                  results += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant(element.name), element.matchResult);
                  break;
                case 1:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, "1", "2");
                  break;
                case 2:
                  results += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant(element.name), element.matchResult, element.odd, this.translate.instant("Over"), this.translate.instant("Under"));
                  break;
                case 3:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Yes"), this.translate.instant("No"));
                  break;
                case 4:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Even"), this.translate.instant("Odd"));
                  break;
                case 5:
                  results += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(element.name), element.matchResult, this.translate.instant("Home"), this.translate.instant("Away"));
                  break;
                case 6:
                  results += TableChoicesMadeService.GenarateConfirmTable3ChoicesDynamic(this.translate.instant(element.name), element.matchResult, this.translate.instant("Home"), this.translate.instant("Draw"), this.translate.instant("Away"));
                  break;
              }
            }
            else {
              if (element.status == 1) {
                results += "<tr><td>" + element.homeName + "</td><td> " + element.matchResult + "</td></tr>";
              } else {
                results += "<tr><td><s>" + element.homeName + "</s></td><td>" + this.translate.instant("pg-tickets.cancelled") + "</td></tr>";
              }
            }
          }
        });
        results += "</table>";
        //contentModal = "<p><span class=\"total_value\" style=\"font-weight:bold;\">" + this.translate.instant("pg-home.total-value") + ": " + this.ticketState.currencySymbol + ' ' + (this.ticketState.finalValue * this.ticketState.currencyQuote).toFixed(2) + '</span>' + "<br/>" + this.translate.instant('pg-home.prize') + ': ' + this.ticketState.currencySymbol + ' ' + this.prizeAmount.toFixed(2) + '<br/>' + this.translate.instant('pg-home.jackpot') + ': ' + this.ticketState.currencySymbol + ' ' + this.round.firstJackpot.toFixed(2) + '<br/>' +
        contentModal = "<p>" + results + "</p>" +
          // this.translate.instant("pg-home.round") + ": " + this.ticketState.round.name + " (" + this.ticketState.round.number + ") <br/>" +
          this.translate.instant("pg-home.round") + ": " + this.ticketState.round.name + "<br/>" +
          this.translate.instant("pg-home.creation") + ": " + this.datepipe.transform(this.ticketState.date, 'short') + "<br/>" +
          (this.appComponent.userAdmin.jurisdictionId < 7 ? this.translate.instant("pg-home.operator") + ": " + this.appComponent.userAdmin.login + " - " + this.translate.instant("pg-home.player-name") + ": " + this.playerName : this.translate.instant("pg-home.operator") + ": " + this.appComponent.userAdmin.login) + "<br/>" + this.translate.instant("pg-home.quantity-tickets") + ": " + this.ticketState.quantity + "</p>";


      }
    }

    this.htmlToAdd = contentModal;
    this.modalCreateTicket.show();

  }

  Confirm() {
    this.modalCreateTicket.hide();
    this.postTicket();
  }

  postTicket() {
    this.processingBet = true;
    this.configService.postTicket(this.ticketState, this.round.rankRound, this.round.name, this.round.betradarSport.name)
      .subscribe(data => {
        this.ticketState.uuid8 = data.betId;
        this.interactionService.setTicketPanelState(this.ticketState);
        this.modalCreateTicket.hide();
        this.modalFinishTicket.show();
        this.processingBet = false;
      }, error => {
        if (error.status == 400) {
          if (error.error == "Without Credit.") {
            this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.no-enough-credit"), true);
          } else {
            this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
          }
        } else {
          this.modalsService.openNotificationModal(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), true);
        }
        console.log(error);
        this.ngOnInit();
        this.processingBet = false;
      });
  }

  updateUserCredit(userId: number, newUserCredit: number) {
    let transactionType = 3;
    this.configService.updateUserCredit(userId, this.ticketState.finalValue, transactionType, 0, this.ticketState.uuid8)
      .subscribe(data => {
      }, error => {
        console.log(error);

        //this.appComponent.msgStandard(this.translate.instant("pg-home.operation-failed"), this.translate.instant("pg-home.operation-failed-info"), 4);
      });
  }

  closeFinishModal() {
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();
    this.clearBet();
  }

  closeWhatsapp() {
    this.modalWhatsapp.hide();
    this.clearBet();
  }

  setMenuFeedback() {
    if (document.getElementsByClassName('button-header').length > 0)
      document.getElementsByClassName('button-header')[0].classList.remove('button-header');
    document.getElementById('headerTicket').classList.add('button-header');
  }

  showModalWhatsapp() {
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();
    this.modalWhatsapp.show();
  }

  /*whatsappTicket(cel: string) {
    cel = cel.trim();

    this.configService.saveWhatsappNumber(this.ticketState.playerName, cel, this.ticketState.uuid8)
      .subscribe(data => {
        //...
      }, error => {
        console.log(error);
      });

    let whatsappHead: string =
      this.translate.instant("pg-jurisdiction.coupon") + ": " + this.ticketState.uuid8 + "%0A" +
      this.translate.instant("pg-home.round") + ": " + this.ticketState.round.name + "(" + this.ticketState.round.number + ") %0A" +
      this.translate.instant("pg-home.total-value") + ": " + this.ticketState.currencySymbol + ' ' + Math.round(this.ticketState.finalValue * this.ticketState.currencyQuote).toFixed(2) + "%0A" +
      this.translate.instant("pg-home.creation") + ": " + this.ticketState.date + "%0A" +
      this.translate.instant("pg-home.operator") + ": " + this.ticketState.userAdmin.login + "%0A" +
      this.translate.instant("pg-home.player-name") + ": " + this.ticketState.playerName + "%0A%0A";

    let whatsappBody: string = this.translate.instant("pg-tickets.results") + ": %0A";
    if (this.ticketState.round.rankRound) {
      this.ticketState.matches.forEach(element => {
        whatsappBody += element.homeName + "%09%09%09%09" + element.matchResult + "° %0A";
      });
    }
    else {
      this.ticketState.matches.forEach(element => {
        var mresult;
        if (this.round.singleMatchRound) {
          if (element.two_options == 0 && (element.homeName == 'Final Result' || element.homeName == 'More Ball Possession')) { mresult = element.matchResult.split("").join(" - ") }

          if (element.two_options == 0 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '1') { mresult = 'Over' }
          if (element.two_options == 0 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '2') { mresult = 'Under' }
          if (element.two_options == 1 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '1') { mresult = 'Yes' }
          if (element.two_options == 1 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '2') { mresult = 'No' }
          if (element.two_options == 1 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '12') { mresult = 'Yes-No' }
          if (element.two_options == 0 && element.homeName != 'Final Result' && element.homeName != 'More Ball Possession' && element.matchResult == '12') { mresult = 'Over-Under' }
          whatsappBody += this.translate.instant(element.homeName) + "%09%09%09 *" + this.translate.instant(mresult) + "* %0A";
        }
        else {
          mresult = element.matchResult.split("").join(" - ")
          whatsappBody += element.homeName + " - " + element.awayName + "%09%09%09 *" + mresult + "* %0A";
        }

      });
    }

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + whatsappHead + whatsappBody);
    this.modalWhatsapp.hide();
    this.clearBet();
  }*/

  whatsappTicket(cel: string) {
    cel = cel.trim();

    this.configService.saveWhatsappNumber(this.ticketState.playerName, cel, this.ticketState.uuid8)
      .subscribe(data => {
        //...
      }, error => {
        console.log(error);
      });

    let whatsappMsg = this.translate.instant("pg-print.ticket") + " " + this.ticketState.uuid8.slice(0, 8) + ": "
      + "https://poolbet365.com/print/" + this.ticketState.uuid8 + "/" + this.ticketState.userAdmin.login + "/"
      + this.ticketState.round.id;

    window.open('https://api.whatsapp.com/send?phone=' + cel + '&text=' + whatsappMsg);
    this.modalWhatsapp.hide();
    this.clearBet();

  }

  /*printTicket() {
    this.modalFinishTicket.hide();
    window.open('/print/' + this.ticketState.uuid8 + "/" + this.ticketState.userAdmin.login + "/" + this.round.id, "", "width=360,height=700");
    this.clearBet();
  }*/

  printTicket() {
    // hide modal
    this.interactionService.selectedRound.isOpened = false;
    this.modalFinishTicket.hide();

    // set round name according to the language
    let roundName: string = "";
    switch (this.appComponent.activeLang) {
      case 'en': {
        roundName = this.round.nameEng;
        break;
      }
      case 'it': {
        roundName = this.round.nameItaly;
        break;
      }
      case 'es': {
        roundName = this.round.nameSpan;
        break;
      }
      case 'pt': {
        roundName = this.round.name;
        break;
      }
      default: {
        roundName = this.round.name;
        break;
      }
    }

    // generate matches list according round type
    let matchesLines: string = "";
    if (this.ticketState.round.rankRound) {
      this.ticketState.matches.forEach(element => {
        matchesLines += "<tr><td colspan='2'><hr style='margin: 0px'/></td></tr>";
        if (element.status == 1) matchesLines += "<tr><td><span style='padding: 0 5px'><b>" + element.homeName + "</b></span></td><td style='text-align= right'>" + element.matchResult + "°</b></span></td><tr>";
        else matchesLines += "<tr><td><span style='padding: 0 5px'><b>" + element.homeName + "</b></span></td><td style='text-align= right'>" + this.translate.instant('pg-tickets.cancelled') + "</b></span></td><tr>";
      });
    }
    else {
      this.ticketState.matches.forEach(element => {
        if (this.round.singleMatchRound) {
          let mResult = element.matchResult;
          if (element.homeName == 'Total Goals' || element.homeName == 'Total Corners' || element.homeName == 'Corners Home Team' || element.homeName == 'Corners Away Team' || element.homeName == 'Total Faults' || element.homeName == 'Shoots on Target' || element.homeName == 'Yellow Cards') {
            mResult = element.matchResult == '1' ? this.translate.instant('Over') : this.translate.instant('Under');
          }
          else if (element.homeName == 'Will be Penalty' || element.homeName == 'Goal in the 1st Half' || element.homeName == 'Goal in the 2nd Half' || element.homeName == 'GG (Both Score)' || element.homeName == 'Red Card') {
            mResult = element.matchResult == '1' ? this.translate.instant('Yes') : this.translate.instant('No');
          }
          matchesLines += "<tr><td colspan='2'><hr style='margin: 0px'/></td></tr>";
          matchesLines += "<tr><td><span style='padding: 0 5px'><b>" + this.translate.instant(element.homeName) + "</b></span></td><td style='text-align: right'><span style='padding: 0 5px'><b>" + mResult + "</b></span></td><tr>";
        } else {
          // matches list for normal round
          matchesLines += "<tr><td colspan='2'><hr style='margin: 0px'/></td></tr>";
          matchesLines += "<tr><td colspan='2'><span style='padding: 0 5px'><b>" + element.homeName + " - " + element.awayName + "</b></span></td><tr>";
          if (element.status == 1) matchesLines += "<tr><td><span style='padding: 0 5px'>" + this.getCurrentDateTime(element.dateTime).getUTCDate() + "/" + (this.getCurrentDateTime(element.dateTime).getUTCMonth() + 1) + " " + this.getCurrentDateTime(element.dateTime).getUTCHours() + ":" + this.getCurrentDateTime(element.dateTime).getUTCMinutes() + "</span></td><td style='text-align: right'><span style='padding: 0 5px'><b>" + element.matchResult + "</b></span></td></tr>";
          else matchesLines += "<tr><td><span style='padding: 0 5px'>" + this.getCurrentDateTime(element.dateTime).getUTCDate() + "/" + (this.getCurrentDateTime(element.dateTime).getUTCMonth() + 1) + " " + this.getCurrentDateTime(element.dateTime).getUTCHours() + ":" + this.getCurrentDateTime(element.dateTime).getUTCMinutes() + "</span></td><td style='text-align: right'><span style='padding: 0 5px'><b>" + this.translate.instant('pg-tickets.cancelled') + "</b></span></td></tr>";
        }
      });
    }

    let printContents, popupWin, betQrCode;
    //printContents = document.getElementById('print-section').innerHTML;
    betQrCode = document.getElementById('bet-qrcode').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,width=360,height=700');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>PoolBet365</title>
          <!--<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.4.1/dist/css/bootstrap.min.css" integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh" crossorigin="anonymous">-->
          <style>
            body {
              font-family: Roboto, "Helvetica Neue", sans-serif;
              font-size: 16px;
            }
            table {
              border-width: thin;
            }
            b {
                font-weight: 600;
            }
            .print-betid {
                margin: 0;
                float: right;
            }
            .bet-qrcode {
                display: flex;
                justify-content: center;
            }
            .bet-qrcode img{
                max-width: 150px;
            }
            @page {
              /*size: auto;*/
              margin: 5;
            }
            /*@media print {
              html, body {
                width: 210mm;
                height: 297mm;
              }*/
          </style>
        </head>
        <body>
          <table width="300px" border="0">
            <tr>
                <td>
                    <table style="width: 100%">
                        <tr>
                            <td>
                                <img src="/assets/image/logo-principal.png" width="180px" style="filter: drop-shadow(0px 4px 6px black);"/><br />
                            </td>
                            <td>
                                <img src="/assets/image/icon-print.jpg" onclick='window.print()'"
                                    style="cursor: pointer; font-size: 50px;" />
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr>
                <td>
                    <table style="border: solid black thin; width: 100%">
                        <tr>
                            <td>
                                <table style="border: 0; width: 100%;">
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('pg-result.ticket')}</b></span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">${this.ticketState.uuid8}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <hr style="margin: 0px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('round-header.datetime')}</b>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">
                                                ${this.getCurrentDateTime(this.ticketState.date).getUTCDate()}/${this.getCurrentDateTime(this.ticketState.date).getUTCMonth() + 1}
                                                ${this.getCurrentDateTime(this.ticketState.date).getUTCHours()}:${this.getCurrentDateTime(this.ticketState.date).getUTCMinutes()}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <hr style="margin: 0px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('pg-home.operator')}</b>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">
                                                ${this.ticketState.userAdmin.login}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <hr style="margin: 0px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('pg-home.player')}</b>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">
                                                ${this.ticketState.playerName}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <hr style="margin: 0px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('pg-print.round-id')}</b>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">
                                                ${this.round.uuid4}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <hr style="margin: 0px" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span style="padding: 0 5px">
                                                <b>${this.translate.instant('pg-home.round-value')}</b>
                                            </span>
                                        </td>
                                        <td style="text-align: right;">
                                            <span style="padding: 0 5px">
                                                ${this.Currency.currencySymbol} ${this.roundingFunction(this.round.value).toFixed(2)}
                                            </span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr>
              <td>
                <table style="border: solid black thin; width: 100%; margin-top: 10px">
                  <tr>
                    <td colspan='2' style='background-color: black; color: white; text-align: center'>
                      <b>${roundName}</b>
                    </td>
                  </tr>
                  ${matchesLines}
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table style="border: solid black thin; width: 100%; margin-top: 10px; background-color: #ccc">
                  <tr>
                    <td>
                      <span style='font-size: 20px; font-weight: 600;padding: 0 5px'>
                        ${this.translate.instant('pg-home.value')}
                      </span>
                    </td>
                    <td style="text-align: right">
                      <span style='font-size: 20px; font-weight: 600;padding: 0 5px'>
                        ${this.Currency.currencySymbol} ${this.roundingFunction(this.ticketState.finalValue).toFixed(2)}
                      </span>
                    </td>
                    <td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table style="border: solid black thin; width: 100%; margin-top: 10px">
                  <tr>
                    <td>
                      <span style="padding: 0 5px">
                        <b>${this.translate.instant('pg-home.prize')}</b>
                      </span>
                    </td>
                    <td style='text-align: right'>
                      <span style="padding: 0 5px">
                        ${this.Currency.currencySymbol} ${this.roundingFunction(this.prizeAmount).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style="padding: 0 5px">
                        <b>${this.translate.instant('pg-home.jackpot')}</b>
                      </span>
                    </td>
                    <td style='text-align: right'>
                      <span style="padding: 0 5px">
                        ${this.Currency.currencySymbol} ${this.roundingFunction(this.jackpotAmount).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td>
                <table style="border: solid black thin; width: 100%; margin-top: 10px">
                  <tr>
                    <td colspan='2' style='text-align: center'>
                      <span style="padding: 0 5px">
                        ${this.translate.instant('pg-print.final-warnning')}
                      </span>
                      <p>${betQrCode}</p>
                      <p>${this.translate.instant('pg-result.ticket')}: ${this.ticketState.uuid8}</p>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </body>
      </html>`
    );
    this.clearBet();
    popupWin.document.close();

  }

  getCurrentDateTime(dateToConvert: Date): Date {
    return new Date(new Date(dateToConvert).getTime() - (new Date()).getTimezoneOffset() * 60000)
  }

  removeResult(i, result) {
    if (this.round.rankRound)
      this.rankArray[parseInt(this.ticketState.matches[i].matchResult)] = 0;
    this.ticketState.matches[i].matchResult = this.ticketState.matches[i].matchResult.replace(result, '');
    let checkExistingResult = false;
    this.ticketState.matches.forEach(element => {
      if (element.matchResult != "") {
        checkExistingResult = true;
      }
    });
    if (!checkExistingResult) {
      this.clearBet();
    }
    this.count = this.count - 1;
    this.betEnd = true;
    this.modalCoupon.hide();
    this.interactionService.setTicketPanelState(this.ticketState);
    this.interactionService.setMatchRank(this.rankArray);
  }

  SetFinalResultName(str: Ticket) {
    if (str.round.singleMatchRound == 0) {
      this.betEnd = false;
      // this.prizeRound = this.prizes.find(data => data.roundId == str.round.id && data.type == 1).gathered
    }
    else {
      //let prizeAmount
      this.configService.getPrize().forEach(element => this.prizeAmount = element.findIndex(data => data.roundId == str.round.id))
      this.betEnd = false;
      this.prizeRound = this.prizes.find(data => data.roundId == str.round.id && data.type == 1).gathered
      let elementName = str.round.name
      let nameTeam: string[]
      elementName = elementName.replace(" (", " X ")
      elementName = elementName.replace(" - ", " X ")
      nameTeam = elementName.split(" X ");
      this.teamAway = nameTeam[1];
      this.teamHome = nameTeam[0];
      let winnerChosen;
      let roundName;
      this.takeValueFromTheGame(str)
      if (str.round.singleMatchRound == 0) {
        this.nameOfTheMatch = str.round.name
      }
      else {
        if (str.matches[0].homeName == "Final Result") winnerChosen = str.matches[0].matchResult;
        else winnerChosen = "X";
        roundName = str.round.name;
        let poss = str.matches.find(data => data.homeName == "More Ball Possession").matchResult;
        this.NameOrganize(winnerChosen, roundName, poss);
      }
    }
    //this.configService.getGames(str).forEach(element => console.log(element))
    //{{matches.matches[0].matchResult}}
  }

  NameOrganize(choosen: string, elementName: string, bollPossSelect: string) {
    let nameTeam: string[]
    elementName = elementName.replace(" (", " X ")
    elementName = elementName.replace(" - ", " X ")
    nameTeam = elementName.split(" X ");
    if (choosen == "1") this.nameOfTheMatch = nameTeam[0]
    if (choosen == "X") this.nameOfTheMatch = this.translate.instant('Teamdraw')
    if (choosen == "2") this.nameOfTheMatch = nameTeam[1]
    if (bollPossSelect == "1") this.bollPossSelected = nameTeam[0]
    if (bollPossSelect == "X") this.bollPossSelected = this.translate.instant('Teamdraw')
    if (bollPossSelect == "2") this.bollPossSelected = nameTeam[1]
  }

  takeValueFromTheGame(str: Ticket) {
    this.TGol = str.matches[0].totalGoals;
    this.CornerAway = str.matches[0].cornerAwayTeam;
    this.CornerHome = str.matches[0].cornerHomeTeam;
    this.Shoot = str.matches[0].shootsOnTarget;
    this.Tfault = str.matches[0].totalFaults;
    this.TCorners = str.matches[0].totalCorners;
    this.yCard = str.matches[0].yellowCards;
  }

  getTranslate(str) {
    return this.translate.instant(str);
  }

  upDateValue() {
    let doubleBets = 0;
    let tripleBets = 0;
    this.ticketState.singleBet = true;
    if (!this.round.rankRound) {
      this.ticketState.matches.forEach(element => {
        if (element.matchResult.length == 2) {
          doubleBets += 1;
          this.ticketState.singleBet = false;
        }
        else if (element.matchResult.length == 3) {
          tripleBets += 1;
          this.ticketState.singleBet = false;
        }
      });
    }
    this.ticketState.finalValue = (2 ** doubleBets) * (3 ** tripleBets) * (this.ticketState.round.value * this.ticketState.quantity);
    this.interactionService.setTicketPanelState(this.ticketState);
  }

  RedirectToPayment() {
    this.clearBet();
    this.router.navigate(['/payment']);
  }

  clearBet() {
    localStorage.setItem("reset", "true");
    this.ticketState = null;
    this.playerName = "";
    this.betEnd = true;
    this.modalCoupon.hide();
    this.interactionService.setTicketPanelState(this.ticketState);
    this.rankArray = [0];
    this.count = 0;
    this.interactionService.clearMatchRank(this.rankArray);
  }

  sendBetConfirmedSignal() {
    this.interactionService.sendBetConfirmedSignal();
  }
}
