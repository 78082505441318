<!-- LOGIN MODAL -->
<form [formGroup]="loginForm"  style="color: white" *ngIf="configLoaded">
  <div [ngClass]="{'show': currentModalType === modalType.Login}" class="modal" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content bg-theme modal-content-login" style="max-height: 50vh; height: fit-content !important;">
        <div class="header_modal_login">
          <button type="button" class="btn_login_close" data-dismiss="modal" aria-label="Close" (click)="close()">
            <span>&times;</span>
          </button>

          <img [ngStyle]="{ 'width': this.skinConfig.mainLogoWidth }" src="../assets/image/{{this.skinConfig.mainLogo}}"
            alt="Logo" class="modal_login_image mb-2" />

          <h6 class="modal_login_title w-100 font-weight-bold">
            {{ "pg-app-component.enter" | translate }}
          </h6>
        </div>

        <div class="body_modal_login">
          <input type="text" class="modal-login-input mb-3" formControlName="username" style="padding-left: 1rem;"
            placeholder="{{ 'pg-app-component.username' | translate }}" id="defaultForm-name">
          <div *ngIf="loginForm.get('username').invalid && loginForm.get('username').touched">
            <small class="text-danger" *ngIf="loginForm.get('username').errors.required">{{
              'pg-app-component.username-required' |
              translate }}</small>
          </div>

          <div class="modal-input-with-icon">
            <input [type]="show_password ? 'text' : 'password'" class="modal-input-with-icon_input"
              id="defaultForm-pass" style="padding-left: 1rem;" formControlName="password"
              placeholder="{{ 'pg-app-component.password' | translate }}">
            <i (click)="showPassword()" [class]="show_password ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
          </div>
          <div *ngIf="loginForm.get('password').invalid && loginForm.get('password').touched">
            <small class="text-danger" *ngIf="loginForm.get('password').errors.required">{{
              'pg-app-component.password-required' |
              translate }}</small>
          </div>
        </div>

        <div class="footer_modal_login">
          <button mdbBtn color="default" class="waves-light button-font btn_login" (click)="login()" mdbWavesEffect
            [disabled]="loginForm.invalid">
            {{"menu.signin" | translate}}
          </button>
          <p class="forgot_password">
            <a (click)="forgetPassword()" style="color: white">{{ "pg-app-component.forget-user-passwords" | translate
              }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</form>

<!-- END LOGIN MODAL -->

<!-- RECOVERY PASSWORD MODAL -->
<div class="modal" id="frameModalTop" tabindex="-1" role="dialog"
  *ngIf="configLoaded" aria-labelledby="myModalLabel"
  [ngClass]="{'show': currentModalType === modalType.RecoveryPassword}">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-theme modal-content-login" style="max-height: 50vh; height: fit-content;">
      <div class="header_modal_login">
        <button type="button" class="btn_login_close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span>&times;</span>
        </button>
        <h6 class="modal_login_title w-100 font-weight-bold mt-2">
          {{ "pg-app-component.forgot-credentials" | translate }}
        </h6>
      </div>

      <div class="modal-body2" [formGroup]="forgotPasswordForm">
        <div class="body_forgot_password">
          <div class="input_forgot_password">
            <p class="text_forgot_password">
              {{ "pg-app-component.text-forgot-password" | translate }}
              <br />
              <br />
              {{ "pg-app-component.text-atention" | translate }}
            </p>
            <input type="text" id="defaultForm-email3" formControlName="email" class="forgot_password_input"
              placeholder="{{ 'pg-app-component.placeholder-text-email-register' | translate }}" />
            <div *ngIf="forgotPasswordForm.get('email').invalid && forgotPasswordForm.get('email').touched">
              <small class="text-danger"
                *ngIf="forgotPasswordForm.get('email').errors.required || forgotPasswordForm.get('email').errors.email">{{
                'pg-jurisdiction.empty-email-info' | translate }}</small>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;justify-content: center;margin-bottom: 10px;">
        <button mdbBtn color="default" class="btn_forgot_password btn-default btn" (click)="recoverPassword()" style="
                height: 27px;
                font-size: 14px;
                margin-bottom: 20px;
                text-align: center;
                line-height: 10px;
              " mdbWavesEffect [disabled]="forgotPasswordForm.invalid">
          {{ "pg-app-component.send-credentials" | translate }}
        </button>
        <br />
      </div>
    </div>
  </div>
</div>
<!-- END RECOVERY PASSWORD MODAL -->


<!-- REGISTER MODAL -->
<div class="modal" id="frameModalTop" tabindex="-1" role="dialog"
  [ngClass]="{'show': currentModalType === modalType.Register}">
  <div class="modal-dialog" role="document">
    <div class="modal-content bg-theme"
      style="display: flex;flex-direction: column;align-items: center;border: #434343 16px solid;">
      <div class="modal-header2 text-center" style="display: flex;align-items: center;">
        <h6 class="modal-title w-100 font-weight-bold" style="color: white !important;">
          {{ "pg-app-component.register" | translate }}
        </h6>
        <button type="button" class="btn_login_close" style="margin-right: 10px;" data-dismiss="modal"
          aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <!-- Loading (appears when is loading register) -->
      <div class="modal-body mx-3" *ngIf="registerLoading" id="spinner-loading">
        {{ "pg-app-component.registering" | translate }}...
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="d-flex flex-column" *ngIf="!registerLoading"
        style="overflow: hidden !important; padding-bottom: 40px;" [formGroup]="registerForm">

        <!-- USERNAME INPUT -->
        <div class="md-form register-form mx-auto text-center"
          style="justify-content: center; display: flex; flex-direction: column;">
          <input type="text" formControlName="username" class="form-control code-field inputField"
            style="min-width: 15vw;height: 19px;margin-bottom: 5px; padding-top: 5px !important;"
            placeholder="{{ 'pg-app-component.placeholder-text-username-register' | translate }}" />

          <small class="text-danger"
            *ngIf="registerForm.get('username').invalid && registerForm.get('username').touched">{{
            'pg-app-component.username-required' | translate }}</small>
        </div>

        <!-- PASSWORD INPUT -->
        <div class="md-form register-form  text-center"
          style="justify-content: center; display: flex; flex-direction: column;">
          <input type="password" formControlName="password" class="form-control code-field inputField"
            placeholder="{{ 'pg-app-component.placeholder-text-password-register' | translate }}"
            style="min-width: 15vw;height: 17px; padding-top: 5px !important;" />

          <small class="text-danger" style="margin: auto; max-width: 75%;"
            *ngIf="registerForm.get('password').invalid && registerForm.get('password').touched">{{
            'pg-edit-user.fail-password' | translate }}</small>
        </div>

        <!-- CONFIRM PASSWORD INPUT -->
        <div class="md-form register-form mx-auto texte-center"
          style="justify-content: center; display: flex; flex-direction: column;">
          <input type="password" formControlName="confirmPassword" class="form-control code-field inputField"
            placeholder="{{ 'pg-app-component.placeholder-text-confirm-password-register' | translate }}"
            style="min-width: 15vw;height: 17px; padding-top: 5px !important;" />

          <small class="text-danger"
            *ngIf="registerForm.errors?.mismatch && registerForm.get('confirmPassword').touched">{{
            'pg-jurisdiction.password-not-match' | translate }}</small>
        </div>


        <!-- EMAIL INPUT -->
        <div class="md-form register-form mx-auto text-center"
          style="justify-content: center; display: flex; flex-direction: column;">
          <input type="text" formControlName="email" class="form-control code-field inputField"
            placeholder="{{ 'pg-app-component.placeholder-text-email-register' | translate }}"
            style="min-width: 15vw;height: 28px;" />
          <small class="text-danger" *ngIf="registerForm.get('email').invalid && registerForm.get('email').touched">{{
            'pg-jurisdiction.invalid-email' | translate }}</small>
          <!-- </div> -->
        </div>
      </div>
      <div style="
              width: 100%;
              margin-top: 5%;
              margin-bottom: 2%;
              font-size: 15px;
              padding-left: 5%;
              padding-right: 5%;
            " [formGroup]="registerForm">
        <mat-checkbox id="confirmCheckbox" formControlName="checkBox" #confirmCheckbox
          (click)="ToggleConditionTermConfirmation()" class="example-margin">
          {{ "pg-app-component.terms-of-condition" | translate }}*
        </mat-checkbox>
      </div>
      <ng-container *ngIf="refCode == '' || refCode == null ; else hideRefCode">
        <div class="d-flex flex-column justify-content-center mb-3">
          <!-- botao registre-se -->
          <div class="d-flex justify-content-center mb-3">
            <button mdbBtn color="success" class="waves-ligh button-font" [ngClass]="{'disabled': registerForm.invalid}"
              mdbWavesEffect (click)="register()">
              {{ "menu.signup" | translate }}
            </button>
          </div>

          <!-- codigo amigo -->
          <div class="refcode-div" [formGroup]="refCodeForm">
            <mat-form-field style="width: 65% !important;" appearance="fill">
              <mat-label class="">Friend Code</mat-label>
              <input matInput formControlName="friendCode" formControlName="refCode">
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ng-template #hideRefCode>
        <div class="d-flex align-items-center justify-content-center modal-footer">
          <div class="d-flex justify-content-end mb-3">
            <button mdbBtn color="success" class="waves-ligh button-font" mdbWavesEffect (click)="register()"
              [ngClass]="{'disabled': registerForm.invalid}">
              {{ "menu.signup" | translate }}
            </button>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<!-- END REGISTER MODAL -->

<!--Modal Change Password-->
<div class="modal" id="frameModalTop" tabindex="-1" role="dialog"
  [ngClass]="{'show': currentModalType === modalType.ChangePassword}">
  <div class="modal-dialog modal-notify modal-primary" role="document">
    <!--Content-->
    <div class="modal-content bg-theme modal-change_password">
      <!--Header-->
      <div class="modal-header">
        <p class="heading">
          {{ "pg-settings.change-password" | translate }}
        </p>

        <button type="button" class="close" (click)="close()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="password-modal">
        <div class="" >
          <form style="color: white">
            <!-- User Data -->

            <div class="w-100 div_input">
              <b>{{ "pg-settings.current-password" | translate }}</b><br>
              <div class="text-center" [formGroup]="changePasswordForm">
                <input class="pass-fields w-100" type="password"
                  formControlName="currentPassword" />
                <small class="text-danger" style="margin: auto; max-width: 75%;"
                  *ngIf="changePasswordForm.get('currentPassword').invalid && changePasswordForm.get('currentPassword').touched">{{
                  'pg-edit-user.fail-password' | translate }}</small>
              </div>

            </div>
            <div class="w-100 div_input">
              <b>{{ "pg-logged.new-password" | translate }}</b><br>
              <div class="text-center" [formGroup]="changePasswordForm">
                <input class="pass-fields w-100" type="password"
                  formControlName="password" />
                <small class="text-danger" style="margin: auto; max-width: 75%;"
                  *ngIf="changePasswordForm.get('password').invalid && changePasswordForm.get('password').touched">{{
                  'pg-edit-user.fail-password' | translate }}</small>
              </div>
            </div>
            <div class="w-100 div_input">
              <b>{{ "pg-app-component.confirm-password" | translate }}</b><br>
              <div class="text-center" [formGroup]="changePasswordForm">
                <input class="pass-fields w-100" type="password"
                  formControlName="confirmPassword" />
                <small class="text-danger"
                  *ngIf="changePasswordForm.errors?.mismatch && changePasswordForm.get('confirmPassword').touched">{{
                  'pg-jurisdiction.password-not-match' | translate }}</small>
              </div>
            </div>

          </form>


          <div class="d-flex mt-3 justify-content-between mb-5">
            <div class="" [formGroup]="changePasswordForm">
              <button class="btn-primary" style="padding: 5px 20px;" [ngClass]="{'disabled': changePasswordForm.invalid}" (click)="changePassword()">
                {{ "pg-settings.change-password" | translate }}
              </button>
            </div>
            <div>
              <button class="btn-danger" style="padding: 5px 25px;" (click)="close()">
                {{ "pg-settings.back" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<!--Modal Change Password-->
