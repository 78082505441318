import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';

import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Transactionsfilter } from '../transactions-filter.interface';
import { Transaction } from '../transaction.interface';
import { ConfigService } from '../config.service';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../currency.interface';
import { Round } from '../round.interface';
import { InteractionService } from '../interaction.service';

import axios from "axios";
import { DatePipe } from '@angular/common';
import { threadId } from 'worker_threads';
import { type } from 'os';
import { Console } from 'console';
import { throws } from 'assert';
import { elementAt } from 'rxjs/operators';
import { ShieldHelper } from '../Helpers/ShieldHelper';
import { ModalsService } from '../services/modals/modals.service';
// import { builtinModules } from 'module';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})

export class TransactionsComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  //displayedColumns: string[] = ['id', 'betUuid8', 'date', 'type', 'description', 'creditIn', 'creditOut', 'credit'];
  // displayedColumns: string[] = ['id', 'betUuid8', 'date', 'type', 'description', 'value'];
  // displayedColumns: string[] = ['data', 'icon' ,'description', 'type', 'value', 'color'];
  displayedColumns: string[] = ['data', 'icon', 'description', 'type', 'value', 'balance', 'color'];
  dataSource = new MatTableDataSource<Transaction>();
  Currency: Currency = <Currency>{};
  currentRound: Round;
  roundName: string;
  dateStart: any = new Date();
  dateEnd: any = new Date();
  period: string = "lastMonth";
  type: number = 0;
  loadingTest: boolean = false;
  isWithdraw: boolean = false;
  transaction: Array<Transaction> = [];
  secondaryList;
  constructor(private interactionService: InteractionService, private configService: ConfigService, private appComponent: AppComponent,
    private fb: FormBuilder, public translate: TranslateService, private modalsService: ModalsService) {
    this.dataSource = new MatTableDataSource<Transaction>();
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }

  ngOnInit() {
    // this.filter()
    this.listTransactions(parseInt(localStorage.getItem('currentUser')));
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
  }

  searchTransaction(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  rejectedTransaction(typeDesc: string): string {
    typeDesc = this.translate.instant("pg-transactions.Rejected");
    return typeDesc;
  }

  translateWithdrawType(type: string): string {
    if (type == "Waiting") {
      type = this.translate.instant("pg-transactions.Waiting");
    }
    if (type == "Accepted") {
      type = this.translate.instant("pg-transactions.Accepted");
    }
    if (type == "Rejected") {
      type = this.translate.instant("pg-transactions.Rejected");
    }
    return type;
  }


  isbet(betUuid8): boolean {
    if (betUuid8 == '' || betUuid8 == null) {
      return true
    }
  }

  changeTransactionColor(obj) {
    for (const item of obj) {
      if (item.withdraw == null) continue;
  
      this.isWithdraw = true;
      const { transaction, withdraw } = item;
      const { description } = transaction;
  
      switch (description) {
        case "Solicitação de saque":
          if (withdraw.status != 1) {
            withdraw.status = 0;
            withdraw.statusStr = "Waiting";
            transaction.color = "#ef782e";
          }
          break;
  
        case "Saque rejeitado":
          withdraw.status = 2;
          withdraw.statusStr = "Rejected";
          transaction.color = "#897D12";
          break;
  
        default:
          if (withdraw.status == 1) {
            withdraw.statusStr = "Accepted";
            transaction.color = "green";
          }
          break;
      }
  
      transaction.typeDesc = this.translateWithdrawType(withdraw.statusStr);
    }
  }
  

  fillSgv(color: string, typeDesc: string): string {
    if (typeDesc == this.translate.instant("pg-transactions.Accepted")) {
      color = "#043916";
    }
    if (typeDesc == this.translate.instant("pg-transactions.Waiting")) {
      color = "#ffd5bb";
    }
    if (typeDesc == this.translate.instant("pg-transactions.lose-bet")) {
      color = "#4f0505";
    }
    if (color == "#FB7D8D") {
      color = "#4f0505";
    }
    if (typeDesc == 'Aposta') {
      color = "#3F3D0A";
    }
    if (typeDesc == 'Reembolso') {
      color = "#D3D3D3";
    }
    return color;
  }

  checkWithdraw(typeDesc: string): boolean {
    if (typeDesc == this.translate.instant("pg-transactions.Accepted")
      || typeDesc == this.translate.instant("pg-transactions.Rejected")
      || typeDesc == this.translate.instant("pg-transactions.Waiting"))
      return true
  }

  listTransactions(userId: number) {
    let fullTransactionsList = [];
    let dateLimited = new Date(this.takeLimitedDate());
    let dateEnd = new Date(this.getToday());
    this.configService.getTransactionsFilterWithStatus(userId, this.appComponent.activeLang)
      .subscribe(data => {
        this.changeTransactionColor(data);
        data.map(element => {
          element.transaction.typeDesc = element.transaction.typeDesc;
          let minutes = parseInt(localStorage.getItem('minute'), 10);
          const gameDateTime = (new Date(element.transaction.date)).getTime() + (minutes * 60000);
          element.transaction.date = new Date(gameDateTime);
          this.isbet(element.transaction.betUuid8);
          if (element.transaction.typeDesc == "Retirada") element.transaction.color = '#f5a0a0';
          if (element.withdraw != null) {
            switch (element.withdraw.statusStr) {
              case 'Waiting':
                element.transaction.color = '#f59247';
                element.transaction.description = 'credit.player_waiting';
                element.transaction.typeDesc = 'credit.player_waiting_descript';
                break;
              case 'Rejected':
                element.transaction.color = '#897d12';
                element.transaction.description = 'credit.player_refused';
                element.transaction.typeDesc = 'credit.player_refused_descript';
                break;
              case 'Accepted':
                element.transaction.color = '#56f78c';
                element.transaction.description = 'credit.player_accepted';
                element.transaction.typeDesc = 'credit.player_accepted_descript';
                break;
            }
          }
          if (element.round != null && element.withdraw == null && element.transaction.type != 4 && element.transaction.type != 5 && element.transaction.type != 18) {
            if (element.round.posted == 0) {
              element.transaction.color = '#fcff70';
              element.transaction.posted = element.round.posted;
              element.transaction.name = element.round.name;
              element.transaction.description = element.round.name;
              element.transaction.typeDesc = 'credit.betWaiting';
            } else {
              if (element.winnerBet) {
                element.transaction.color = '#56f78c';
                element.transaction.posted = element.round.posted;
                element.transaction.name = element.round.name;
                element.transaction.description = element.round.name;
                element.transaction.typeDesc = 'credit.betWin';
              } else {
                element.transaction.color = '#f75656';
                element.transaction.posted = element.round.posted;
                element.transaction.name = element.round.name;
                element.transaction.description = element.round.name;
                element.transaction.typeDesc = 'credit.betLose';
              }
            }
          }
          if (element.transaction.type == 1 && element.withdraw == null && element.round == null) {
            element.transaction.color = '#56f78c';
            element.transaction.typeDesc = 'credit.player_credtin';
            element.transaction.name = 'credit.transaction_credtin_jurisdction';
            element.transaction.rankMatch = 0;
            element.transaction.singleMatch = 0;
            element.transaction.type = 14
            if (element.transaction.betUuid8 == "null") element.transaction.betUuid8 = null;
          }
          if (element.transaction.type == 2 && element.withdraw == null && element.round == null) {
            element.transaction.color = '#f75656';
            element.transaction.typeDesc = 'credit.player_credtout';
            element.transaction.name = 'credit.transaction_credtout_jurisdction';
            element.transaction.rankMatch = 0;
            element.transaction.singleMatch = 0;
            element.transaction.type = 15
            if (element.transaction.betUuid8 == "null") element.transaction.betUuid8 = null;
          }

          if(element.transaction.type == 18 || element.transaction.type == 5){
            element.transaction.typeDesc = 'credit.refundbet';
            element.transaction.color = '#6d7175';
            element.transaction.typeGame = 1;
            element.transaction.description = element.round ? element.round.name : "Não foi possível recuperar informações da rodada.";
            element.transaction.name = element.round ? element.round.name : "Não foi possível recuperar informações da rodada.";
          }
          
          this.transaction.push(element.transaction);
          fullTransactionsList.push(element.transaction);
        });
        this.dataSource = new MatTableDataSource<Transaction>(fullTransactionsList);
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.log(error);
      });
  }

  concurrentTranslation(element) {
    if (element.description == "credit.purchase") return;
    if (element.type == 1 && element.betUuid8 == null) return this.translate.instant(element.typeDesc);
    else if (element.type == 1 && element.betUuid8 != null) return this.translate.instant('pg-transactions.bet-win');
    else return this.translate.instant(element.typeDesc);
  }

  verifyGame(element) {
    if (element >= 6) return true;
    else return false;
  }

  checkDepositTransaction(element) {
    if (element.round == null && element.withdraw == null && element.transaction.betUuid8 == null) return "#043916"
    else return "#00ffba"
  }

  getFilterTransaction(dateStart, dateEnd, period, type) {
    let filteredList = this.transaction.slice();
    if (type == 1) filteredList = filteredList.filter(x => x.type == 1 && x.betUuid8 == null|| x.type == 12 && x.betUuid8 == null || x.type == 2 && x.betUuid8 == null || x.type == 14 && x.betUuid8 == null || x.type == 15 && x.betUuid8 == null);
    if (type == 2) filteredList = filteredList.filter(x => x.type == 4 || x.type == 5 || x.type == 10);
    // if (type == 3) filteredList = filteredList.filter(x => x.type <= 3 && x.betUuid8 != null);
    if (type == 3) filteredList = filteredList.filter(x => x.type <= 3 && x.betUuid8 != null || x.type == 4);
    if (type == 4) filteredList = filteredList.filter(x => x.typeGame == 1);
    if (type == 5) filteredList = filteredList.filter(x => x.typeGame == 2);
    if (type == 6) filteredList = filteredList.filter(x => x.typeGame == 3);

    if (this.dataOrganize(dateStart) != this.dataOrganize(dateEnd)) {
      dateStart = new Date(dateStart);
      dateEnd = new Date(dateEnd);
      filteredList = filteredList.filter(x => x.date >= dateStart && x.date <= dateEnd);
    }

    this.dataSource = new MatTableDataSource<Transaction>(filteredList);
    this.dataSource.paginator = this.paginator;
  }

  getIPAddress() {
    axios.get('http://api.ipify.org/?format=json')
      .then((response) => {

        axios.get('https://api.ipfind.com?ip=' + response.data['ip'] + '&auth=7e2a21d0-0e27-44b6-aa06-8a0d015e266c')
          .then((response) => {

            localStorage.setItem('currentUserCountry', response.data['currencyCode']);
          }, error => {

            localStorage.setItem('currentUserCountry', 'Brazil');
          })

      }, error => {
        localStorage.setItem('currentUserCountry', 'Brazil');
      })
  }

  detailCoupon(ticketCode) {
    debugger;
    this.modalsService.openDetailTickeModal(0, ticketCode.slice(0,8));
  }

  getChampionshipLogos(name: string) {
    return ShieldHelper.getChampionshipLogos(name);
  }

  getSingleMatchShield(elementName: String, team: number) {
    return ShieldHelper.getSingleMatchShield(elementName, team);
  }

  getToday() {
    const dateToday: Date = new Date();
    dateToday.setDate(this.dateEnd.getDate() + 1);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let today = datepipe.transform(dateToday, 'yyyy/MM/dd', null, 'pt-BR');
    return today
  }
  getlastWeek() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 9));
    const datepipe: DatePipe = new DatePipe('pt-BR');
    let lastWeek = datepipe.transform(priorDate, 'yyyy/MM/dd', null, 'pt-BR');
    return lastWeek;
  }
  getlastMonth() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastMonth = datepipe.transform(priorDate, 'yyyy/MM/dd', null, 'pt-BR');
    return lastMonth;
  }
  getThreeMonth() {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() - 90));
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let threeMonth = datepipe.transform(priorDate, 'yyyy/MM/dd', null, 'pt-BR');
    return threeMonth;
  }
  takeLimitedDate() {
    const dateToday: Date = new Date();
    this.dateEnd = new Date();
    dateToday.setDate(this.dateEnd.getDate() - 90);
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let lastWeek = datepipe.transform(dateToday, 'yyyy/MM/dd', null, 'pt-BR');
    return lastWeek;
  }

  dataOrganize(element: Date) {
    const datepipe: DatePipe = new DatePipe('pt-BR')
    let allTime = datepipe.transform(element, 'yyyy/MM/dd', null, 'pt-BR');
    return allTime;
  }

}
