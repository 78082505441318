import { AfterViewChecked, AfterViewInit, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ConfigService } from '../config.service';
//import { Team } from '../team.interface';
import { Round } from '../round.interface';
import { Game } from '../game.interface';
import { ResponseGame } from '../response-game.class';
import { Prize } from '../prize.interface';
import { Bet } from '../bet.interface';
import { User } from '../user.interface';
import { Jurisdiction } from '../jurisdiction.interface';
import { AppComponent } from '../app.component';
import { TranslateService } from '@ngx-translate/core';
import { Currency } from '../currency.interface';
import { InteractionService } from '../interaction.service';
import axios from "axios";
import { MatTableDataSource } from '@angular/material/table';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { TableChoicesMadeService } from '../services/table-choices-made/table-choices-made.service';
import { element } from 'protractor';
import { debug } from 'console';
import { IGetRoundInfosResponse } from "../Interfaces/RoundPosted/GetRoundInfosResponse.interface"
import { IUserWithBet } from '../Interfaces/RoundPosted/UserWithBet.interface';
import { forkJoin } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { ShieldHelper } from '../Helpers/ShieldHelper';
import { ModalsService } from '../services/modals/modals.service';
import { MatPaginator } from '@angular/material/paginator';

export interface TableWinners {
  betId: string;
  admin: string;
  player: string;
  country: string;
  city: string;
  quantity: number;
  value: string;
  id: number;
}

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit  {
  @ViewChild('dataPaginator') dataPaginator: MatPaginator;

  isLoaded: boolean = false;
  roundsPosted: Round[] = [];
  currentRound: Round;
  games: any[] = [];
  resultLength: number = 0;
  jiuJitsuMatchResult: string[] = [];
  treatedJJMatchResult: string[] = [];
  isLoadingGames: boolean = true;
  winners: any[] = [];
  bets: Bet[] = [];
  totalPrize: number = null;
  totalWinners: number;
  eachValue: number = null;
  eachHits: number = null;
  quantityCoupons: number = null;
  users: User[] = [];
  Currency: Currency = <Currency>{};
  rankResult: string[] = [];
  searchText: any;
  roundName: string;
  info: any = [];
  paramRoundId;
  ELEMENT_DATA: TableWinners[] = [];
  displayedColumns: string[] = ['betId', 'player', 'country', 'quantity', 'value'];
  dataSource = new MatTableDataSource<TableWinners>([]);
  selected = 0;
  roundId: number = +localStorage.getItem("roundGet");
  nameEvent: string;
  dateEnd: string;
  singleMatch: number;
  sportCategory: number;
  jiuJitsuMatch: number;
  TableResult: string = '';
  JackpotValue: number;
  prizeAmount: number;
  countBets: number = null;
  firstJackpot: number;
  winnersCount: number = null;
  userWithBet: IUserWithBet[] = null;

  constructor(private configService: ConfigService, private appComponent: AppComponent,
    private translate: TranslateService, private interactionService: InteractionService,
    private activatedRoute: ActivatedRoute, public router: Router, private cdr: ChangeDetectorRef,
    private modalsService: ModalsService) {
    this.translate.setDefaultLang(this.appComponent.activeLang);
  }
  ngAfterViewChecked(): void {
    if (!this.isLoadingGames && this.games.length > 0 && (this.dataSource.paginator == undefined || this.dataSource.paginator == null)) {
      this.dataSource.paginator = this.dataPaginator
    }
  }

  ngOnInit() {
    this.paramRoundId = this.activatedRoute.snapshot.paramMap.get("roundId");
    this.getRoundName(this.roundId);
    this.resultConstructor();
    this.interactionService.globalCurrency
      .subscribe(data => {
        if (data) {
          this.Currency = data;
        }
      });
    this.scrollToResult();
  }

  getRoundName(roundId) {
    let datepipe: DatePipe = new DatePipe('pt-BR');
    this.configService.getRoundById(roundId)
      .subscribe(data => {
        this.currentRound = data;
        this.rankResult = data.result.split('|');
        this.firstJackpot = data.firstJackpot;
        this.nameEvent = data.name;
        this.singleMatch = data.singleMatchRound;
        this.jiuJitsuMatch = data.jiuJitsuRound;
        this.sportCategory = data.betradarSportId;
        this.dateEnd = datepipe.transform(data.startDateTime, 'yyyy-MM-dd', null, 'pt-BR');
      })
  }

  resultConstructor() {
    forkJoin({
      roundInfo: this.configService.getPostedRoundInfo(this.paramRoundId),
      games: this.configService.getEvents(this.paramRoundId),
    }).subscribe(({ roundInfo, games }) => {
      this.countBets = roundInfo.totalBets;
      this.totalPrize = roundInfo.totalRoundPayment;
      this.winnersCount = roundInfo.userWithBet.length;
      this.eachHits = roundInfo.prizeWinners[0].points;
      this.quantityCoupons = roundInfo.betOfWinners.length;
      this.eachValue = roundInfo.totalRoundPayment / roundInfo.betOfWinners.length;
      this.winners = roundInfo.prizeWinners;
      this.userWithBet = roundInfo.userWithBet;
      this.SetPrizeAumont();

      this.games = games;
      this.GenerateTableResultsEspecial(games);

      this.loadWinners(this.paramRoundId);
      this.chargeWinnersTable();
      this.isLoadingGames = false;
      this.isLoaded = true;
    }, error => {
      console.error('An error occurred:', error);
    });
  }

  chargeWinnersTable() {
    if (this.winners.length > 0) {
      this.ELEMENT_DATA = [];
      this.userWithBet.forEach((element: IUserWithBet, index) => {
        this.ELEMENT_DATA.push({
          betId: element.userBet.uuid8,
          admin: "",
          player: element.userBet.playerName,
          country: element.country,
          city: element.city,
          quantity: element.userBet.quantity,
          value: this.configService.formatCurrencyValue(this.eachValue * element.userBet.quantity),
          id: element.userBet.id,
        });
      });

    } else {
      this.ELEMENT_DATA = [];
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.totalPrize = 0;
      this.eachHits = 0;
      this.eachValue = 0;
      this.modalsService.openNotificationModal(this.translate.instant("pg-result.exception"), this.translate.instant("pg-result.exception-info") +
        this.translate.instant("pg-result.exception-possibility"), true);
    }
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.dataPaginator;
  }

  scrollToResult(): void {
    (document.getElementById('formlogin') as HTMLElement).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }


  getFormula1Shield(driverName: string) {
    return ShieldHelper.getFormula1Shield(driverName);
  }


  loadWinners(roundId: number) {
    this.configService.listRoundWinners(roundId)
      .subscribe(data => {
        this.winners = data;
        this.getBets(roundId);
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      });
  }


  getBets(roundId: number) {
    this.configService.getAllBets(roundId)
      .subscribe(data => {
        this.bets = data;
        this.getUsers();
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      });
  }


  getUsers() {
    this.configService.listCommonUsers()
      .subscribe(data => {
        this.users = data;
      }, error => {
        this.modalsService.openNotificationModal(this.translate.instant("pg-result.failed-load"), this.translate.instant("pg-result.failed-load-info") +
          this.translate.instant("pg-result.assistence-contact"), true);
        console.log(error);
      })
  }
  result(homeScore: number, awaySocore: number) {
    let ret = "X";
    if (homeScore > awaySocore) {
      ret = "1";
    } else if (homeScore < awaySocore) {
      ret = "2";
    }
    return ret;
  }

  resultForSingleMatch(homeScore: any, awaySocore: any) {
    let ret = "X";
    if (Number(homeScore) > Number(awaySocore)) {
      ret = "1";
    } else if (Number(homeScore) < Number(awaySocore)) {
      ret = "2";
    }
    return ret;
  }
  resultOverUnder(homeScore: any) {
    let ret = this.translate.instant("Over");
    if (homeScore == '2') {
      ret = this.translate.instant("Under");
    }
    return ret;
  }

  resultGG(homeScore: any, awaySocore: any) {
    let ret = this.translate.instant("No");
    if (homeScore > 0 && awaySocore > 0) {
      ret = this.translate.instant("Yes");
    }
    return ret;
  }

  resultYesNo(homeScore: any, awaySocore: any) {
    if (Number(homeScore) < Number(awaySocore))
      return this.translate.instant("No");

    return this.translate.instant("Yes");;
  }

  detailCoupon(element) {
    this.modalsService.openDetailTickeModal(element.id, "")
  }

  getChampionshipLogos(name: string) {
    return ShieldHelper.getChampionshipLogos(name);
  }

  getShield(elementName: String, team: number) {
    return ShieldHelper.getSingleMatchShield(elementName, team);
  }

  GenerateTableResultsEspecial(games: any[]) {
    if (this.currentRound.rankRound && !this.currentRound.singleMatchRound) {
      for (let i = 0; i < this.rankResult.length; i++) {
        if (this.rankResult[i][0] == "¢") {
          this.TableResult += TableChoicesMadeService.GenerateResultCanceledWithShield(this.rankResult[i].substring(1), this.translate.instant('Canceled'), ShieldHelper.getFormula1Shield(this.rankResult[i].substring(1)));
        } else
          this.TableResult += TableChoicesMadeService.GenerateResultTableRankMatch(ShieldHelper.getFormula1Shield(this.rankResult[i]), this.rankResult[i], `${(i + 1)}°`);
      }
    }

    if (this.currentRound.singleMatchRound && !this.currentRound.jiuJitsuRound) {
      let results = this.currentRound.result.split('|');

      if (results.length == 18) {
        this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant('Final Result'), results[0].toLowerCase());

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant('More Ball Possession'), results[1].toLowerCase());

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Goal'), results[2],
          this.games[0].totalGoals, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Goals Home Team'), results[3],
          this.games[0].goalsHomeTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Goals Away Team'), results[4],
          this.games[0].goalsAwayTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Corners'), results[5],
          this.games[0].totalCorners, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Corners Home Team'), results[6],
          this.games[0].cornerHomeTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Corners Away Team'), results[7],
          this.games[0].cornerAwayTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Shoots On Target'), results[8],
          this.games[0].shootsOnTarget, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Shoots On Target Home Team'), results[9],
          this.games[0].shootsOnTargetHomeTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Shoots On Target Away Team'), results[10],
          this.games[0].shootsOnTargetAwayTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Faults'), results[11],
          this.games[0].totalFaults, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Yellow Cards'), results[12],
          this.games[0].yellowCards, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Will be Penalty'), results[13]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Goal in the 1st Half'), results[14]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Goal in the 2nd Half'), results[15]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('GG (Both Score)'), results[16]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Red Card'), results[17]
          , this.translate.instant('Yes'), this.translate.instant('No'));

      } else {
        this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant('Final Result'), results[0].toLowerCase());

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant('More Ball Possession'), results[1].toLowerCase());

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Goal'), results[2],
          this.games[0].totalGoals, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Corners'), results[3],
          this.games[0].totalCorners, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Yellow Cards'), results[4],
          this.games[0].yellowCards, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Corners Home Team'), results[5],
          this.games[0].cornerHomeTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Corners Away Team'), results[6],
          this.games[0].cornerAwayTeam, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Total Faults'), results[7],
          this.games[0].totalFaults, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Shoots On Target'), results[8],
          this.games[0].shootsOnTarget, this.translate.instant('Over'), this.translate.instant('Under'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Will be Penalty'), results[9]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Goal in the 1st Half'), results[10]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Goal in the 2nd Half'), results[11]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('GG (Both Score)'), results[12]
          , this.translate.instant('Yes'), this.translate.instant('No'));

        this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Red Card'), results[13]
          , this.translate.instant('Yes'), this.translate.instant('No'));
      }
    }

    if (this.currentRound.jiuJitsuRound && !this.currentRound.singleMatchRound) {
      this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant('Winner'), this.treatedJJMatchResult[0].toLowerCase());

      this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Advantages difference'), this.treatedJJMatchResult[1],
        this.games[0].advantagesDifference, this.translate.instant('Over'), this.translate.instant("Under"));

      this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Winner points'), this.treatedJJMatchResult[2],
        this.games[0].winnerPoints, this.translate.instant('Over'), this.translate.instant("Under"));

      this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Loser points'), this.treatedJJMatchResult[3],
        this.games[0].loserPoints, this.translate.instant('Over'), this.translate.instant("Under"));

      this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Final points difference'), this.treatedJJMatchResult[4],
        this.games[0].advantagesDifference, this.translate.instant('Over'), this.translate.instant("Under"));

      this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant('Finishing at minute'), this.treatedJJMatchResult[5],
        this.games[0].finishingAtMinute, this.translate.instant('Over'), this.translate.instant("Under"));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Win by finishing'), this.treatedJJMatchResult[6],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Will be punishment'), this.treatedJJMatchResult[8],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Will be disqualified'), this.treatedJJMatchResult[9],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Neck finishing'), this.treatedJJMatchResult[10],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Arm finishing'), this.treatedJJMatchResult[11],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Leg finishing'), this.treatedJJMatchResult[12],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Draw in points'), this.treatedJJMatchResult[7],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Fall points'), this.treatedJJMatchResult[13],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Sweep points'), this.treatedJJMatchResult[14],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Guard points'), this.treatedJJMatchResult[15],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Mount points'), this.treatedJJMatchResult[16],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Grip on back points'), this.treatedJJMatchResult[18],
        this.translate.instant('Yes'), this.translate.instant('No'));

      this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant('Knee on belly points'), this.treatedJJMatchResult[17],
        this.translate.instant('Yes'), this.translate.instant('No'));
    }

    if (!this.currentRound.rankRound && !this.currentRound.singleMatchRound && !this.currentRound.jiuJitsuMatchResult) {
      var result = this.currentRound.result.split('|');
      this.games.forEach((game, i) => {
        if (this.rankResult[i] == "¢") {
          this.TableResult += TableChoicesMadeService.GenerateResultCanceledWithImage(game.homeName, ShieldHelper.getBasicShield(game.homeName), game.awayName, ShieldHelper.getBasicShield(game.awayName),
            this.translate.instant('Event Canceled'));
        } else {
          this.TableResult += TableChoicesMadeService.GenarateConfirmTable3ChoicesWithImage(game.homeName, game.awayName, ShieldHelper.getBasicShield(game.homeName),
            ShieldHelper.getBasicShield(game.awayName), result[i].toLocaleLowerCase());
        }
      });
    }

    if (this.currentRound.jiuJitsuRound && this.currentRound.singleMatchRound) {
      this.games.forEach((game, i) => {
        switch (game.type) {
          case 2:
            this.TableResult += TableChoicesMadeService.GenarateConfirmTable2ChoicesOverUnder(this.translate.instant(game.name), this.rankResult[i].toLowerCase(), game.odd, this.translate.instant("Over"), this.translate.instant("Under"));
            break;
          case 3:
            this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(game.name), this.rankResult[i].toLowerCase(), this.translate.instant("Yes"), this.translate.instant("No"));
            break;
          case 1:
            this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(game.name), this.rankResult[i].toLowerCase(), this.translate.instant("1"), this.translate.instant("2"));
            break;
          case 0:
            this.TableResult += TableChoicesMadeService.GenarateConfirmTable3Choices(this.translate.instant(game.name), this.rankResult[i].toLowerCase());
            break;
          case 4:
            this.TableResult += TableChoicesMadeService.Generateconfirmtable2Choices(this.translate.instant(game.name), this.rankResult[i].toLowerCase(), this.translate.instant("Even"), this.translate.instant("Odd"));
            break;
          case 5:
            this.TableResult += TableChoicesMadeService.GenerateNoHitTable2Choices(this.translate.instant(game.name), game.result, this.translate.instant("Home"), this.translate.instant("Away"));
            break;
          case 6:
            this.TableResult += TableChoicesMadeService.GenarateConfirmTable3ChoicesDynamic(this.translate.instant(game.name), this.rankResult[i], this.translate.instant("Home"), this.translate.instant("Draw"), this.translate.instant("Away"));
            break;
        }
      });
    }
  }


  SetPrizeAumont() {
    let jackpot = this.JackpotValue - (this.JackpotValue / 5);
    this.configService.getPrizeAmountByRoundId(this.roundId)
      .subscribe(data => {
        this.prizeAmount = data[0].gathered;

        if (this.prizeAmount == 0) {
          if (this.totalPrize > jackpot) {
            this.prizeAmount = this.totalPrize - jackpot;
          } else {
            this.prizeAmount = this.totalPrize;
          }
        }
        let recoveredJackpot = (this.prizeAmount * 2) - (0.8 * (this.prizeAmount * 2))
        this.JackpotValue = recoveredJackpot > this.firstJackpot ? recoveredJackpot : this.firstJackpot;
      });
  }

  openBetList() {
    let roundId = this.roundId.toString();
    localStorage.setItem("roundGet", roundId)
    this.router.navigate(['/bet-list/:' + roundId]);
  }
}
